import { useIntl } from "react-intl";
import { Modal } from "../../../common/components/Modal1";
import Skeleton from "react-loading-skeleton";
import { useRef, useState } from "react";
import { useAuth } from "../../../auth/components/AuthInit";

export const PromotionDetailModal = ({ htmlFilePath, title }) => {
  const intl = useIntl();
  const iframeRef = useRef();
  const [height, setHeight] = useState("600px");
  const { auth } = useAuth();
  const getContentHeight = () => {
    if (iframeRef.current.contentWindow.document)
      setHeight(
        iframeRef.current.contentWindow.document.body.scrollHeight + "px"
      );
  };

  return (
    <Modal modalId="promotionDetailModal" modalSize="modal-lg">
      {htmlFilePath ? (
        <div>
          <iframe
            src={htmlFilePath}
            ref={iframeRef}
            height={height}
            title={title}
            className="w-100"
            frameBorder="0"
          />
        </div>
      ) : (
        <Skeleton className="w-100" style={{ height: "500px" }} />
      )}
    </Modal>
  );
};

import { ReduxCallTypesConst } from "../../../constants/ReduxCallTypesConst";
import { gamePlaySlice } from "./GamePlaySlice";
import { postMember3 } from "../../common/redux/MasterLayoutCRUD";

const { actions } = gamePlaySlice;

export const playGame = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return postMember3(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.playCompanyGame({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: companyPlayGame");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};

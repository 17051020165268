import { useIntl } from "react-intl";
import { LoginTab } from "../../enum/LoginTab";
import { Field, Form, Formik } from "formik";
import {
  ErrorContainer,
  Input,
} from "../../../common/components/control/InputField";
import { useEffect, useRef, useState } from "react";
import {
  fetchCurrencyList,
  fetchIPAddress,
  getCurrentCountryCode,
  googleRegister,
} from "../../api/loginapi";
import { GoogleRegisterSchema } from "../../yupSchema/registerSchema";
import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { firebaseConfig } from "../../../../setup/FirebaseConfig";
import { useAuth } from "../AuthInit";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

export const GoogleRegisterTab = ({ setLoginTab, setSwalProps, loginTab }) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(true);
  const [isFetchingCurrency, setIsFetchingCurrency] = useState(true);
  const [isShowCurrencyError, setIsShowCurrencyError] = useState(false);
  const [currencyLists, setCurrencyLists] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const { saveAuth, setCurrentUser } = useAuth();
  const formikRef = useRef();
  const longitude = useRef("");
  const latitude = useRef("");
  const ipAddress = useRef("");
  const { refferalCode } = useParams();

  useEffect(() => {
    fetchCurrencyList({ language: intl.locale }).then((resp) => {
      setCurrencyLists(resp.currencyLists);
      fetchIPAddress().then((res) => {
        ipAddress.current = res.ip;
        getCurrentCountryCode(res.ip).then((re) => {
          const currencyCode = resp.currencyLists.find(
            (currency) => currency.currencycode === re.currency
          );
          if (currencyCode) {
            setSelectedCurrency(currencyCode);
          } else {
            setSelectedCurrency(resp.currencyLists[0]);
          }
          setIsFetchingCurrency(false);
          setIsLoading(false);
        });
      });
    });
  }, []);

  // useEffect(() => {
  //   navigator.geolocation.getCurrentPosition(function (position) {
  //     longitude.current = position.coords.longitude;
  //     latitude.current = position.coords.latitude;
  //   });
  // }, []);

  return (
    <>
      <div style={{ width: "100%", marginTop: 50 }}>
        <h3 className="mb-5 d-flex align-items-center justify-content-center">
          <img
            src={`${process.env.PUBLIC_URL}/image/google.png`}
            alt="google"
            className="me-2 mt-1"
            style={{ width: "20px", height: "20px" }}
          />
          <span style={{ color: "white" }}>
            {intl.formatMessage({ id: "registerWithGoogle" })}
          </span>
        </h3>
      </div>
      <Formik
        initialValues={{
          referralCode: refferalCode ?? "",
        }}
        innerRef={formikRef}
        // validationSchema={GoogleRegisterSchema(intl)}
        enableReinitialize={true}
        onSubmit={(values, formikHelpers) => {
          if (selectedCurrency) {
            setIsShowCurrencyError(false);
            setIsLoading(true);
            const app = initializeApp(firebaseConfig);
            const auth = getAuth(app);
            const provider = new GoogleAuthProvider();
            provider.addScope(
              "https://www.googleapis.com/auth/userinfo.profile"
            );
            signInWithPopup(auth, provider)
              .then((result) => {
                const user = result.user;

                let queryParams = {
                  email: user.email,
                  referrerid: values.referralCode,
                  mediaplatform: "gmail",
                  currencycode: selectedCurrency.currencycode,
                  language: intl.locale,
                  uid: user.uid,
                  fcmnotificationid: "",
                  latitude: latitude.current,
                  longitude: longitude.current,
                  ipaddress: ipAddress.current,
                };

                googleRegister(queryParams).then((resp) => {
                  setIsLoading(false);
                  if (parseInt(resp.code) === 0) {
                    setSwalProps({
                      show: true,
                      icon: "success",
                      title: intl.formatMessage({ id: "success" }),
                      text: `
                      ${intl.formatMessage({
                        id: "registerSuccess1",
                      })}
                     ${user.email}
                      ${intl.formatMessage({
                        id: "registerSuccess2",
                      })}`,
                      onConfirm: () => {
                        saveAuth(resp);
                        setCurrentUser(resp);
                      },
                    });
                  } else {
                    formikHelpers.setFieldError("username", resp.message);
                    setSwalProps({
                      show: true,
                      icon: "error",
                      title: intl.formatMessage({ id: "error" }),
                      text: resp.message,
                    });
                  }
                });
              })
              .catch((error) => {
                setIsLoading(false);
                setSwalProps({
                  show: true,
                  icon: "error",
                  title: intl.formatMessage({ id: "error" }),
                  text: intl.formatMessage({ id: "failToRegister" }),
                });
              });
          } else {
            setIsShowCurrencyError(true);
          }
        }}
      >
        {({
          values,
          handleSubmit,
          setFieldValue,
          touched,
          errors,
          setFieldError,
        }) => (
          <Form>
            <div className="mb-2">
              <label
                className="mb-2 text-input-label"
                style={{ color: "white" }}
              >
                {intl.formatMessage({ id: "refferalCode" })}
              </label>
              <Field
                name="referralCode"
                component={Input}
                placeholder={intl.formatMessage({ id: "refferalCode" })}
                readOnly={refferalCode ? true : false}
                autoComplete="off"
              />
            </div>
            <div className="mb-4">
              <label
                className="mb-2 text-input-label"
                style={{ color: "white" }}
              >
                {intl.formatMessage({ id: "currency" })}
              </label>
              {!isFetchingCurrency ? (
                <>
                  <div className="dropdown">
                    <button
                      className={`form-control text-start d-flex align-items-center ${
                        selectedCurrency ? "" : "text-placeholder"
                      }`}
                      style={{
                        height: 35,
                        backgroundColor: "#1c1e23",
                        border: "1px solid grey",
                        borderRadius: "10",
                        fontSize: "12px",
                        color: "white",
                      }}
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      data-text={intl.formatMessage({ id: "currency" })}
                    >
                      {selectedCurrency ? (
                        <>
                          <img
                            src={selectedCurrency.imageurl}
                            alt={selectedCurrency.currencycode}
                            className="me-4 currency-icon"
                          />
                          <div>{selectedCurrency.currencyname}</div>
                        </>
                      ) : (
                        intl.formatMessage({ id: "currency" })
                      )}
                    </button>
                    <div className="dropdown-menu w-100">
                      {currencyLists.map((currency) => (
                        <div
                          className={`dropdown-item d-flex align-items-center py-2 cursor-pointer ${
                            selectedCurrency &&
                            selectedCurrency.currencycode ===
                              currency.currencycode
                              ? "active"
                              : ""
                          }`}
                          key={currency.currencycode}
                          onClick={() => {
                            setSelectedCurrency(currency);
                            document
                              .querySelector(".dropdown-menu")
                              .classList.remove("show");
                          }}
                        >
                          <img
                            src={currency.imageurl}
                            alt={currency.currencycode}
                            className="me-4 currency-icon"
                          />
                          <div>{currency.currencyname}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <ErrorContainer
                    isShow={isShowCurrencyError}
                    errorMsg={intl.formatMessage({ id: "currencyRequired" })}
                  />
                </>
              ) : (
                <Skeleton className="w-100" height="35px" />
              )}
            </div>
            <div className="mt-4 mb-2">
              <button
                type="submit"
                style={{
                  height: 35,
                  borderRadius: 15,
                  backgroundColor: "rgb(245, 190, 9)",
                  fontWeight: "bold",
                  color: "black",
                  fontSize: "12px",
                }}
                disabled={isLoading}
                className="btn btn-prim fw-bold w-100 d-flex align-items-center justify-content-center"
              >
                {isLoading && (
                  <div
                    className="spinner-border text-dark"
                    role="status"
                    style={{ scale: "0.75" }}
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
                <span className={isLoading ? "ms-2" : ""}>
                  {intl.formatMessage({ id: "submit" })}
                </span>
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

import { useEffect, useState } from "react";
import * as actions from "../redux/PromotionAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import Skeleton from "react-loading-skeleton";
import { PromotionDetailModal } from "./promotionDetail/PromotionDetailModal";
import { useAuth } from "../../auth/components/AuthInit";

export const PromotionListing = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [htmlFilePath, setHtmlFilePath] = useState();
  const [title, setTitle] = useState();
  const { auth } = useAuth();
  const promotionState = useSelector(
    ({ promotion }) => promotion,
    shallowEqual
  );

  useEffect(() => {
    const params = {
      platform: "mobile",
      memberid: auth.memberid,
    };

    dispatch(actions.getPromotionPageList(params));
  }, []);

  return (
    <div
      style={{
        backgroundColor: "black",
        backgroundImage: `url(${process.env.PUBLIC_URL}/image/homebackground.png)`,
        backgroundSize: "cover",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={styles.navBar}>
        <div className="text-font4" style={styles.title}>
          {intl.formatMessage({ id: "promotion" })}
        </div>
      </div>
      <PromotionDetailModal htmlFilePath={htmlFilePath} title={title} />
      <div style={{ paddingBottom: "6vh" }}>
        {promotionState.promotionPageList &&
          promotionState.promotionPageList.promotionLists.map(
            (promotion, index) => (
              <div
                key={index}
                className="card"
                style={{
                  width: "95%",
                  margin: "10px auto",
                  justifyContent: "center",
                  backgroundColor: "#1c1e23",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                }}
                data-bs-toggle="modal"
                data-bs-target="#promotionDetailModal"
                onClick={() => {
                  setHtmlFilePath(promotion.htmlfile);
                  setTitle(promotion.title);
                }}
              >
                <div className="card-body">
                  <div>
                    <img
                      alt={promotion.title}
                      src={promotion.imageurl}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                  <div
                    className="fw-bold mb-2 text-font3"
                    style={{ color: "#f5be09" }}
                  >
                    {promotion.title}
                  </div>
                  <div className="text-font2" style={{ color: "white" }}>
                    {promotion.description}
                  </div>
                  {/* <div className="text-center">
                    <button
                      type="button"
                      className="btn btn-prim p-1 px-4 fw-bold text-font3"
                      data-bs-toggle="modal"
                      data-bs-target="#promotionDetailModal"
                      onClick={() => {
                        setHtmlFilePath(promotion.htmlfile);
                        setTitle(promotion.title);
                      }}
                      style={{ backgroundColor: "#f5be09", color: "#000000" }}
                    >
                      {intl.formatMessage({ id: "detail" })}
                    </button>
                  </div> */}
                </div>
              </div>
            )
          )}
      </div>
    </div>
  );
};
const styles = {
  navBar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "60px",
    backgroundColor: "black",
  },
  backButton: {
    position: "absolute",
    left: "10px",
    fontSize: "20px",
  },
  title: {
    fontWeight: "bold",
    color: "white",
  },
};

export const Modal = ({ modalId, children, dismissBtnRef }) => {
  return (
    <div
      className="modal fade"
      id={modalId}
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        style={{ margin: "0 30px" }}
      >
        <div
          className="modal-content"
          style={{
            backgroundColor: "#1c1e23",
            color: "white",
            border: "1px solid #f5be09",
          }}
        >
          <div
            className="d-flex align-items-end justify-content-end"
            style={{ marginRight: "5px" }}
          >
            <i
              className="bi bi-x-circle modal-close cursor-pointer fs-3"
              style={{ color: "red" }}
              data-bs-dismiss="modal"
              aria-label="Close"
              ref={dismissBtnRef}
            />
          </div>
          <div
            className="modal-body p-0 text-font3"
            style={{
              backgroundColor: "#1c1e23",
            }}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

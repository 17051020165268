import { useIntl } from "react-intl";
import { Modal } from "../../../../../common/components/Modal";
import { useRef } from "react";

export const BankAccountDialog = ({
  selectedBank,
  setSelectedBank,
  bankList,
  isShow,
  setIsShow,
}) => {
  const intl = useIntl();

  return (
    <>
      {isShow ? (
        <>
          <div
            className="modal fade show"
            id="gameDepositModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-modal="true"
            role="dialog"
            style={{ display: "block" }}
            onClick={() => {
              setIsShow(false);
            }}
          >
            <div
              className="modal-dialog modal-dialog-centered"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="modal-content" style={{background:'#1c1e23'}}>
                <div className="d-flex align-items-center justify-content-between custom-border-bottom p-3">
                  <h1 className="modal-title text-font4" id="exampleModalLabel">
                    {intl.formatMessage({ id: "depositAccountList" })}
                  </h1>
                  <i
                    className="bi bi-x modal-close cursor-pointer fs-3"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setIsShow(false);
                    }}
                  />
                </div>
                <div className="modal-body p-0">
                  <div className="p-3">
                    {bankList.map((bank, index) => (
                      <div
                        className={`rounded custom-border p-3 d-flex justify-content-between align-items-top cursor-pointer ${
                          bankList.length === index + 1 ? "" : "mb-3"
                        } ${selectedBank === index ? "bank-active" : ""}`}
                        style={{ background: "rgba(0,0,0,0.2)" }}
                        key={index}
                        onClick={() => {
                          setSelectedBank(index);
                          setIsShow(false);
                        }}
                      >
                        <div>
                          <div className="text-muted small text-font2">
                            {intl.formatMessage({ id: "bankName" })}
                          </div>
                          <div className="mb-2 fw-bold text-font2">{bank.bankname}</div>
                          <div className="text-muted small text-font2 ">
                            {intl.formatMessage({ id: "accountName" })}
                          </div>
                          <div className="mb-2 fw-bold text-font2">{bank.accountname}</div>
                          <div className="text-muted small text-font2">
                            {intl.formatMessage({ id: "accountNumber" })}
                          </div>
                          <div className="fw-bold text-font2">{bank.accountnum}</div>
                        </div>
                        {selectedBank === index && (
                          <div className="text-prim text-font3">
                            {intl.formatMessage({ id: "selected" })}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal-backdrop fade show"
            onClick={() => setIsShow(false)}
          ></div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
import { Field, Form, Formik } from "formik";
import { useIntl } from "react-intl";
import {
  ErrorContainer,
  Input,
} from "../../../../../common/components/control/InputField";
import { depositSchema } from "../../../yupSchema/depositSchema";
import { CheckIsNull } from "../../../../../../utils/CommonHelper";
import { useState } from "react";

export const DepositAtmForm = ({ formikRef, saveValues, receiptRef }) => {
  const intl = useIntl();
  const [receipt, setReceipt] = useState();
  const [receiptTouched, setReceiptTouched] = useState(false);

  return (
    <Formik
      initialValues={{
        amount: "",
      }}
      innerRef={formikRef}
      validationSchema={depositSchema(intl)}
      enableReinitialize={true}
      onSubmit={(values, formikHelpers) => {
        if (!CheckIsNull(receipt)) {
          const queryParams = {
            topupamount: values.amount,
            receipt: receipt,
          };

          saveValues(queryParams);
        } else {
          setReceiptTouched(true);
        }
      }}
    >
      {({
        values,
        handleSubmit,
        setFieldValue,
        touched,
        errors,
        setFieldError,
      }) => (
        <Form>
          <div className="mb-2">
            <div className="mb-2">
              <label className="mb-2 text-input-label text-font3">
                {intl.formatMessage({ id: "receipt" })}
              </label>
              <input
                name="image_chooser"
                type="file"
                className="form-control text-font2"
                placeholder={intl.formatMessage({ id: "receipt" })}
                autoComplete="off"
                accept="image/*"
                onChange={(e) => {
                  setReceipt(e.currentTarget.files[0]);
                }}
                ref={receiptRef}
                onClick={() => setReceiptTouched(true)}
              />
            </div>
            <ErrorContainer
              isShow={receiptTouched && CheckIsNull(receipt)}
              errorMsg={intl.formatMessage({ id: "receiptRequired" })}
            />
          </div>
          <label
            className="mb-2 text-input-label text-font3"
            style={{ color: "white" }}
          >
            {intl.formatMessage({ id: "amount" })}
          </label>
          <div className="mb-2">
            <Field
              className="text-font2"
              name="amount"
              component={Input}
              placeholder={intl.formatMessage({ id: "amount" })}
              autoComplete="off"
              type="number"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

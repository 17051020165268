import { registerLocale } from "react-datepicker";
import { useIntl } from "react-intl";
import { Route, Routes, Navigate } from "react-router-dom";
import { getDateLocale } from "../utils/DateLocale";
import { React } from "react";
// import { HomeWrapper } from "../pages/home/HomeWrapper";
import { MasterLayout } from "../modules/common/components/MasterLayout";
import { SlotWrapper } from "../pages/slot/SlotWrapper";
import { SettingWrapper } from "../pages/setting/SettingWrapper";
import { LiveCasinoWrapper } from "../pages/liveCasino/LiveCasinoWrapper";
import { FishingWrapper } from "../pages/fishing/FishingWrapper";
// import { PromotionWrapper } from "../pages/promotion/PromotionWrapper";
import { AppGamesWrapper } from "../pages/appGames/AppGamesWrapper";
import { HomeWrapper } from "../pages/home/HomeWrapper";
import { LotteryWrapper } from "../pages/lottery/LotteryWrapper";
import { GamePlayWrapper } from "../pages/gamePlay/GamePlayWrapper";
import { SportWrapper } from "../pages/sport/SportWraper";

const PrivateRoutes = () => {
  const intl = useIntl();
  let locale = intl.locale;
  registerLocale(locale, getDateLocale(locale));

  return (
    <>
      <Routes>
        <Route element={<MasterLayout />}>
          <Route path="home/*" element={<HomeWrapper />} />
          <Route path="slot/*" element={<SlotWrapper />} />
          <Route path="live/*" element={<LiveCasinoWrapper />} />
          <Route path="fishing/*" element={<FishingWrapper />} />
          <Route path="app/*" element={<AppGamesWrapper />} />
          <Route path="gamePlay/*" element={<GamePlayWrapper />} />
          <Route path="setting/*" element={<SettingWrapper />} />
          <Route path="sport/*" element={<SportWrapper />} />
          <Route path="lottery/*" element={<LotteryWrapper />} />
          <Route path="*" element={<Navigate to={"/home"} />} />
        </Route>
      </Routes>
    </>
  );
};

export { PrivateRoutes };

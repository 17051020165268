import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { PaymentMethod } from "../../../../../constants/DepositNavConst";
import { ApiStatusTypeEnum } from "../../../../../enumeration/ApiStatusTypeEnum";
import { toAbsoluteUrl } from "../../../../../utils/CommonHelper";
import * as actions from "../../../redux/SettingAction";
import { HistoryAtm } from "./HistoryAtm";
import { HistoryPaymentGateway } from "./HistoryPaymentGateway";
import { HistoryTelco } from "./HistoryTelco";

export const DepositHistory = () => {
  const [paymentMethod, setPaymentMethod] = useState();
  const intl = useIntl();
  const dispatch = useDispatch();

  const settingState = useSelector(({ setting }) => setting, shallowEqual);

  useEffect(() => {
    dispatch(actions.getDepositWalletUI({ action: "topupwalletui" })).then(
      (res) => {
        const data = res.data;
        if (parseInt(data.code) === ApiStatusTypeEnum.SUCCESS) {
          setPaymentMethod(
            data.topupWalletUIs.find(
              (topupWalletUI) => topupWalletUI.active === "true"
            )?.["paymenttype"]
          );
        }
      }
    );
  }, []);

  return (
    <>
      {paymentMethod && (
        <div
          className="d-flex mb-3 custom-border-bottom pb-3"
          style={{ width: "95%", margin: "0 auto" }}
        >
          {settingState.depositWalletUI &&
            settingState.depositWalletUI.topupWalletUIs &&
            settingState.depositWalletUI.topupWalletUIs
              .filter((topupWalletUI) => topupWalletUI.active === "true")
              .map((topupWalletUI, index) => (
                <div key={index} className="card cursor-pointer me-4">
                  <div
                    style={{
                      background:
                        paymentMethod === topupWalletUI.paymenttype
                          ? "#f5be09"
                          : "#2e323a",
                      display: "flex",
                      width: "80px",
                      height: "80px",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "10px",
                      borderRadius: "5px",
                    }}
                    onClick={() => {
                      setPaymentMethod(topupWalletUI.paymenttype);
                    }}
                  >
                    <div
                      className={`d-flex flex-column align-items-center menu-item `}
                    >
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={toAbsoluteUrl(
                            `image/${topupWalletUI.paymenttype}.png`
                          )}
                          alt="Image Alt Text"
                          style={{
                            width: "30px",
                            height: "30px",
                            filter:
                              paymentMethod === topupWalletUI.paymenttype
                                ? "brightness(0%)"
                                : "brightness(100%)",
                          }}
                        />
                      </span>
                      <div
                        style={{
                          color:
                            paymentMethod === topupWalletUI.paymenttype
                              ? "black"
                              : "white",
                          textAlign: "center",
                        }}
                        className="text-font2"
                      >
                        {topupWalletUI.paymentname}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
        </div>
      )}
      <div style={{ width: "95%", margin: "0 auto" }}>
        {paymentMethod === PaymentMethod.ATM ? (
          <HistoryAtm />
        ) : paymentMethod === PaymentMethod.PAYMENT_GATEWAY ? (
          <HistoryPaymentGateway />
        ) : paymentMethod === PaymentMethod.TELCO ? (
          <HistoryTelco />
        ) : (
          <div className="fs-4 m-5 text-center text-light">
            {intl.formatMessage({ id: "noPaymentMethodFound" })}
          </div>
        )}
      </div>
    </>
  );
};

import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Skeleton from "react-loading-skeleton";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  LotteryHLCompanyConst,
  LotteryMKTCompanyConst,
} from "../../../../constants/LotteryCompanyConst";
import {
  LotteryBetTypeConst,
  LotteryTypeConst,
  LotteryTypeTitleConst,
} from "../../../../constants/LotteryNavConst";
import { toAbsoluteUrl } from "../../../../utils/CommonHelper";
import * as actions from "../../redux/LotteryAction";

export const LotteryPayout = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [lotteryType, setLotteryType] = useState(
    LotteryTypeConst.BASIC_LOTTERY
  );

  const lotteryState = useSelector(({ lottery }) => lottery, shallowEqual);

  useEffect(() => {
    dispatch(actions.getLotteryPrizeUI({ action: "vbossprizelist" }));
  }, []);

  const getLotteryPrize = (lotteryBetType) => {
    const betPrizeList =
      lotteryType === LotteryTypeConst.BASIC_LOTTERY
        ? lotteryState.lotteryPrizeUI.mKTPrizeLists
        : lotteryState.lotteryPrizeUI.hLPrizeLists;

    return betPrizeList.find(
      (betPrize) => betPrize.bettypedscp === lotteryBetType
    ).betType;
  };

  return (
    <>
      <div className="d-flex mb-4">
        {Object.entries(LotteryTypeConst).map(([key, value], index) => (
          <div
            key={index}
            className={`p-2 px-4 cursor-pointer me-3 text-font3 rounded ws-nowrap text-center ${
              lotteryType === value
                ? "bg-prim text-dark fw-bold text-font3"
                : ""
            }`}
            onClick={() => {
              setLotteryType(value);
            }}
            style={{ background: "rgba(255,255,255,0.1)" }}
          >
            {intl.formatMessage({ id: LotteryTypeTitleConst[index].title })}
          </div>
        ))}
      </div>
      {lotteryState.listLoading || !lotteryState.lotteryPrizeUI ? (
        <Skeleton className="w-100" style={{ height: "500px" }} />
      ) : (
        <>
          <div className="d-flex align-items-center mb-3 overflow-scroll">
            {(lotteryType === LotteryTypeConst.HL_LOTTERY
              ? LotteryHLCompanyConst
              : LotteryMKTCompanyConst
            ).map((lotteryCompany, index) => (
              <div key={index}>
                <img
                  style={{ width: "40px" }}
                  className="me-3"
                  src={toAbsoluteUrl(lotteryCompany.lotteryUrl)}
                  alt={lotteryCompany.lotteryCode}
                />
              </div>
            ))}
          </div>
          <div className="d-flex custom-border border-bottom-0 text-font3">
            <div className="col-4 p-3 fw-bold custom-border-end bg-light text-dark text-center">
              {intl.formatMessage({ id: "prize" }).toUpperCase()}
            </div>
            <div className="d-flex col-8">
              <div className="col-4 p-3 fw-bold custom-border-end bg-light text-dark text-center">
                {intl.formatMessage({ id: "big" }).toUpperCase()}
              </div>
              <div className="col-4 p-3 fw-bold custom-border-end bg-light text-dark text-center">
                {intl.formatMessage({ id: "small" }).toUpperCase()}
              </div>
              <div className="col-4 p-3 fw-bold bg-light text-dark text-center">
                {intl.formatMessage({ id: "4a" }).toUpperCase()}
              </div>
            </div>
          </div>
          <div className="d-flex custom-border border-bottom-0">
            <div className="col-4 p-3 fw-bold custom-border-end text-muted text-font3">
              1st {intl.formatMessage({ id: "prize" })}
            </div>
            <div className="d-flex col-8">
              <div className="col-4 custom-border-end p-3 fw-semibold text-end text-font2">
                {getLotteryPrize(LotteryBetTypeConst.BIG).prize1}
              </div>
              <div className="col-4 custom-border-end p-3 fw-semibold text-end text-font2">
                {getLotteryPrize(LotteryBetTypeConst.SMALL).prize1}
              </div>
              <div className="col-4 p-3 fw-semibold text-end text-font2">
                {getLotteryPrize(LotteryBetTypeConst.FOUR_A).prize1}
              </div>
            </div>
          </div>
          <div className="d-flex custom-border border-bottom-0">
            <div className="col-4 p-3 fw-bold custom-border-end text-muted text-font3">
              2nd {intl.formatMessage({ id: "prize" })}
            </div>
            <div className="d-flex col-8">
              <div className="col-4 custom-border-end p-3 fw-semibold text-end text-font2">
                {getLotteryPrize(LotteryBetTypeConst.BIG).prize2}
              </div>
              <div className="col-4 custom-border-end p-3 fw-semibold text-end text-font2">
                {getLotteryPrize(LotteryBetTypeConst.SMALL).prize2}
              </div>
              <div className="col-4 p-3 fw-semibold text-end text-font2">
                {getLotteryPrize(LotteryBetTypeConst.FOUR_A).prize2}
              </div>
            </div>
          </div>
          <div className="d-flex custom-border border-bottom-0">
            <div className="col-4 p-3 fw-bold custom-border-end text-muted text-font3">
              3rd {intl.formatMessage({ id: "prize" })}
            </div>
            <div className="d-flex col-8">
              <div className="col-4 custom-border-end p-3 fw-semibold text-end text-font2">
                {getLotteryPrize(LotteryBetTypeConst.BIG).prize3}
              </div>
              <div className="col-4 custom-border-end p-3 fw-semibold text-end text-font2">
                {getLotteryPrize(LotteryBetTypeConst.SMALL).prize3}
              </div>
              <div className="col-4 p-3 fw-semibold text-end text-font2">
                {getLotteryPrize(LotteryBetTypeConst.FOUR_A).prize3}
              </div>
            </div>
          </div>
          <div className="d-flex custom-border border-bottom-0">
            <div className="col-4 p-3 fw-bold custom-border-end text-muted text-font3">
              {intl.formatMessage({ id: "special" })}
            </div>
            <div className="d-flex col-8">
              <div className="col-4 custom-border-end p-3 fw-semibold text-end text-font2">
                {getLotteryPrize(LotteryBetTypeConst.BIG).prize4}
              </div>
              <div className="col-4 custom-border-end p-3 fw-semibold text-end text-font2">
                {getLotteryPrize(LotteryBetTypeConst.SMALL).prize4}
              </div>
              <div className="col-4 p-3 fw-semibold text-end text-font2">
                {getLotteryPrize(LotteryBetTypeConst.FOUR_A).prize4}
              </div>
            </div>
          </div>
          <div className="d-flex custom-border">
            <div className="col-4 p-3 fw-bold custom-border-end text-muted text-font3">
              {intl.formatMessage({ id: "consolation" })}
            </div>
            <div className="d-flex col-8">
              <div className="col-4 custom-border-end p-3 fw-semibold text-end text-font2">
                {getLotteryPrize(LotteryBetTypeConst.BIG).prize5}
              </div>
              <div className="col-4 custom-border-end p-3 fw-semibold text-end text-font2">
                {getLotteryPrize(LotteryBetTypeConst.SMALL).prize5}
              </div>
              <div className="col-4 p-3 fw-semibold text-end text-font2">
                {getLotteryPrize(LotteryBetTypeConst.FOUR_A).prize5}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

import { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";

export const LiveChat = () => {
  const gamePlayState = useSelector(({ gamePlay }) => gamePlay, shallowEqual);
  const screenHeight = window.innerHeight;
  const screenWidth = window.innerWidth;
  const [dateNow, setDateNow] = useState(Date.now());
  const [loading, setLoading] = useState(true);

  const handleLoad = () => {
    setLoading(false);
  };

  useEffect(() => {
    setDateNow(Date.now());
  }, []);

  return (
    <div className="d-flex flex-column">
      <div style={{ backgroundColor: "black" }}>
        {loading && (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ backgroundColor: "black", height: screenHeight }}
          >
            <div
              className="spinner-border"
              role="status"
              style={{ scale: "1.2" }}
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
        <iframe
          key={dateNow}
          style={{
            height: screenHeight,
            width: screenWidth,
            display: loading ? "none" : "block",
            marginBottom: "7vh",
          }}
          src={
            "https://secure.livechatinc.com/licence/14664588/v2/open_chat.cgi"
          }
          onLoad={handleLoad}
        />
      </div>
    </div>
  );
};

/* eslint-disable jsx-a11y/anchor-is-valid */
import { Route, Routes } from "react-router-dom";
import { createContext } from "react";
import { LiveCasinoListing } from "../../modules/liveCasino/component/LiveCasinoListing";

const LiveCasinoContext = createContext();

const LiveCasinoWrapper = () => {
  const value = {};

  return (
    <LiveCasinoContext.Provider value={value}>
      <Routes>
        <Route path="/:companyCode" element={<LiveCasinoListing />} />

      </Routes>
    </LiveCasinoContext.Provider>
  );
};

export { LiveCasinoWrapper };

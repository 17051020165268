import { Button, Drawer } from "@mui/material";
import { useEffect, useState } from "react";
import { FiArrowLeft } from "react-icons/fi";
import { IoIosArrowDown } from "react-icons/io";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { toAbsoluteUrl } from "../../../utils/CommonHelper";

const GameItem = ({
  game,
  handlePlayGame,
  searchName,
  className,
  isHideGameName = false,
  imageStyle = { width: "100%", height: "51px" },
}) => {
  const intl = useIntl();
  const [isShowPlay, setIsShowPlay] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };
  return (
    <div
      className={className ? className : "mb-2"}
      style={
        className
          ? {}
          : {
              width: "23%",
              fontSize: "10px",
              marginLeft: "1%",
              marginRight: "1%",
            }
      }
    >
      <div
        className="rounded company-active position-relative"
        onMouseEnter={() => setIsShowPlay(true)}
        onMouseLeave={() => setIsShowPlay(false)}
      >
        <div style={imageStyle}>
          <div className="position-relative h-100">
            {!isImageLoaded && (
              <div
                className="skeleton"
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  color: "white",
                }}
              />
            )}
            <img
              src={game.imageurl}
              alt={game.gamename}
              className="w-100 mb-2 skeleton"
              style={{
                width: "100%",
                height: "100%",
              }}
              onLoad={handleImageLoad}
            />
          </div>
        </div>
        {isShowPlay && (
          <div
            className="position-absolute w-100 top-0 bottom-0 rounded d-flex flex-column align-items-center justify-content-center"
            style={{ background: "rgba(0,0,0,0.8)" }}
          >
            <button
              type="button"
              className="btn btn-info fw-bold text-font1"
              onClick={() => handlePlayGame(game.gamecode, searchName)}
            >
              {intl.formatMessage({ id: "play" })}
            </button>
          </div>
        )}
      </div>
      {!isHideGameName && (
        <div
          className="text-center text-truncate px-2"
          style={{ color: "white" }}
        >
          {game.gamename}
        </div>
      )}
    </div>
  );
};

export const GameListing = ({
  gameCompanyList,
  gameList,
  gameCategoryList,
  setCategory,
  category,
  companyCode,
  handlePlayGame,
  setCompanyOffset,
  companyOffset = 0,
  initialSearchName,
  companyName,
  gameType,
  gameLobby,
}) => {
  const [gameName, setGamename] = useState(initialSearchName ?? "");
  const intl = useIntl();
  const [openCompanyList, setOpenCompanyList] = useState(false);
  const [offset, setOffset] = useState(companyOffset);
  const navigate = useNavigate();

  useEffect(() => {
    setCompanyOffset(offset);
  }, [offset]);

  const _showCompanyList = () => {
    setOpenCompanyList(true);
  };

  const handleCloseCompanyList = () => {
    setOpenCompanyList(false);
  };

  const changeCompanyList = (companyGameType, companyListCode) => {
    navigate(`/${companyGameType}/${companyListCode}`);
    setOpenCompanyList(false);
  };

  useEffect(() => {
    const handleBrowserBackButton = () => {
      navigate(`/home`, {
        state: { gameType },
      });
    };

    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handleBrowserBackButton);

    return () => {
      window.removeEventListener("popstate", handleBrowserBackButton);
    };
  }, []);

  useEffect(() => {
    document.body.classList.add(
      "pages-bg",
      "h-100",
      "scroll-y-default",
      "bg-dark"
    );
    document.querySelector("html").classList.add("h-100", "scroll-y-default");
    document
      .querySelector("#root")
      .classList.add("h-100", "w-100", "scroll-y-default", "position-absolute");

    document.body.style.backgroundImage = `url(${toAbsoluteUrl(
      "/image/homebackground.png"
    )})`;

    return () => {
      document.body.style.backgroundImage = ``;
      document.body.classList.remove(
        "pages-bg",
        "h-100",
        "scroll-y-default",
        "bg-dark"
      );
      document
        .querySelector("html")
        .classList.remove("h-100", "scroll-y-default");
      document
        .querySelector("#root")
        .classList.remove(
          "h-100",
          "w-100",
          "scroll-y-default",
          "position-absolute"
        );
    };
  }, []);

  return (
    <div className="d-flex flex-column">
      <div style={styles.navBar}>
        <div
          style={styles.backButton}
          onClick={() =>
            navigate(`/home`, {
              state: { gameType },
            })
          }
        >
          <FiArrowLeft style={{ color: "white" }} />
        </div>
        <div
          style={styles.title}
          className="text-font4"
          onClick={_showCompanyList}
        >
          {companyName}
          <IoIosArrowDown style={{ paddingLeft: "5px" }} />
        </div>
      </div>
      <div style={{ height: "10px" }}></div>
      <div
        style={{
          backgroundColor: "#1c1e23",
          width: "95%",
          margin: "0 auto",
          paddingTop: "5px",
          paddingBottom: "6vh",
        }}
      >
        <div className="input-group">
          <input
            type="text"
            className="form-control text-font2"
            placeholder={intl.formatMessage({ id: "search" })}
            aria-describedby="basic-addon1"
            value={gameName}
            onChange={(e) => {
              setGamename(e.target.value);
            }}
          />
          <span className="input-group-text" id="basic-addon1">
            <i className="fa fa-search" />
          </span>
        </div>

        <div
          style={{
            overflowX: "auto",
            display: "flex",
            justifyContent: "space-between",
            marginTop: "10px",
          }}
        >
          {gameCategoryList &&
            gameCategoryList.map((gameCategory, index) => (
              <div
                className={`px-4 py-1 text-center mb-3 text-font2 ${
                  index + 1 === gameCategoryList.length ? "" : "me-4"
                } cursor-pointer rounded-pill ${
                  category === gameCategory.category
                    ? "category-active fw-bold "
                    : ""
                }`}
                onClick={() => setCategory(gameCategory.category)}
                key={index}
                style={{
                  color: "white",
                  padding: "15px",
                  whiteSpace: "nowrap",
                }}
              >
                {gameCategory.name}
              </div>
            ))}
        </div>
        {gameLobby && (
          <div>
            <GameItem
              className="w-100 mb-3"
              imageStyle={{ width: "100%", height: "110px" }}
              game={gameLobby}
              searchName={gameName}
              handlePlayGame={handlePlayGame}
              isHideGameName={true}
            />
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {gameList &&
          gameList.filter((game) =>
            game.gamename.toLowerCase().includes(gameName.toLowerCase())
          ).length > 0 ? (
            gameList
              .filter((game) =>
                game.gamename.toLowerCase().includes(gameName.toLowerCase())
              )
              .map((game, index) => (
                <GameItem
                  key={index}
                  game={game}
                  searchName={gameName}
                  handlePlayGame={handlePlayGame}
                />
              ))
          ) : (
            <div
              className="text-font3"
              style={{
                width: "95%",
                color: "white",
                textAlign: "center",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              {intl.formatMessage({ id: "noRecordFound" })}
            </div>
          )}
        </div>
      </div>
      <Drawer
        anchor="bottom"
        open={openCompanyList}
        onClose={handleCloseCompanyList}
        style={{ minHeight: "40vh" }}
      >
        <div style={{ backgroundColor: "#1c1e23", padding: "16px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button onClick={handleCloseCompanyList}>
              <FiArrowLeft style={{ color: "white" }} />
            </Button>
            <div className="text-font3" style={{ flex: 7, color: "white" }}>
              {intl.formatMessage({ id: "companyList" })}
            </div>
          </div>

          <div
            style={{
              color: "white",
              cursor: "pointer",
              width: "100%",
              paddingTop: "20px",
            }}
          >
            {gameCompanyList &&
              gameCompanyList.map((company) => (
                <div
                  onClick={() =>
                    changeCompanyList(company.gametype, company.companycode)
                  }
                  key={company.id}
                  className="text-font2"
                  style={{
                    width: "100%",
                    height: "40px",
                    borderTop: "1px solid #262626",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {company.companyname}
                </div>
              ))}
          </div>
        </div>
      </Drawer>
    </div>
  );
};
const styles = {
  navBar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "60px",
    backgroundColor: "black",
  },
  backButton: {
    position: "absolute",
    left: "10px",
    fontSize: "20px",
  },
  title: {
    fontWeight: "bold",
    color: "white",
    margin: "0 auto",
  },
};

import { Field, Form, Formik } from "formik";
import { editPasswordSchema } from "../../yupSchema/editPasswordSchema";
import { useIntl } from "react-intl";
import { Input } from "../../../../common/components/control/InputField";

export const EditPasswordForm = ({ formikRef, saveValues }) => {
  const intl = useIntl();

  return (
    <Formik
      initialValues={{
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      }}
      innerRef={formikRef}
      validationSchema={editPasswordSchema(intl)}
      enableReinitialize={true}
      onSubmit={(values, formikHelpers) => {
        if (values.newPassword === values.confirmPassword) {
          let queryParams = {
            oldpassword: values.oldPassword,
            newpassword: values.newPassword,
            repeatpassword: values.confirmPassword,
          };
          saveValues(queryParams);
        } else {
          formikHelpers.setFieldError(
            "confirmPassword",
            intl.formatMessage({ id: "invalidConfirmPassword" })
          );
        }
      }}
    >
      {({
        values,
        handleSubmit,
        setFieldValue,
        touched,
        errors,
        setFieldError,
      }) => (
        <Form>
          <div className="mb-2">
            <label className="mb-2 text-input-label" style={{ color: "white" }}>
              {intl.formatMessage({ id: "oldPassword" })}
            </label>

            <Field
              name="oldPassword"
              component={Input}
              placeholder={intl.formatMessage({ id: "oldPassword" })}
              autoComplete="off"
              type="password"
            />
          </div>
          <div className="mb-2">
            <label className="mb-2 text-input-label" style={{ color: "white" }}>
              {intl.formatMessage({ id: "newPassword" })}
            </label>

            <Field
              name="newPassword"
              component={Input}
              placeholder={intl.formatMessage({ id: "newPassword" })}
              autoComplete="off"
              type="password"
            />
          </div>
          <div className="mb-2">
            <label className="mb-2 text-input-label" style={{ color: "white" }}>
              {intl.formatMessage({ id: "confirmPassword" })}
            </label>

            <Field
              name="confirmPassword"
              component={Input}
              placeholder={intl.formatMessage({ id: "confirmPassword" })}
              autoComplete="off"
              type="password"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

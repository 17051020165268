import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import Skeleton from "react-loading-skeleton";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as masterActions from "../../../common/redux/MasterLayoutAction";
import SweetAlert2 from "react-sweetalert2";
import * as actions from "../../redux/SettingAction";
import { ApiStatusTypeEnum } from "../../../../enumeration/ApiStatusTypeEnum";
import { Overlay } from "../../../common/components/Overlay";
import { FiArrowLeft } from "react-icons/fi";
import { Link } from "react-router-dom";
import "bootstrap-icons/font/bootstrap-icons.css";

export const Team = () => {
  const [swalProps, setSwalProps] = useState({});
  const intl = useIntl();
  const dispatch = useDispatch();
  const settingState = useSelector(({ setting }) => setting, shallowEqual);

  useEffect(() => {
    dispatch(actions.getTeamList());
  }, []);

  return (
    <div
      style={{
        backgroundColor: "black",
        backgroundImage: `url(${process.env.PUBLIC_URL}/image/homebackground.png)`,
        backgroundSize: "cover",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={styles.navBar}>
        <div style={styles.backButton}>
          <Link to="/setting/profile">
            <FiArrowLeft style={{ color: "white" }} />
          </Link>
        </div>
        <div style={styles.title} className="text-font4">
          {intl.formatMessage({ id: "team" })}
        </div>
      </div>
      {settingState.actionsLoading && <Overlay />}
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      <div
        style={{
          backgroundColor: "#1c1e23",
          width: "95%",
          margin: "10px auto",
          padding: "5px",
          color: "white",
          fontSize: "12px",
          borderRadius: "5px",
        }}
      >
        {settingState.teamList ? (
          settingState.teamList.downlineLists &&
          settingState.teamList.downlineLists.length > 0 ? (
            <div>
              {settingState.teamList.downlineLists.map((downline, index) => (
                <div
                  className="custom-border p-3 rounded d-flex mb-4"
                  key={index}
                  style={{
                    width: "95%",
                    margin: "10px auto",
                    backgroundColor: "#2e323a",
                  }}
                >
                  <div className="d-flex align-items-center col-6">
                    <div className="d-flex align-items-center me-2"></div>
                    <div className="flex-fill">
                      <div className="mb-2 text-font3">{downline.username}</div>
                      <div style={{ position: "relative" }}>
                        <div className="mb-2 text-muted">
                          {intl.formatMessage({ id: "joindate" })}
                          &nbsp; {downline.joindate}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center col-4">
                    <div className="d-flex align-items-center me-4"></div>
                    <div className="flex-fill">
                      <div className="mb-2 text-font3">
                        {downline.regtelemail}
                      </div>
                      <div style={{ position: "relative" }}>
                        <div className="mb-2 text-muted">
                          {intl.formatMessage({ id: "currency" })}:&nbsp;
                          {downline.currencycode}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="d-flex py-5 align-items-center justify-content-center">
              <div className="text-muted d-flex align-items-center justify-content-center flex-column">
                <i
                  style={{ color: "white" }}
                  className="bi bi-search fs-1 mb-3"
                />
                <div className="text-font2" style={{ color: "white" }}>
                  {intl.formatMessage({ id: "noDownline" })}
                </div>
              </div>
            </div>
          )
        ) : (
          <Skeleton className="w-100" height="600px" />
        )}
      </div>
    </div>
  );
};
const styles = {
  navBar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "60px",
    backgroundColor: "black",
  },
  backButton: {
    position: "absolute",
    left: "10px",
    fontSize: "20px",
  },
  title: {
    fontWeight: "bold",
    color: "white",
  },
};

import { createSlice } from "@reduxjs/toolkit";
import { ReduxCallTypesConst } from "../../../constants/ReduxCallTypesConst";

const fishingState = {
  fishingGameList: null,
  gamePlay: null,
  error: null,
  listLoading: false,
  actionsLoading: false,
  searchLoading: false,
  isRefresh: false,
};

export const fishingSlice = createSlice({
  name: "fishing",
  initialState: fishingState,
  reducers: {
    catchError: (state, action) => {
      state.error = action.payload.error;

      if (action.payload.callType === ReduxCallTypesConst.LIST) {
        state.listLoading = false;
      } else if (action.payload.callType === ReduxCallTypesConst.ACTION) {
        state.actionsLoading = false;
      } else if (action.payload.callType === ReduxCallTypesConst.DETAIL) {
        state.searchLoading = false;
      }
    },
    throwError: (state, action) => {
      state.error = action.payload.data.error;
    },
    startCall: (state, action) => {
      state.isRefresh = false;
      state.error = null;

      if (action.payload.callType === ReduxCallTypesConst.LIST) {
        state.listLoading = true;
      } else if (action.payload.callType === ReduxCallTypesConst.ACTION) {
        state.actionsLoading = true;
      } else if (action.payload.callType === ReduxCallTypesConst.DETAIL) {
        state.searchLoading = true;
      }
    },
    clearError: (state) => {
      state.error = null;
    },
    fishingGameListFetched: (state, action) => {
      state.listLoading = false;
      state.fishingGameList = action.payload.data ?? {};
    },
    clearFishingGameList: (state, action) => {
      state.fishingGameList = fishingState.fishingGameList;
    },
    playCompanyGame: (state, action) => {
      state.actionsLoading = false;
      state.gamePlay = action.payload.data ?? {};
    },
  },
});

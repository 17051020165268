import { authGet } from "../../common/redux/ApiClient";

const baseUrl = "http://35.240.204.237/es3MemberApi";

export function fetchLotteryResultUI() {
  return authGet(
    `http://34.87.51.38/ok99memberApi/api/Member5/LotteryResultUI`,
    {}
  );
}

import { useEffect, useState } from "react";
import { LotteryBetForm } from "./LotteryBetForm";
import { useIntl } from "react-intl";
import { CheckIsNull, toAbsoluteUrl } from "../../../../utils/CommonHelper";
import SweetAlert2 from "react-sweetalert2";
import { Overlay } from "../../../common/components/Overlay";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/LotteryAction";
import { ApiStatusTypeEnum } from "../../../../enumeration/ApiStatusTypeEnum";
import {
  LotteryTypeConst,
  LotteryTypeTitleConst,
} from "../../../../constants/LotteryNavConst";

export const LotteryBetDialog = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [longCodeList, setLongCodeList] = useState([]);
  const [swalProps, setSwalProps] = useState({});
  const [season, setSeason] = useState(1);
  const [zIndex, setZIndex] = useState(1);
  const [lotteryType, setLotteryType] = useState(
    LotteryTypeConst.BASIC_LOTTERY
  );
  const [lotteryBalance, setLotteryBalance] = useState(
    intl.formatMessage({ id: "loading" })
  );
  const [isRefreshClick, setIsRefreshClick] = useState(false);

  const lotteryState = useSelector(({ lottery }) => lottery, shallowEqual);

  const saveValues = (longCode) => setLongCodeList([...longCodeList, longCode]);

  const confirmSubmitNumber = () => {
    const longCode = longCodeList.map((longCD) => longCD.code).join("|");
    const fullLongCode = `D${season}|${longCode}`;
    setZIndex(9999);
    const queryParams = {
      action: "vbossbuy4d",
      betformat: fullLongCode,
      companytype: lotteryType,
    };

    dispatch(actions.buyLottery(queryParams)).then((resp) => {
      resp = resp.data;
      setZIndex(1);

      if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
        setLongCodeList([]);
        setLotteryBalance(resp.gamebalance);
        setSwalProps({
          show: true,
          icon: "success",
          title: intl.formatMessage({ id: "success" }),
          text: intl.formatMessage({ id: "purchaseSuccessfully" }),
          onResolve: () => {
            setSwalProps({});
          },
        });
      } else {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };

  const getCompanyGameBalance = () =>
    dispatch(
      actions.getCompanyGameBalance({
        action: "membertargetbalance",
        companycode: "vboss",
      })
    ).then((resp) => {
      resp = resp.data;
      if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
        setLotteryBalance(resp.balance);
      }
    });

  useEffect(() => {
    getCompanyGameBalance();
    dispatch(actions.getLotteryUI({ action: "vboss4dbuyui" }));
  }, []);

  const handleReloadBalance = () => {
    getCompanyGameBalance();
    setIsRefreshClick(true);

    setTimeout(() => {
      setIsRefreshClick(false);
    }, 1000);
  };

  return (
    <div style={{ marginBottom: "10vh" }}>
      <SweetAlert2
        {...swalProps}
        onResolve={() => {
          setSwalProps({});
        }}
        customClass="custom-swal"
      />
      {lotteryState.actionsLoading && <Overlay zIndex={zIndex} />}
      <div className="mb-3 custom-border-bottom d-flex justify-content-between align-items-center">
        <div className="col-4 pb-3">
          <label className="mb-2 text-input-label text-font3">
            {intl.formatMessage({ id: "season" })}
          </label>
          <select
            className={`${
              longCodeList.length > 0 ? "text-dark" : ""
            } form-control text-font2`}
            style={{ background: "#1c1e23" }}
            value={season}
            onChange={(e) => setSeason(parseInt(e.target.value))}
            // disabled={longCodeList.length > 0}
            disabled={true}
          >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
          </select>
        </div>
        <div>
          <div className="card">
            <div
              className="card-body p-2 px-3"
              style={{ background: "rgba(255,255,255,0.1)" }}
            >
              <div className="text-end text-font3">
                {intl.formatMessage({ id: "balance" })}
              </div>
              <div className="fw-bold mt-2 d-flex align-items-center justify-content-end text-font3">
                <div>{lotteryBalance}</div>
                <i
                  className={`fa fa-refresh cursor-pointer ms-2 ${
                    isRefreshClick ? "spinning-animation" : ""
                  }`}
                  onClick={() => handleReloadBalance()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-2">
        <label className="mb-2 text-input-label text-font3">
          {intl.formatMessage({ id: "chooseLotteryType" })}
        </label>
        <div className="d-flex">
          {Object.entries(LotteryTypeConst).map(([key, value], index) => (
            <div
              key={index}
              className={`text-font3 p-2 px-4 cursor-pointer me-3 ws-nowrap rounded text-center ${
                lotteryType === value
                  ? "bg-light-success text-dark fw-bold text-font3"
                  : ""
              }`}
              onClick={() => {
                setLotteryType(value);
                setLongCodeList([]);
              }}
              style={{ background: "rgba(255,255,255,0.1)" }}
            >
              {intl.formatMessage({ id: LotteryTypeTitleConst[index].title })}
            </div>
          ))}
        </div>
      </div>
      <LotteryBetForm saveValues={saveValues} lotteryType={lotteryType} />
      <div className="custom-border-bottom my-4 mb-2 pb-2 text-font3">
        {intl.formatMessage({ id: "numberList" })}
      </div>
      <div>
        {longCodeList.length > 0 ? (
          <>
            {longCodeList.map((longCode, index) => (
              <div className="card mb-2 border-0" key={index}>
                <div
                  className="card-body border-0"
                  style={{ background: "rgba(0,0,0,0.9)", color: "#fff" }}
                >
                  <div className="d-flex align-items-center">
                    <div className="flex-fill">
                      <div className="d-flex w-100 align-items-top">
                        <div className="me-4 col-3">
                          <div
                            className="fw-bold ws-nowrap"
                            style={{ fontSize: "12px" }}
                          >
                            {intl.formatMessage({ id: "season" })} {season}
                          </div>
                          <div
                            className="fw-bold mb-2"
                            style={{ fontSize: "13px" }}
                          >
                            {longCode.number}
                          </div>
                        </div>
                        <div>
                          <div className="d-flex">
                            <div className="d-flex flex-column me-3">
                              <div className="me-2 text-muted ws-nowrap text-font2">
                                {intl.formatMessage({ id: "big" })} :
                              </div>
                              <div className="text-font2">{longCode.big}</div>
                            </div>
                            <div className="d-flex flex-column me-3">
                              <div className="me-2 text-muted ws-nowrap text-font2">
                                {intl.formatMessage({ id: "small" })} :
                              </div>
                              <div className="text-font2">{longCode.small}</div>
                            </div>
                            <div className="d-flex flex-column me-3">
                              <div className="me-2 text-muted ws-nowrap text-font2">
                                {intl.formatMessage({ id: "4a" })} :
                              </div>
                              <div className="text-font2">{longCode.fourA}</div>
                            </div>
                          </div>

                          <div className="d-flex">
                            <div className="d-flex flex-column me-3">
                              <div className="me-2 text-muted ws-nowrap text-font2">
                                {intl.formatMessage({ id: "betType" })} :
                              </div>
                              <div className="text-font2">
                                {CheckIsNull(longCode.betType)
                                  ? "-"
                                  : longCode.betType}
                              </div>
                            </div>
                            <div className="d-flex flex-column me-3">
                              <div className="me-2 text-muted ws-nowrap text-font2">
                                {intl.formatMessage({ id: "company" })} :
                              </div>
                              <div className="text-font2">
                                {longCode.companyList
                                  .map((company) => company.lotteryCode)
                                  .join()}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="ms-2">
                      <i
                        className="text-danger bi bi-trash cursor-pointer text-font3"
                        onClick={() => {
                          setZIndex(1);
                          setSwalProps({
                            show: true,
                            showCancelButton: true,
                            showConfirmButton: true,
                            confirmButtonText: intl.formatMessage({
                              id: "confirm",
                            }),
                            denyButtonText: intl.formatMessage({
                              id: "remove",
                            }),
                            icon: "info",
                            title: intl.formatMessage({ id: "alert" }),
                            text: intl.formatMessage({
                              id: "confirmRemoveNumber",
                            }),
                            onConfirm: () => {
                              setSwalProps({});
                              setLongCodeList(
                                longCodeList.filter(
                                  (longCd) => longCd.key !== longCode.key
                                )
                              );
                            },
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div
                    className="d-flex justify-content-end w-100 mt-3"
                    style={{ borderTop: "1px solid grey" }}
                  >
                    <div className="d-flex px-4">
                      <div className="me-2 text-end text-font3">
                        {intl.formatMessage({ id: "estimatePrice" })} :
                      </div>
                      <div className="text-end fw-bold text-font3">
                        {parseFloat(longCode.subTotal).toFixed(2)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className="d-flex py-5 align-items-center justify-content-center">
            <div className="text-muted d-flex align-items-center justify-content-center flex-column">
              <i className="bi bi-search fs-1 mb-3 text-font3" />
              <div>{intl.formatMessage({ id: "noRecordFound" })}</div>
            </div>
          </div>
        )}
      </div>
      {longCodeList.length > 0 && (
        <div className="mt-4 mb-2">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <button
                type="button"
                className="btn btn-danger me-2 py-1 text-font3"
                onClick={() => {
                  setZIndex(1);
                  setSwalProps({
                    show: true,
                    showCancelButton: true,
                    showConfirmButton: true,
                    confirmButtonText: intl.formatMessage({
                      id: "confirm",
                    }),
                    denyButtonText: intl.formatMessage({
                      id: "cancel",
                    }),
                    icon: "info",
                    title: intl.formatMessage({ id: "alert" }),
                    text: intl.formatMessage({
                      id: "confirmRemoveAllNumber",
                    }),
                    onConfirm: () => {
                      setSwalProps({});
                      setLongCodeList([]);
                    },
                  });
                }}
              >
                {intl.formatMessage({ id: "cancel" })}
              </button>
              <button
                type="button"
                className="btn btn-prim fw-bold py-1 text-font3"
                onClick={() => {
                  setZIndex(1);
                  setSwalProps({
                    show: true,
                    showCancelButton: true,
                    showConfirmButton: true,
                    confirmButtonText: intl.formatMessage({
                      id: "confirm",
                    }),
                    denyButtonText: intl.formatMessage({
                      id: "cancel",
                    }),
                    icon: "info",
                    title: intl.formatMessage({ id: "alert" }),
                    text: intl.formatMessage({
                      id: "confirmSubmitNumber",
                    }),
                    onConfirm: () => {
                      setSwalProps({});
                      confirmSubmitNumber();
                    },
                  });
                }}
              >
                {intl.formatMessage({ id: "submit" })}
              </button>
            </div>
            <div className="d-flex fw-semibold custom-border rounded px-3">
              <div className="me-2 ws-nowrap text-font3">
                {intl.formatMessage({ id: "estimateTotal" })} :
              </div>
              <div className="me-2 text-font3">
                {longCodeList.length > 0
                  ? parseFloat(
                      longCodeList
                        .map((longCode) => longCode.subTotal)
                        .reduce(
                          (accumulator, currentValue) =>
                            accumulator + currentValue,
                          0
                        ) * season
                    ).toFixed(2)
                  : "0.00"}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

import { useState } from "react";
import { DepositNavTitle } from "../../../../constants/DepositNavConst";
import { useIntl } from "react-intl";
import { DepositHistory } from "./history/DepositHistory";
import { DepositDialog } from "./depositDialog/DepositDialog";
import { FiArrowLeft } from "react-icons/fi";
import { Link, useLocation } from "react-router-dom";

export const Deposit = () => {
  const [selectedNav, setSelectedNav] = useState(DepositNavTitle.DEPOSIT);
  const intl = useIntl();
  const location = useLocation();

  return (
    <>
      <div
        style={{
          backgroundColor: "black",
          backgroundImage: `url(${process.env.PUBLIC_URL}/image/homebackground.png)`,
          backgroundSize: "cover",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={styles.navBar}>
          <div style={styles.backButton}>
            {location.state && location.state.backTo && (
              <Link to={location.state.backTo}>
                <FiArrowLeft style={{ color: "white" }} />
              </Link>
            )}
          </div>
          <div style={styles.title} className="text-font4">
            {intl.formatMessage({ id: "deposit" })}
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#1c1e23",
            width: "95%",
            margin: "0 auto",
            paddingTop: "10px",
            paddingBottom: "7vh",
            borderRadius: "5px",
          }}
        >
          <div className="d-flex">
            {Object.entries(DepositNavTitle).map(([key, value]) => (
              <h6
                style={{
                  color: selectedNav === value ? "#f5be09" : "white",
                  borderBottom:
                    selectedNav === value ? "2px solid #f5be09" : "",
                  padding: "10px",
                }}
                className="text-font3"
                onClick={() => setSelectedNav(value)}
                key={key}
              >
                {intl.formatMessage({ id: value })}
              </h6>
            ))}
          </div>
          <div style={{ marginBottom: "7vh" }}>
            {selectedNav === DepositNavTitle.DEPOSIT ? (
              <DepositDialog />
            ) : (
              <DepositHistory />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
const styles = {
  navBar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "60px",
    backgroundColor: "black",
  },
  backButton: {
    position: "absolute",
    left: "10px",
    fontSize: "20px",
  },
  title: {
    fontWeight: "bold",
    color: "white",
  },
};

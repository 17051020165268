import { useIntl } from "react-intl";
import { Modal } from "../../../../../common/components/Modal";
import { useRef } from "react";

export const BankAccountDialog = ({
  selectedBank,
  setSelectedBank,
  bankList,
  fullname,
}) => {
  const intl = useIntl();
  const dismissBtnRef = useRef();

  return (
    <Modal
      modalId="bankAccountModal"
      title={intl.formatMessage({ id: "withdrawalAccountList" })}
      dismissBtnRef={dismissBtnRef}
    >
      <div className="p-3">
        {bankList.map((bank, index) => (
          <div
            className={`rounded custom-border p-3 d-flex justify-content-between align-items-top cursor-pointer ${
              bankList.length === index + 1 ? "" : "mb-3"
            } ${selectedBank === index ? "bank-active" : ""}`}
            style={{ background: "rgba(0,0,0,0.2)" }}
            key={index}
            onClick={() => {
              setSelectedBank(index);
              dismissBtnRef.current.click();
            }}
          >
            <div>
              <div className="text-muted small text-font2">
                {intl.formatMessage({ id: "bankName" })}
              </div>
              <div className="mb-2 fw-bold text-font2">{bank.bankname}</div>
              <div className="text-muted small text-font2">
                {intl.formatMessage({ id: "fullName" })}
              </div>
              <div className="mb-2 fw-bold text-font2">{fullname}</div>
              <div className="text-muted small text-font2">
                {intl.formatMessage({ id: "accountNumber" })}
              </div>
              <div className="fw-bold text-font2">{bank.accountnum}</div>
            </div>
            {selectedBank === index && (
              <div className="text-prim">
                {intl.formatMessage({ id: "selected" })}
              </div>
            )}
          </div>
        ))}
      </div>
    </Modal>
  );
};
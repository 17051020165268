import React, { useState, useRef, useEffect } from "react";
import { useIntl } from "react-intl";
import { LoginTab } from "../../enum/LoginTab";
import { FiArrowLeft, FiRefreshCw } from "react-icons/fi";
import {
  fetchIPAddress,
  fetchCurrencyList,
  fetchVerificationCode,
  getOTP,
  registerStep2,
  registerStep3,
  getCurrentCountryCode,
} from "../../api/loginapi";
import { Form, Formik, Field } from "formik";
import {
  RegisterStep1Schema,
  RegisterStep3Schema,
} from "../../yupSchema/registerSchema";
import { CheckIsNull } from "../../../../utils/CommonHelper";
import {
  ErrorContainer,
  Input,
  PhoneInputField,
} from "../../../common/components/control/InputField";
import Skeleton from "react-loading-skeleton";
import OTPInput from "react-otp-input";
import { useAuth } from "../AuthInit";
import { useParams } from "react-router-dom";

export const RegisterTab = ({ setLoginTab, setSwalProps, loginTab }) => {
  const [otp, setOTP] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const intl = useIntl();
  const longitude = useRef("");
  const latitude = useRef("");
  const [isShowOtpError, setIsShowOTPError] = useState(false);
  const [isShowPhoneNoError, setIsShowPhoneNoError] = useState(false);
  const [isShowCurrencyError, setIsShowCurrencyError] = useState(false);
  const [verifyImage, setVerifyImageUrl] = useState("");
  const [otpErrorDescription, setOtpErrorDescription] = useState("");
  const [phoneNoErrorDescription, setPhoneNoErrorDescription] = useState("");
  const [registerDetail, setRegisterDetail] = useState();
  const [currencyLists, setCurrencyLists] = useState([]);
  const { saveAuth, setCurrentUser } = useAuth();
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [isFetchingCurrency, setIsFetchingCurrency] = useState(true);
  const [phoneCode, setPhoneCode] = useState("my");
  const ipAddress = useRef("");
  const codeNum = useRef("");
  const formikRef = useRef();
  const dateNow = Date.now();
  const { refferalCode } = useParams();
  const currentLocale = localStorage.getItem("locale");

  const getVerificationCode = () => {
    setVerifyImageUrl("");
    codeNum.current = "";
    fetchVerificationCode().then((resp) => {
      setVerifyImageUrl(resp.imageurl);
      codeNum.current = resp.codenum;
    });
  };
  const handleLanguageChange = (language) => {
    if (sessionStorage.getItem("platform") === "mobile") {
      console.log("update:language");
      localStorage.setItem("locale", language);
      // window.location.reload();
    } else {
      localStorage.setItem("locale", language);
      window.location.reload();
    }
  };
  useEffect(() => {
    // navigator.geolocation.getCurrentPosition(function (position) {
    //   longitude.current = position.coords.longitude;
    //   latitude.current = position.coords.latitude;
    // });
    getVerificationCode();
    fetchCurrencyList({ language: intl.locale }).then((resp) => {
      setCurrencyLists(resp.currencyLists);
      fetchIPAddress().then((res) => {
        ipAddress.current = res.ip;
        getCurrentCountryCode(res.ip).then((re) => {
          const currencyCode = resp.currencyLists.find(
            (currency) => currency.currencycode === re.currency
          );
          setPhoneCode(re.country.toLowerCase());
          if (currencyCode) {
            setSelectedCurrency(currencyCode);
          } else {
            setSelectedCurrency(resp.currencyLists[0]);
          }
          setIsFetchingCurrency(false);
          setIsLoading(false);
        });
      });
    });
  }, []);

  const renderRegisterComponent = () => {
    return (
      <div style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: "10px",
            marginTop: "10px",
          }}
        >
          <FiArrowLeft style={{ marginRight: "5px" }} />
        </div>
        <div style={{ textAlign: "center" }}>
          <h3 style={{ color: "white" }}>
            {intl.formatMessage({ id: "register" })}
          </h3>
        </div>
        <div style={{ margin: "10px", color: "white", textAlign: "center" }}>
          <span
            style={{
              color:
                loginTab === LoginTab.REGISTER_PART_1
                  ? "rgb(245, 190, 9)"
                  : "white",
            }}
          >
            1
          </span>
          <div
            style={{
              display: "inline-block",
              width: "15%",
              verticalAlign: "middle",
              borderTop: "1px solid white",
              marginLeft: "5px",
              marginRight: "5px",
            }}
          ></div>
          <span
            style={{
              color:
                loginTab === LoginTab.REGISTER_PART_2
                  ? "rgb(245, 190, 9)"
                  : "white",
            }}
          >
            2
          </span>
          <div
            style={{
              display: "inline-block",
              width: "15%",
              verticalAlign: "middle",
              borderTop: "1px solid white",
              marginLeft: "5px",
              marginRight: "5px",
            }}
          ></div>
          <span
            style={{
              color:
                loginTab === LoginTab.REGISTER_PART_3
                  ? "rgb(245, 190, 9)"
                  : "white",
            }}
          >
            3
          </span>
        </div>
        <div style={{ width: "100%" }}>
          {loginTab === LoginTab.REGISTER_PART_1 ? (
            <Formik
              initialValues={{
                phoneNo: registerDetail ? registerDetail.telno : "",
                referralCode: refferalCode
                  ? refferalCode
                  : registerDetail
                  ? registerDetail.referrerid
                  : "",
                verifyCode: "",
              }}
              innerRef={formikRef}
              //  validationSchema={RegisterStep1Schema(intl)}
              enableReinitialize={true}
              onSubmit={(values, formikHelpers) => {
                if (codeNum.current === values.verifyCode) {
                  setIsLoading(true);
                  const queryParams = {
                    telno: values.phoneNo,
                    referrerid: values.referralCode,
                    uniqueid: dateNow,
                    language: intl.locale,
                  };
                  getOTP(queryParams).then((resp) => {
                    setIsLoading(false);
                    getVerificationCode();
                    formikHelpers.setFieldValue("verifyCode", "");
                    if (parseInt(resp.code) === 0) {
                      setRegisterDetail(queryParams);
                      setLoginTab(LoginTab.REGISTER_PART_2);
                    } else {
                      setIsShowPhoneNoError(true);
                      setPhoneNoErrorDescription(resp.message);
                    }
                  });
                } else {
                  formikHelpers.setFieldError(
                    "verifyCode",
                    intl.formatMessage({ id: "incorrectVerificationCode" })
                  );
                }
              }}
            >
              {({
                values,
                handleSubmit,
                setFieldValue,
                touched,
                errors,
                setFieldError,
                setFieldTouched,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      paddingTop: "2%",
                    }}
                  >
                    <div
                      style={{
                        paddingLeft: "5%",
                        paddingRight: "5%",
                        width: "90%",
                        boxSizing: "border-box",
                      }}
                    >
                      <label
                        className="mb-2 text-input-label"
                        style={{ color: "white" }}
                      >
                        {intl.formatMessage({ id: "phoneNo" })}
                      </label>
                      {!isFetchingCurrency ? (
                        <PhoneInputField
                          values={values}
                          setFieldValue={setFieldValue}
                          setIsShowPhoneNoError={setIsShowPhoneNoError}
                          isShowPhoneNoError={isShowPhoneNoError}
                          phoneNoErrorDescription={phoneNoErrorDescription}
                          setPhoneNoErrorDescription={
                            setPhoneNoErrorDescription
                          }
                          name="phoneNo"
                          country={phoneCode}
                        />
                      ) : (
                        <Skeleton className="w-100" height="35px" />
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      paddingTop: "2%",
                    }}
                  >
                    <div
                      style={{
                        paddingLeft: "5%",
                        paddingRight: "5%",
                        width: "90%",
                        boxSizing: "border-box",
                      }}
                    >
                      <label
                        className="mb-2 text-input-label"
                        style={{ color: "white" }}
                      >
                        {intl.formatMessage({ id: "refferalCode" })}
                      </label>
                      <Field
                        name="referralCode"
                        component={Input}
                        placeholder={intl.formatMessage({ id: "refferalCode" })}
                        autoComplete="off"
                        readOnly={refferalCode ? true : false}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      paddingTop: "2%",
                    }}
                  >
                    <div
                      style={{
                        paddingLeft: "5%",
                        paddingRight: "5%",
                        width: "90%",
                        boxSizing: "border-box",
                      }}
                    >
                      <label
                        className="mb-2 text-input-label"
                        style={{ color: "white" }}
                      >
                        {intl.formatMessage({ id: "verificationCode" })}
                      </label>
                      <div className="mb-2">
                        {CheckIsNull(verifyImage) ? (
                          <Skeleton
                            className="col-4"
                            style={{ height: "60px" }}
                          />
                        ) : (
                          <>
                            <img
                              src={verifyImage}
                              alt="verifyImage"
                              className="col-4 me-4"
                              style={{ height: "60px" }}
                            />
                            <FiRefreshCw
                              style={{ color: "white", marginLeft: "5px" }}
                              onClick={() => getVerificationCode()}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mb-2"></div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      paddingTop: "2%",
                    }}
                  >
                    <div
                      style={{
                        paddingLeft: "5%",
                        paddingRight: "5%",
                        width: "90%",
                        boxSizing: "border-box",
                      }}
                    >
                      <Field
                        name="verifyCode"
                        component={Input}
                        placeholder={intl.formatMessage({
                          id: "verificationCode",
                        })}
                        autoComplete="off"
                        readOnly={isLoading}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      paddingTop: "2%",
                    }}
                  >
                    <button
                      type="submit"
                      disabled={isLoading}
                      style={{
                        width: "60%",
                        height: 35,
                        borderRadius: 15,
                        backgroundColor: "rgb(245, 190, 9)",
                        fontWeight: "bold",
                        color: "black",
                        fontSize: "12px",
                      }}
                    >
                      {isLoading && (
                        <div
                          className="spinner-border"
                          role="status"
                          style={{ scale: "0.75" }}
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      )}
                      {intl.formatMessage({ id: "requestOTP" })}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          ) : loginTab === LoginTab.REGISTER_PART_2 ? (
            <div style={{ width: "100%" }}>
              <div
                style={{
                  color: "white",
                  textAlign: "center",
                  width: "70%",
                  margin: "0 auto",
                }}
              >
                {intl.formatMessage({ id: "otpHasSended" })}
                {"(+"}
                {registerDetail.telno}
                {")"}
              </div>
              <div style={{ paddingTop: "5px" }}>
                <OTPInput
                  value={otp}
                  onChange={setOTP}
                  numInputs={5}
                  containerStyle="justify-content-center"
                  shouldAutoFocus={true}
                  renderSeparator={<div className="mx-2 mb-3"></div>}
                  renderInput={(props) => (
                    <input
                      {...props}
                      className="form-control otp-field"
                      inputMode="numeric"
                    />
                  )}
                />
                <div className="d-flex justify-content-center mt-2">
                  <ErrorContainer
                    isShow={isShowOtpError}
                    errorMsg={otpErrorDescription}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    paddingTop: "2%",
                  }}
                >
                  <button
                    type="submit"
                    onClick={() => {
                      if (!CheckIsNull(otp)) {
                        setIsShowOTPError(false);
                        setIsLoading(true);
                        registerStep2({ ...registerDetail, otpcode: otp }).then(
                          (resp) => {
                            setIsLoading(false);
                            setOTP("");
                            if (parseInt(resp.code) === 0) {
                              setLoginTab(LoginTab.REGISTER_PART_3);
                            } else {
                              setIsShowOTPError(true);
                              setOtpErrorDescription(resp.message);
                            }
                          }
                        );
                      } else {
                        setIsShowOTPError(true);
                        setOtpErrorDescription(
                          intl.formatMessage({ id: "otpRequired" })
                        );
                      }
                    }}
                    disabled={isLoading}
                    style={{
                      width: "60%",
                      height: 35,
                      borderRadius: 15,
                      backgroundColor: "rgb(245, 190, 9)",
                      fontWeight: "bold",
                      color: "black",
                      fontSize: "12px",
                    }}
                  >
                    <div className="d-flex align-items-center justify-content-center">
                      {isLoading && (
                        <div
                          className="spinner-border text-dark"
                          role="status"
                          style={{ scale: "0.75" }}
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      )}
                      {intl.formatMessage({ id: "submit" })}
                    </div>
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div style={{ width: "100%" }}>
              <Formik
                initialValues={{
                  username: "",
                  userPassword: "",
                }}
                innerRef={formikRef}
                validationSchema={RegisterStep3Schema(intl)}
                enableReinitialize={true}
                onSubmit={(values, formikHelpers) => {
                  if (selectedCurrency) {
                    let queryParams = {
                      ...registerDetail,
                      fcmnotificationid: "",
                      username: values.username,
                      password: values.userPassword,
                      currencycode: selectedCurrency.currencycode,
                      latitude: latitude.current,
                      longitude: longitude.current,
                    };
                    setIsShowCurrencyError(false);
                    setIsLoading(true);
                    fetchIPAddress().then((res) => {
                      setIsLoading(false);
                      queryParams = { ...queryParams, ipaddress: res.ip };
                      registerStep3(queryParams).then((resp) => {
                        if (parseInt(resp.code) === 0) {
                          setSwalProps({
                            show: true,
                            icon: "success",
                            title: intl.formatMessage({ id: "success" }),
                            text: intl.formatMessage({ id: "registerSuccess" }),
                            onConfirm: () => {
                              saveAuth(resp);
                              setCurrentUser(resp);
                              setLoginTab(LoginTab.LOGIN);
                            },
                          });
                        } else {
                          formikHelpers.setFieldError("username", resp.message);
                        }
                      });
                    });
                  } else {
                    setIsShowCurrencyError(true);
                  }
                }}
              >
                {({
                  values,
                  handleSubmit,
                  setFieldValue,
                  touched,
                  errors,
                  setFieldError,
                }) => (
                  <Form>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        paddingTop: "2%",
                      }}
                    >
                      <div
                        style={{
                          paddingLeft: "5%",
                          paddingRight: "5%",
                          width: "90%",
                          boxSizing: "border-box",
                        }}
                      >
                        <label
                          className="mb-2 text-input-label"
                          style={{ color: "white" }}
                        >
                          {intl.formatMessage({ id: "username" })}
                        </label>
                        <div className="mb-2">
                          <Field
                            name="username"
                            component={Input}
                            placeholder={intl.formatMessage({ id: "username" })}
                            autoComplete="off"
                            pattern="[a-zA-Z0-9]*"
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          paddingLeft: "5%",
                          paddingRight: "5%",
                          width: "90%",
                          boxSizing: "border-box",
                        }}
                      >
                        <label
                          className="mb-2 text-input-label"
                          style={{ color: "white" }}
                        >
                          {intl.formatMessage({ id: "password" })}
                        </label>
                        <div className="mb-2">
                          <Field
                            name="userPassword"
                            component={Input}
                            placeholder={intl.formatMessage({ id: "password" })}
                            autoComplete="off"
                            type="password"
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          paddingLeft: "5%",
                          paddingRight: "5%",
                          width: "90%",
                          boxSizing: "border-box",
                        }}
                      >
                        <div className="mb-4">
                          <label
                            className="mb-2 text-input-label"
                            style={{ color: "white" }}
                          >
                            {intl.formatMessage({ id: "currency" })}
                          </label>
                          {!isFetchingCurrency ? (
                            <>
                              <div className="dropdown">
                                <button
                                  className={`form-control text-start d-flex align-items-center ${
                                    selectedCurrency ? "" : "text-placeholder"
                                  }`}
                                  style={{
                                    height: 35,
                                    backgroundColor: "#1c1e23",
                                    border: "1px solid grey",
                                    borderRadius: "10",
                                    fontSize: "12px",
                                    color: "white",
                                  }}
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  data-text={intl.formatMessage({
                                    id: "currency",
                                  })}
                                >
                                  {selectedCurrency ? (
                                    <>
                                      <img
                                        src={selectedCurrency.imageurl}
                                        alt={selectedCurrency.currencycode}
                                        className="me-4 currency-icon"
                                      />
                                      <div>{selectedCurrency.currencyname}</div>
                                    </>
                                  ) : (
                                    intl.formatMessage({ id: "currency" })
                                  )}
                                </button>
                                <div className="dropdown-menu w-100">
                                  {currencyLists.map((currency) => (
                                    <div
                                      className={`dropdown-item d-flex align-items-center py-2 cursor-pointer ${
                                        selectedCurrency &&
                                        selectedCurrency.currencycode ===
                                          currency.currencycode
                                          ? "active"
                                          : ""
                                      }`}
                                      key={currency.currencycode}
                                      onClick={() => {
                                        setSelectedCurrency(currency);
                                        document
                                          .querySelector(".dropdown-menu")
                                          .classList.remove("show");
                                      }}
                                    >
                                      <img
                                        src={currency.imageurl}
                                        alt={currency.currencycode}
                                        className="me-4 currency-icon"
                                      />
                                      <div>{currency.currencyname}</div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                              <ErrorContainer
                                isShow={isShowCurrencyError}
                                errorMsg={intl.formatMessage({
                                  id: "currencyRequired",
                                })}
                              />
                            </>
                          ) : (
                            <Skeleton className="w-100" height="35px" />
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            paddingTop: "2%",
                          }}
                        >
                          <button
                            type="submit"
                            disabled={isLoading}
                            style={{
                              width: "60%",
                              height: 35,
                              borderRadius: 15,
                              backgroundColor: "rgb(245, 190, 9)",
                              fontWeight: "bold",
                              color: "black",
                              fontSize: "12px",
                            }}
                          >
                            {isLoading && (
                              <div
                                className="spinner-border"
                                role="status"
                                style={{ scale: "0.75" }}
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            )}
                            {intl.formatMessage({ id: "register" })}
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          )}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "0 auto",
            width: "90%",
            marginTop: 20,
            marginBottom: 20,
            padding: 5,
          }}
        >
          {currentLocale !== "ms" && (
            <img
              src={`${process.env.PUBLIC_URL}/image/my.png`}
              style={{ height: "30px", width: "30px", marginLeft: "10px" }}
              onClick={() => handleLanguageChange("ms")}
            />
          )}
          {currentLocale !== "en" && (
            <img
              src={`${process.env.PUBLIC_URL}/image/en.png`}
              style={{ height: "30px", width: "30px", marginLeft: "10px" }}
              onClick={() => handleLanguageChange("en")}
            />
          )}
          {currentLocale !== "zh" && (
            <img
              src={`${process.env.PUBLIC_URL}/image/cn.png`}
              style={{ height: "30px", width: "30px", marginLeft: "10px" }}
              onClick={() => handleLanguageChange("zh")}
            />
          )}
          {currentLocale !== "id" && (
            <img
              src={`${process.env.PUBLIC_URL}/image/id.png`}
              style={{ height: "30px", width: "30px", marginLeft: "10px" }}
              onClick={() => handleLanguageChange("id")}
            />
          )}
        </div>
        <p
          style={{
            color: "white",
            textAlign: "center",
            fontSize: 13,
            paddingTop: "2%",
          }}
        >
          {intl.formatMessage({ id: "alreadyHaveAccount" })}{" "}
          <button
            onClick={() => {
              setLoginTab(LoginTab.LOGIN);
            }}
            style={{
              cursor: "pointer",
              border: "none",
              background: "none",
              color: "white",
              textDecoration: "underline",
            }}
          >
            {intl.formatMessage({ id: "login" })}
          </button>
        </p>
      </div>
    );
  };

  return (
    <div
      style={{
        backgroundColor: "transparent",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <div style={{ width: "100%" }}>{renderRegisterComponent()}</div>
    </div>
  );
};

import { useState } from "react";
import { useIntl } from "react-intl";
import {
  HistoryStatusColorConst,
  HistoryStatusConst,
  historyDurationList,
} from "../../../../../constants/HistoryConst";
import * as actions from "../../../redux/SettingAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import SweetAlert2 from "react-sweetalert2";
import { ApiStatusTypeEnum } from "../../../../../enumeration/ApiStatusTypeEnum";

export const WithdrawHistory = () => {
  const intl = useIntl();
  const [swalProps, setSwalProps] = useState({});
  const dispatch = useDispatch();
  const [dayType, setDayType] = useState(1);
  const [status, setStatus] = useState(HistoryStatusConst.REQUEST);

  const settingState = useSelector(({ setting }) => setting, shallowEqual);

  useEffect(() => {
    dispatch(
      actions.getWithdrawList({ action: "withdrawlist", days: dayType })
    );
  }, [dayType]);

  const cancelWithdrawRequest = (itemid) => {
    setSwalProps({
      show: true,
      icon: "info",
      title: intl.formatMessage({ id: "alert" }),
      text: intl.formatMessage({
        id: "confirmationCancelWithdraw",
      }),
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: intl.formatMessage({ id: "confirm" }),
      denyButtonText: intl.formatMessage({ id: "cancel" }),
      onResolve: () => {
        setSwalProps({});

        dispatch(
          actions.cancelWithdrawRequest({
            action: "withdrawcancel",
            selitemid: itemid,
          })
        ).then((resp) => {
          resp = resp.data;

          if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
            dispatch(
              actions.getWithdrawList({ action: "withdrawlist", days: dayType })
            );

            setSwalProps({
              show: true,
              icon: "success",
              title: intl.formatMessage({ id: "success" }),
              text: intl.formatMessage({
                id: "withdrawRequestCancelSuccessfully",
              }),
            });
          } else {
            setSwalProps({
              show: true,
              icon: "error",
              title: intl.formatMessage({ id: "error" }),
              text: resp.message,
            });
          }
        });
      },
    });
  };

  return (
    <div style={{ width: "95%", margin: "5px auto" }}>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      <div className="col-3">
        <select
          className="form-control text-font3"
          value={dayType}
          onChange={(e) => setDayType(e.target.value)}
        >
          {historyDurationList.map((day, index) => (
            <option key={index} value={day}>
              {day} {intl.formatMessage({ id: "days" })}
            </option>
          ))}
        </select>
      </div>
      <div
        className="d-flex"
        style={{
          overflowX: "auto",
          margin: "10px auto",
        }}
      >
        {Object.entries(HistoryStatusConst).map(([key, value]) => (
          <div
            className={`me-3 cursor-pointer px-3 fw-bold py-2 ws-nowrap text-font3`}
            style={{
              color: status === value ? "#f5be09" : "white",
              border:
                status === value ? "1px solid #f5be09" : "1px solid white",
              borderRadius: "5px",
            }}
            onClick={() => setStatus(value)}
            key={key}
          >
            {intl.formatMessage({
              id: value === HistoryStatusConst.CANCEL ? "canceled" : value,
            })}
          </div>
        ))}
      </div>
      {settingState.withdrawList ? (
        <>
          {settingState.listLoading ? (
            <div className="d-flex align-items-center justify-content-center py-5">
              <div
                className="spinner-border"
                role="status"
                style={{ scale: "1.2" }}
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <div style={{ minHeight: "600px" }}>
              {settingState.withdrawList.withdrawLists.filter(
                (withdraw) =>
                  withdraw.status.toLowerCase() === status.toLowerCase()
              ).length > 0 ? (
                settingState.withdrawList.withdrawLists
                  .filter(
                    (withdraw) =>
                      withdraw.status.toLowerCase() === status.toLowerCase()
                  )
                  .map((withdraw, index) => (
                    <div
                      className="card mb-2"
                      key={index}
                      style={{ background: "#1c1e23", color: "white" }}
                    >
                      <div
                        className="card-body rounded"
                        style={{
                          background: "rgba(0,0,0,0.2)",
                        }}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="fw-bold text-font3">
                            {withdraw.withdrawamount}
                          </div>
                          <div
                            className={`py-1 px-2 rounded small fw-bold text-dark`}
                            style={{
                              fontSize: "11px",
                              background:
                                HistoryStatusColorConst[withdraw.status],
                            }}
                          >
                            {withdraw.status}
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div>
                            <div className="text-muted fst-italic small text-font2">
                              {intl.formatMessage({ id: "requestedDate" })} :{" "}
                              {withdraw.requestdate}
                            </div>
                            {(withdraw.status.toLowerCase() ===
                              HistoryStatusConst.APPROVE.toLowerCase() ||
                              withdraw.status.toLowerCase() ===
                                HistoryStatusConst.REJECT.toLowerCase()) && (
                              <div className="text-muted fst-italic small text-font2">
                                {intl.formatMessage({ id: "rejectReason" })}
                                {withdraw.message}
                              </div>
                            )}
                          </div>
                          {withdraw.status.toLowerCase() ===
                          HistoryStatusConst.REQUEST.toLowerCase() ? (
                            <button
                              className="btn btn-danger cursor-pointer text-dark px-4 py-1 fw-bold mt-2"
                              style={{ fontSize: "13px" }}
                              onClick={() =>
                                cancelWithdrawRequest(withdraw.itemid)
                              }
                            >
                              {intl.formatMessage({ id: "cancel" })}
                            </button>
                          ) : withdraw.status.toLowerCase() ===
                            HistoryStatusConst.APPROVE.toLowerCase() ? (
                            <div
                              className="position-relative cursor-pointer custom-border rounded px-2 me-1 text-font2"
                              onClick={() => {
                                setSwalProps({
                                  show: true,
                                  showCloseButton: true,
                                  showConfirmButton: false,
                                  html: `<div><h4 class='d-flex align-items-center justify-content-start mb-4'>${intl.formatMessage(
                                    {
                                      id: "prove",
                                    }
                                  )}</h4><img src='${
                                    withdraw.receiptimage
                                  }' class="w-100"/></div>`,
                                });
                              }}
                            >
                              <i
                                className="bi bi-image fs-1 p-0"
                                title={intl.formatMessage({ id: "prove" })}
                              />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  ))
              ) : (
                <div className="d-flex py-5 align-items-center justify-content-center">
                  <div className="text-muted d-flex align-items-center justify-content-center flex-column">
                    <i
                      style={{ color: "white" }}
                      className="bi bi-search fs-1 mb-3"
                    />
                    <div style={{ color: "white" }}>
                      {intl.formatMessage({ id: "noRecordFound" })}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      ) : (
        <Skeleton className="w-100" height="600px" />
      )}
    </div>
  );
};

import { authFormPost, authGet, authPost } from "../redux/ApiClient";

const baseUrl = "http://35.240.204.237/es3MemberApi";

export function fetchWalletInfo(params) {
  return authPost(`${baseUrl}/Member2/SingleWalletInfo`, params);
}

export function getMember1(queryParams) {
  return authGet(`${baseUrl}/Member1.ashx`, queryParams);
}

export function postMember1(queryParams) {
  return authPost(`${baseUrl}/Member1.ashx`, queryParams);
}

export function getMember2(queryParams) {
  return authGet(`${baseUrl}/Member2.ashx`, queryParams);
}

export function postMember2(queryParams) {
  return authPost(`${baseUrl}/Member2.ashx`, queryParams);
}

export function getMember3(queryParams) {
  return authGet(`${baseUrl}/Member3.ashx`, queryParams);
}

export function postMember3(queryParams) {
  return authPost(`${baseUrl}/Member3.ashx`, queryParams);
}

export function getMember4(queryParams) {
  return authGet(`${baseUrl}/Member4.ashx`, queryParams);
}

export function postMember4(queryParams) {
  return authPost(`${baseUrl}/Member4.ashx`, queryParams);
}

export function getMobile1(queryParams) {
  return authGet(`${baseUrl}/Mobile1.ashx`, queryParams);
}

export function postMobile1(queryParams) {
  return authPost(`${baseUrl}/Mobile1.ashx`, queryParams);
}

export const atmDepositRequest = (queryParams) => {
  return authFormPost(
    `${window.location.protocol}//api.es51.com/deposit.php`,
    queryParams
  );
};

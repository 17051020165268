import { ReduxCallTypesConst } from "../../../constants/ReduxCallTypesConst";
import * as requestToApi from "./PromotionCRUD";
import { promotionSlice } from "./PromotionSlice";

const { actions } = promotionSlice;

export const getPromotionPageList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .mbPromotionPageList(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.promotionPageListFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: fetchHomePageUI");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};

//   dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

//   return requestToApi
//     .mbHomePageCompanyList(queryParams)
//     .then((response) => {
//       if (response) {
//         if (!response.data.isAbort) {
//           dispatch(actions.homePageCompanyListFetched({ data: response.data }));
//           return response;
//         }
//       } else {
//         throw new Error("Error api: fetchHomeCompanyList");
//       }
//     })
//     .catch((error) => {
//       dispatch(
//         actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
//       );
//       throw error;
//     });
// };
export const CheckIsNull = (value) =>
  value === "" || value === undefined || value === null;

export const toAbsoluteUrl = (url) => {
  const newUrl = url[0] === "/" ? url : "/" + url;

  return window.location.origin + newUrl;
};

export const ResolveArrayNull = (array) => {
  return array ? array : [];
};

export const IsAlphaNumeric = (e) => {
  const alphanumericRegex = /^[a-zA-Z0-9]+$/;
  const key = e.key;

  return alphanumericRegex.test(key);
};

export const IsKeyPressNumber = (e) => {
  return (
    e.code.includes("Digit") ||
    e.ctrlKey ||
    e.code === "Backspace" ||
    e.code.includes("Arrow")
  );
};

export const CopyToClipboard = (text, setSwalProps, intl) => {
  if (setSwalProps && intl) {
    setSwalProps({
      show: true,
      position: "bottom",
      text: intl.formatMessage({ id: "copySuccessfully" }),
      timer: 1000,
      showConfirmButton: false,
      toast: true,
      width: "13.2rem",
    });
  }
  navigator.clipboard.writeText(text);
};

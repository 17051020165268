import { ReduxCallTypesConst } from "../../../constants/ReduxCallTypesConst";
import { postMember1, postMobile1 } from "../../common/redux/MasterLayoutCRUD";
import { homeSlice } from "./HomeSlice";

const { actions } = homeSlice;

export const getHomePageUI = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return postMobile1(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.homePageUIFetched({ data: response.data }));

          return response;
        }
      } else {
        throw new Error("Error api: fetchHomePageUI");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};

export const getHomePageMemberData = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return postMember1(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.homePageMemberDataFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: fetchHomePageMemberData");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};

import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../../../../../utils/CommonHelper";
import { MobileDepositDialog } from "./MobileDepositDialog";

export const MobileDeposit = () => {
  const paymentLink = sessionStorage.getItem("paymentLink");
  const intl = useIntl();
  const settingState = useSelector(({ setting }) => setting, shallowEqual);
  const screenHeight = window.innerHeight;
  const screenWidth = window.innerWidth;
  const [dateNow, setDateNow] = useState(Date.now());
  const [visibleButton, setVisibleButton] = useState("topRight");

  const toggleButton = () => {
    switch (visibleButton) {
      case "topRight":
        setVisibleButton("topLeft");
        break;
      case "topLeft":
        setVisibleButton("bottomLeft");
        break;
      case "bottomLeft":
        setVisibleButton("bottomRight");
        break;
      case "bottomRight":
        setVisibleButton("topRight");
        break;
      default:
        setVisibleButton("topRight");
    }
  };
  return (
    <div className="d-flex flex-column">
      <MobileDepositDialog toggleButton={toggleButton} />
      {!settingState || settingState.actionsLoading ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ backgroundColor: "black", height: screenHeight }}
        >
          <div
            className="spinner-border"
            role="status"
            style={{ scale: "1.2" }}
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div>
          <div>{(window.location.href = paymentLink)}</div>

          {visibleButton === "topRight" && (
            <div style={{ position: "absolute", top: 5, right: 5 }}>
              <div
                style={{ background: "transparent" }}
                data-bs-toggle="modal"
                data-bs-target="#mobileDepositModal"
              >
                <img
                  src={toAbsoluteUrl(`/image/es51.gif`)}
                  style={{ width: "40px", height: "40px" }}
                />
              </div>
            </div>
          )}

          {visibleButton === "topLeft" && (
            <div style={{ position: "absolute", top: 5, left: 5 }}>
              <div
                style={{ background: "transparent" }}
                data-bs-toggle="modal"
                data-bs-target="#mobileDepositModal"
              >
                <img
                  src={toAbsoluteUrl(`/image/es51.gif`)}
                  style={{ width: "40px", height: "40px" }}
                />
              </div>
            </div>
          )}

          {visibleButton === "bottomLeft" && (
            <div style={{ position: "absolute", bottom: 5, left: 5 }}>
              <div
                style={{ background: "transparent" }}
                data-bs-toggle="modal"
                data-bs-target="#mobileDepositModal"
              >
                <img
                  src={toAbsoluteUrl(`/image/es51.gif`)}
                  style={{ width: "40px", height: "40px" }}
                />
              </div>
            </div>
          )}

          {visibleButton === "bottomRight" && (
            <div style={{ position: "absolute", bottom: 5, right: 5 }}>
              <div
                style={{ background: "transparent" }}
                data-bs-toggle="modal"
                data-bs-target="#mobileDepositModal"
              >
                <img
                  src={toAbsoluteUrl(`/image/es51.gif`)}
                  style={{ width: "40px", height: "40px" }}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

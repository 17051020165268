import { useEffect } from "react";
import * as actions from "../redux/AppGamesAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {
  CheckIsNull,
  CopyToClipboard,
  ResolveArrayNull,
  toAbsoluteUrl,
} from "../../../utils/CommonHelper";
import Skeleton from "react-loading-skeleton";
import { useIntl } from "react-intl";
import { Overlay } from "../../common/components/Overlay";
import SweetAlert2 from "react-sweetalert2";
import { EditPasswordDialog } from "./editPassword/EditPasswordDialog";
import { FiArrowLeft } from "react-icons/fi";
import { IoIosArrowDown } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Drawer } from "@mui/material";

export const AppGamesListing = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { companyCode } = useParams();
  const [swalProps, setSwalProps] = useState({});
  const navigate = useNavigate();
  const [openCompanyList, setOpenCompanyList] = useState(false);
  const [companyName, setCompanyName] = useState();

  const appGamesState = useSelector(({ appGames }) => appGames, shallowEqual);

  const _showCompanyList = () => {
    setOpenCompanyList(true);
  };

  const handleCloseCompanyList = () => {
    setOpenCompanyList(false);
  };

  const changeCompanyList = (companyGameType, companyListCode) => {
    navigate(`/${companyGameType}/${companyListCode}`);
    setOpenCompanyList(false);
  };

  useEffect(() => {
    const params = {
      action: "mbappgamelist",
    };

    dispatch(actions.getAppGamesPageUI(params));
  }, []);

  const createGameId = () => {
    const queryParams = { action: "createappgame", companycode: companyCode };

    dispatch(actions.addAppGamesID(queryParams)).then((resp) => {
      resp = resp.data;

      if (parseInt(resp.code) === 0) {
        setSwalProps({
          show: true,
          icon: "success",
          title: intl.formatMessage({ id: "success" }),
          text: intl.formatMessage({ id: "gameIdCreateSuccessfully" }),
          onResolve: () => {
            setSwalProps({});
            const params = {
              action: "mbappgamelist",
            };
            dispatch(actions.getAppGamesPageUI(params));
          },
        });
      } else {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };

  useEffect(() => {
    if (appGamesState.appGamesPageUI) {
      const foundCompany = appGamesState.appGamesPageUI.appCompanyLists.find(
        (company) => company.companycode === companyCode
      );

      if (foundCompany) {
        setCompanyName(foundCompany.companyname);
      }
    }
  }, [appGamesState.appGamesPageUI, companyCode]);

  useEffect(() => {
    document.body.classList.add(
      "pages-bg",
      "h-100",
      "scroll-y-default",
      "bg-dark"
    );
    document.querySelector("html").classList.add("h-100", "scroll-y-default");
    document
      .querySelector("#root")
      .classList.add("h-100", "w-100", "scroll-y-default", "position-absolute");

    document.body.style.backgroundImage = `url(${toAbsoluteUrl(
      "/image/homebackground.png"
    )})`;

    return () => {
      document.body.style.backgroundImage = ``;
      document.body.classList.remove(
        "pages-bg",
        "h-100",
        "scroll-y-default",
        "bg-dark"
      );
      document
        .querySelector("html")
        .classList.remove("h-100", "scroll-y-default");
      document
        .querySelector("#root")
        .classList.remove(
          "h-100",
          "w-100",
          "scroll-y-default",
          "position-absolute"
        );
    };
  }, []);

  return (
    <div className="d-flex flex-column">
      <div style={styles.navBar}>
        <div
          style={styles.backButton}
          onClick={() =>
            navigate(`/home`, {
              state: { gameType: "app" },
            })
          }
        >
          <FiArrowLeft style={{ color: "white" }} />
        </div>
        <div style={styles.title} onClick={_showCompanyList}>
          {companyName}
          <IoIosArrowDown style={{ paddingLeft: "5px" }} />
        </div>
      </div>
      {appGamesState.actionsLoading && <Overlay />}
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      <EditPasswordDialog
        companyCode={companyCode}
        setSwalProps={setSwalProps}
      />
      {appGamesState.appGamesPageUI &&
      appGamesState.appGamesPageUI.appCompanyLists &&
      companyCode ? (
        appGamesState.appGamesPageUI.appCompanyLists
          .filter((company) => company.companycode === companyCode)
          .map((company, index) => (
            <div
              style={{
                backgroundColor: "#1c1e23",
                paddingBottom: "7vh",
              }}
              key={index}
              className="rounded pt-2 mx-3 px-2"
            >
              <div>
                <div className="text-center">
                  <img
                    alt={companyCode}
                    src={company.appimageurl}
                    style={{ height: "250px" }}
                  />
                </div>
                <div
                  style={{
                    border: "1px solid #f5be09",
                    width: "50%",
                    borderRadius: "5px",
                    margin: "10px auto",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <a
                    href={company.appdownloadurl}
                    className="btn"
                    style={{ color: "#f5be09" }}
                    target="_blank"
                    onClick={() =>
                      console.log(`openurl:${company.appdownloadurl}`)
                    }
                  >
                    {intl.formatMessage({ id: "apkDownload" })}
                  </a>
                </div>
              </div>
              <div
                style={{ width: "95%", margin: "10px auto", color: "white" }}
              >
                {CheckIsNull(company.gameusername) ? (
                  <div className="text-center">
                    <div className="mb-5">
                      {intl.formatMessage({
                        id: "createGameIdDescription",
                      })}
                    </div>
                    <button
                      type="button"
                      className="btn btn-prim-light px-4 fs-3"
                      style={{ border: "1px solid #f5be09", color: "#f5be09" }}
                      onClick={() => createGameId()}
                    >
                      {intl.formatMessage({ id: "createGameId" })}
                    </button>
                  </div>
                ) : (
                  <>
                    <div className="mb-4">
                      <label className="mb-2 text-input-label">
                        {intl.formatMessage({ id: "username" })}
                      </label>
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control"
                          readOnly={true}
                          value={company.gameusername}
                        />
                        <i
                          className="fa fa-copy position-absolute top-50 end-0 translate-middle cursor-pointer"
                          onClick={() =>
                            CopyToClipboard(
                              company.gameusername,
                              setSwalProps,
                              intl
                            )
                          }
                        />
                      </div>
                    </div>
                    <div className="mb-4">
                      <label className="mb-2 text-input-label">
                        {intl.formatMessage({ id: "password" })}
                      </label>
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control"
                          readOnly={true}
                          value={company.gamepassword}
                        />
                        <i
                          className="fa fa-copy position-absolute top-50 end-0 translate-middle cursor-pointer"
                          onClick={() =>
                            CopyToClipboard(
                              company.gamepassword,
                              setSwalProps,
                              intl
                            )
                          }
                        />
                      </div>
                    </div>
                    <button
                      type="button"
                      className="btn btn-prim-light w-100 mt-4"
                      data-bs-toggle="modal"
                      style={{ border: "1px solid #f5be09", color: "#f5be09" }}
                      data-bs-target="#passwordModal"
                    >
                      {intl.formatMessage({ id: "changePassword" })}
                    </button>
                  </>
                )}
              </div>
            </div>
          ))
      ) : (
        <Skeleton className="w-100" height="600px" />
      )}
      <Drawer
        anchor="bottom"
        open={openCompanyList}
        onClose={handleCloseCompanyList}
        style={{ minHeight: "40vh" }}
      >
        <div style={{ backgroundColor: "#262626", padding: "16px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button onClick={handleCloseCompanyList}>
              <FiArrowLeft style={{ color: "white" }} />
            </Button>
            <div style={{ flex: 7, color: "white" }}>
              {intl.formatMessage({ id: "companyList" })}
            </div>
          </div>

          <div
            style={{
              color: "white",
              cursor: "pointer",
              width: "100%",
              paddingTop: "20px",
            }}
          >
            {appGamesState.appGamesPageUI &&
              appGamesState.appGamesPageUI.appCompanyLists.map((company) => (
                <div
                  onClick={() =>
                    changeCompanyList(company.gametype, company.companycode)
                  }
                  key={company.id}
                  style={{
                    width: "100%",
                    height: "50px",
                    borderTop: "1px solid #262626",
                  }}
                >
                  {company.companyname}
                </div>
              ))}
          </div>
        </div>
      </Drawer>
    </div>
  );
};
const styles = {
  navBar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "60px",
    backgroundColor: "black",
  },
  backButton: {
    position: "absolute",
    left: "10px",
    fontSize: "20px",
  },
  title: {
    fontWeight: "bold",
    color: "white",
    margin: "0 auto",
  },
};

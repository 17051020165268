import { useIntl } from "react-intl";
import {
  LotteryNavConst,
  LotteryNavTitle,
} from "../../../constants/LotteryNavConst";
import { LotteryResultListing } from "./lotteryResult/LotteryResultListing";
import { LotteryBetDialog } from "./lotteryBet/LotteryBetDialog";
import { Link, useNavigate } from "react-router-dom";
import { LotteryPayout } from "./lotteryPayout/LotteryPayout";
import { LotteryHistory } from "./lotteryHistory/LotteryHistory";
import { LotteryList } from "./lotteryList/LotteryList";
import { useEffect } from "react";
import { FiArrowLeft } from "react-icons/fi";

export const LotteryListing = ({ selectedTab }) => {
  const intl = useIntl();
  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.background = "#000";
    document.body.style.color = "#fff";

    return () => {
      document.body.style.background = "";
      document.body.style.color = "";
    };
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center p-3 pb-2">
        <div
          className="col-2"
          onClick={() =>
            navigate(`/home`, {
              state: { gameType: "lottery" },
            })
          }
        >
          <FiArrowLeft style={{ color: "white", fontSize: "20px" }} />
        </div>
        <div className="fw-bold text-font4">
          {intl.formatMessage({ id: "lottery" })}
        </div>
        <div className="col-2" />
      </div>
      <div className="container mb-5">
        <div className="d-flex mb-4 overflow-scroll justify-content-between">
          {LotteryNavConst.map((nav, index) => (
            <div
              className={` lottery-nav ${
                selectedTab === nav.title ? "active fw-bold" : ""
              } text-center p-3 cursor-pointer ws-nowrap text-font3`}
              onClick={() => {
                navigate(nav.navigate);
              }}
              key={index}
            >
              {intl.formatMessage({ id: nav.title })}
            </div>
          ))}
        </div>
        <div>
          {selectedTab === LotteryNavTitle.RESULT ? (
            <LotteryResultListing />
          ) : selectedTab === LotteryNavTitle.BET ? (
            <LotteryBetDialog />
          ) : selectedTab === LotteryNavTitle.PAYOUT ? (
            <LotteryPayout />
          ) : selectedTab === LotteryNavTitle.REPORT ? (
            <LotteryHistory />
          ) : selectedTab === LotteryNavTitle.SLIP ? (
            <LotteryList />
          ) : null}
        </div>
      </div>
    </>
  );
};

import { Field, Form, Formik } from "formik";
import { useIntl } from "react-intl";
import { Input } from "../../../common/components/control/InputField";
import { memberTransferSchema } from "../yupSchema/memberTransferSchema";
import { useAuth } from "../../../auth/components/AuthInit";


export const MemberTransferForm = ({ formikRef, saveValues, mainWallet }) => {
  const intl = useIntl();
  const { auth } = useAuth();

  return (
    <Formik
      initialValues={{
        amount: "",
        receiverUsername: "",
      }}
      innerRef={formikRef}
      validationSchema={memberTransferSchema(intl)}
      enableReinitialize={true}
      onSubmit={(values, formikHelpers) => {
        const queryParams = {
          amount: values.amount,
          selusername: values.receiverUsername,
        };

        saveValues(queryParams);
      }}
    >
      {({
        values,
        handleSubmit,
        setFieldValue,
        touched,
        errors,
        setFieldError,
      }) => (
        <Form>
            <div className="mb-2">
            <label className="mb-2 text-input-label text-font3" style={{ color: 'white' }}>
                    {intl.formatMessage({ id: "receiverUsername" })}
                </label>
                
          <Field
              className="text-font2"
              name="receiverUsername"
              component={Input}
              placeholder={intl.formatMessage({ id: "receiverUsername" })}
              autoComplete="off"
              type="text"
              isUpperCaseOnly={true}
            />
          </div>
          <label className="mb-2 text-input-label text-font3" style={{ color: 'white' }}>
              {intl.formatMessage({ id: "amount" })}
            </label>
          <div className="mb-2 position-relative">
            <Field
              className="text-font2"
              name="amount"
              component={Input}
              placeholder={intl.formatMessage({ id: "amount" })}
              autoComplete="off"
              type="number"
            />
            <div
              className="position-absolute text-prim cursor-pointer text-font2"
              style={{ right: "25px",top:'8px',color:'#f5be09'}}
              onClick={() => {
                const [mainBal] = mainWallet.split(".");

                setFieldValue("amount", mainBal.replaceAll(",", ""));
              }}
            >
              {intl.formatMessage({ id: "all" })}
            </div>
          </div>

          <div className="small d-flex justify-content-end align-items-center text-font2">
            <div className="text-muted me-2">
              {intl.formatMessage({ id: "available" })} :
            </div>
            <div className="fw-semibold" style={{color:'white'}}>
              {auth.currencycode} {mainWallet}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
import { Field, Form, Formik } from "formik";
import { useIntl } from "react-intl";
import { Input } from "../../../../common/components/control/InputField";
import { withdrawSchema } from "../../yupSchema/withdrawSchema";
import { useAuth } from "../../../../auth/components/AuthInit";

export const WithdrawForm = ({
  formikRef,
  saveValues,
  mainWallet,
  floatingBalance,
}) => {
  const intl = useIntl();
  const { auth } = useAuth();

  return (
    <Formik
      initialValues={{
        amount: "",
      }}
      innerRef={formikRef}
      validationSchema={withdrawSchema(intl)}
      enableReinitialize={true}
      onSubmit={(values, formikHelpers) => {
        const queryParams = {
          withdrawamount: values.amount,
        };

        saveValues(queryParams);
      }}
    >
      {({
        values,
        handleSubmit,
        setFieldValue,
        touched,
        errors,
        setFieldError,
      }) => (
        <Form>
          <label
            className="mb-2 text-input-label text-font3"
            style={{ color: "white" }}
          >
            {intl.formatMessage({ id: "amount" })}
          </label>
          <div className="mb-2 position-relative">
            <Field
              className="text-font2"
              name="amount"
              component={Input}
              placeholder={intl.formatMessage({ id: "amount" })}
              autoComplete="off"
              type="number"
            />
            <div
              className="position-absolute text-prim cursor-pointer text-font2"
              style={{ right: "25px", top: "8px", color: "#f5be09" }}
              onClick={() => {
                const [mainBal] = mainWallet.split(".");

                setFieldValue("amount", mainBal.replaceAll(",", ""));
              }}
            >
              {intl.formatMessage({ id: "all" })}
            </div>
          </div>
          <div className="small d-flex justify-content-end align-items-center">
            <div className="d-flex align-items-center pe-2 me-2 custom-border-end text-font2">
              <div className="text-muted me-2">
                {intl.formatMessage({ id: "pending" })} :
              </div>
              <div className="fw-semibold">
                {auth.currencycode} {floatingBalance}
              </div>
            </div>
            <div className="d-flex align-items-center text-font2">
              <div className="text-muted me-2">
                {intl.formatMessage({ id: "available" })} :
              </div>
              <div className="fw-semibold">
                {auth.currencycode} {mainWallet}
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

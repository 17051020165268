import { Field, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Input } from "../../../common/components/control/InputField";
import { fetchIPAddress, login } from "../../api/loginapi";
import { LoginTab } from "../../enum/LoginTab";
import { LoginSchema } from "../../yupSchema/loginSchema";
import { useAuth } from "../AuthInit";

export const LoginFormTab = ({ setSwalProps, setLoginTab, setTempAuth }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { saveAuth, setCurrentUser, promoShow, setPromoShow } = useAuth();
  const intl = useIntl();
  const currentLocale = localStorage.getItem("locale");
  const ipaddress = useRef("");

  useEffect(() => {
    fetchIPAddress().then((res) => {
      ipaddress.ip = res.ip;
      localStorage.setItem("ipaddress", ipaddress.ip);
      if (!promoShow) {
        const modalTrigger = document.getElementById("modalTrigger");
        if (modalTrigger) {
          modalTrigger.click();
        }
        setPromoShow(true);
      }
    });
  }, []);

  const handleLanguageChange = (language) => {
    if (sessionStorage.getItem("platform") === "mobile") {
      localStorage.setItem("locale", language);
    } else {
      localStorage.setItem("locale", language);
      window.location.reload();
    }
  };
  return (
    <div className="d-flex flex-column align-items-stretch justify-content-center">
      <div className="d-flex mb-4 align-items-center justify-content-center">
        <img
          src={`${process.env.PUBLIC_URL}/image/logo.png`}
          style={{ height: "60px" }}
        />
      </div>
      <Formik
        initialValues={{ username: "", userPassword: "" }}
        enableReinitialize={true}
        validationSchema={LoginSchema(intl)}
        onSubmit={(values, formikHelpers) => {
          setIsLoading(true);
          fetchIPAddress()
            .then((res) => {
              const queryParams = {
                action: "memberlogin",
                username: values.username,
                password: values.userPassword,
                language: intl.locale,
                fcmnotificationid: "",
                ipaddress: res.ip,
              };

              login(queryParams)
                .then((resp) => {
                  setIsLoading(false);
                  if (parseInt(resp.code) === 0) {
                    saveAuth(resp);
                    setCurrentUser(resp);
                  } else {
                    formikHelpers.setFieldValue("userPassword", "");
                    setSwalProps({
                      show: true,
                      icon: "error",
                      title: intl.formatMessage({ id: "error" }),
                      text: resp.message,
                    });
                  }
                })
                .catch((error) => {
                  setIsLoading(false);
                });
            })
            .catch((error) => {
              setIsLoading(false);
            });
        }}
      >
        {({
          values,
          handleSubmit,
          setFieldValue,
          touched,
          errors,
          setFieldError,
        }) => (
          <Form>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingTop: "2%",
              }}
            >
              <div
                style={{
                  paddingLeft: "5%",
                  paddingRight: "5%",
                  width: "90%",
                  boxSizing: "border-box",
                }}
              >
                <label
                  className="mb-2 text-input-label"
                  style={{ color: "white" }}
                >
                  {intl.formatMessage({ id: "username" })}
                </label>
                <div className="mb-2">
                  <Field
                    name="username"
                    component={Input}
                    placeholder={intl.formatMessage({ id: "username" })}
                    autoComplete="off"
                    pattern="[a-zA-Z0-9]*"
                  />
                </div>
              </div>
              <div
                style={{
                  paddingLeft: "5%",
                  paddingRight: "5%",
                  width: "90%",
                  boxSizing: "border-box",
                }}
              >
                <label
                  className="mb-2 text-input-label"
                  style={{ color: "white" }}
                >
                  {intl.formatMessage({ id: "password" })}
                </label>
                <div className="mb-2">
                  <Field
                    name="userPassword"
                    component={Input}
                    placeholder={intl.formatMessage({ id: "password" })}
                    autoComplete="off"
                    type="password"
                  />
                </div>
              </div>
            </div>

            <div
              style={{
                paddingTop: "2%",
              }}
              className="d-flex align-items-center flex-column"
            >
              <div style={{ width: "80%" }}>
                <p
                  style={{
                    fontSize: 13,
                  }}
                  className="text-startt text-light"
                >
                  <div
                    style={{
                      cursor: "pointer",
                      background: "none",
                    }}
                    className="border-0 text-light text-decoration-underline"
                    onClick={() => {
                      setLoginTab(LoginTab.FORGOT_PASSWORD_PART_1);
                    }}
                  >
                    {intl.formatMessage({ id: "forgetpassword" })}
                  </div>
                </p>
              </div>
            </div>

            <div
              style={{
                paddingTop: "2%",
              }}
              className="d-flex align-items-center flex-column"
            >
              <button
                type="submit"
                disabled={isLoading}
                style={{
                  width: "60%",
                  height: 35,
                  borderRadius: 15,
                  backgroundColor: "rgb(245, 190, 9)",
                  fontWeight: "bold",
                  color: "black",
                  fontSize: "12px",
                }}
              >
                {isLoading ? (
                  <div
                    className="spinner-border"
                    role="status"
                    style={{ scale: "0.75" }}
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <div>
                    <span style={{ flex: 1, textAlign: "center" }}>
                      {intl.formatMessage({ id: "login" })}
                    </span>
                  </div>
                )}
              </button>
            </div>
          </Form>
        )}
      </Formik>

      <div
        style={{
          margin: "0 auto",
          width: "90%",
          marginTop: 20,
          marginBottom: 20,
          padding: 5,
        }}
        className="d-flex align-items-center justify-content-center"
      >
        {currentLocale !== "ms" && (
          <img
            src={`${process.env.PUBLIC_URL}/image/my.png`}
            style={{ height: "30px", width: "30px", marginLeft: "10px" }}
            onClick={() => handleLanguageChange("ms")}
          />
        )}
        {currentLocale !== "en" && (
          <img
            src={`${process.env.PUBLIC_URL}/image/en.png`}
            style={{ height: "30px", width: "30px", marginLeft: "10px" }}
            onClick={() => handleLanguageChange("en")}
          />
        )}
        {currentLocale !== "zh" && (
          <img
            src={`${process.env.PUBLIC_URL}/image/cn.png`}
            style={{ height: "30px", width: "30px", marginLeft: "10px" }}
            onClick={() => handleLanguageChange("zh")}
          />
        )}
        {currentLocale !== "id" && (
          <img
            src={`${process.env.PUBLIC_URL}/image/id.png`}
            style={{ height: "30px", width: "30px", marginLeft: "10px" }}
            onClick={() => handleLanguageChange("id")}
          />
        )}
      </div>
    </div>
  );
};

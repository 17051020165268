import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../redux/LotteryAction";
import { ApiStatusTypeEnum } from "../../../../../enumeration/ApiStatusTypeEnum";
import { Modal } from "../../../../common/components/Modal";
import Skeleton from "react-loading-skeleton";

export const LotteryDetailConst = {
  BASIC: "basic",
  ADVANCE: "advance",
};

export const LotteryDetail = ({
  setSwalProps,
  refid,
  orderid,
  dateType,
  setZIndex,
}) => {
  const intl = useIntl();
  const [lotteryDetailMode, setLotteryDetailMode] = useState(
    LotteryDetailConst.BASIC
  );
  const dismissBtnRef = useRef();
  const lotteryState = useSelector(({ lottery }) => lottery, shallowEqual);

  const dispatch = useDispatch();

  useEffect(() => {
    if (refid && orderid) {
      const queryParams = {
        action: "vbossslipdetail",
        refid,
        orderid,
      };

      dispatch(actions.getLotteryDetail(queryParams));
      setLotteryDetailMode(LotteryDetailConst.BASIC);
    }
  }, [refid, orderid]);

  const voidLottery = () => {
    setZIndex(9999);
    const queryParams = {
      action: "vbossvoid4d",
      refid,
      orderid,
    };

    dispatch(actions.voidLottery(queryParams)).then((resp) => {
      resp = resp.data;
      setZIndex(1);

      if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
        setSwalProps({
          show: true,
          icon: "success",
          title: intl.formatMessage({ id: "success" }),
          text: intl.formatMessage({ id: "voidSuccessfully" }),
          onResolve: () => {
            setSwalProps({});
            dispatch(
              actions.getLotteryList({
                action: "vbosssliplist",
                buydate: dateType,
              })
            );
            dismissBtnRef.current.click();
          },
        });
      } else {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };

  const reorderLottery = () => {
    setZIndex(9999);
    const queryParams = {
      action: "vbossreorder",
      orderid,
      refid,
    };

    dispatch(actions.reorderLottery(queryParams)).then((resp) => {
      resp = resp.data;
      setZIndex(1);

      if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
        setSwalProps({
          show: true,
          icon: "success",
          title: intl.formatMessage({ id: "success" }),
          text: intl.formatMessage({ id: "reorderSuccessfully" }),
          onResolve: () => {
            setSwalProps({});
            dispatch(
              actions.getLotteryList({
                action: "vbosssliplist",
                buydate: dateType,
              })
            );
            dismissBtnRef.current.click();
          },
        });
      } else {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };

  return (
    <Modal
      modalId="lotterySlip"
      title={intl.formatMessage({ id: "detail" })}
      dismissBtnRef={dismissBtnRef}
      modalSize="modal-lg"
    >
      <div className="p-3">
        <div className="d-flex mb-4">
          {Object.entries(LotteryDetailConst).map(([key, value], index) => (
            <div
              key={index}
              className={`me-3 cursor-pointer p-2 rounded px-4 ${
                value === lotteryDetailMode ? "text-dark fw-bold" : ""
              }`}
              style={{
                background:
                  value === lotteryDetailMode ? "#ffa500" : "rgba(0,0,0,0.2)",
              }}
              onClick={() => setLotteryDetailMode(value)}
            >
              {intl.formatMessage({ id: value })}
            </div>
          ))}
        </div>
        {lotteryState.actionsLoading || !lotteryState.lotteryDetail ? (
          <Skeleton style={{ height: "350px" }} className="w-100" />
        ) : (
          <div>
            <div>
              {lotteryDetailMode === LotteryDetailConst.BASIC ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: lotteryState.lotteryDetail.receipt.replaceAll(
                      "\n",
                      "<br/>"
                    ),
                  }}
                />
              ) : (
                <div className="table-responsive">
                  <table style={{ width: "100%", overflowX: "auto" }}>
                    <thead>
                      <tr className="border-bottom">
                        <th className="p-2">No.</th>
                        <th className="p-2">
                          {intl.formatMessage({
                            id: "date",
                          })}
                        </th>
                        <th className="p-2">
                          {intl.formatMessage({
                            id: "company",
                          })}
                        </th>
                        <th className="p-2">
                          {intl.formatMessage({
                            id: "number",
                          })}
                        </th>
                        <th className="p-2 text-end">
                          {intl.formatMessage({
                            id: "big",
                          })}
                        </th>
                        <th className="p-2 text-end">
                          {intl.formatMessage({
                            id: "small",
                          })}
                        </th>
                        <th className="p-2 text-end">
                          {intl.formatMessage({
                            id: "4a",
                          })}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {lotteryState.lotteryDetail.vbossSlipDetails.map(
                        (vBossSlip, index) => (
                          <tr key={index}>
                            <td className="p-2">{index + 1}</td>
                            <td className="ws-nowrap p-2">
                              {vBossSlip.drawdate}
                            </td>
                            <td className="p-2">
                              <div className="d-flex align-items-center">
                                <img
                                  src={vBossSlip.iconurl}
                                  style={{ width: "20px" }}
                                  alt={vBossSlip.drawtype}
                                  className="me-2"
                                />
                                {vBossSlip.drawtype}
                              </div>
                            </td>
                            <td className="p-2 text-center">
                              {vBossSlip.betnumber}
                            </td>
                            <td className="p-2 text-end">{vBossSlip.betbig}</td>
                            <td className="p-2 text-end">
                              {vBossSlip.betsmall}
                            </td>
                            <td className="p-2 text-end">{vBossSlip.beta}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            <div
              className="mt-4 d-flex align-items-center justify-content-between p-2 rounded"
              style={{ background: "rgba(0,0,0,0.2)" }}
            >
              <div>
                {intl.formatMessage({
                  id: "total",
                })}
              </div>
              <div>{lotteryState.lotteryDetail.totalbet}</div>
            </div>
            <div className="d-flex align-items-center justify-content-start mt-4">
              <button
                type="button"
                className="btn btn-danger fw-bold me-3 p-2 py-1 text-font3"
                onClick={() => {
                  setZIndex(1);
                  setSwalProps({
                    show: true,
                    showCancelButton: true,
                    showConfirmButton: true,
                    confirmButtonText: intl.formatMessage({
                      id: "confirm",
                    }),
                    denyButtonText: intl.formatMessage({
                      id: "cancel",
                    }),
                    icon: "info",
                    title: intl.formatMessage({ id: "alert" }),
                    text: intl.formatMessage({
                      id: "confirmVoidSlip",
                    }),
                    onConfirm: () => {
                      setSwalProps({});
                      voidLottery();
                    },
                  });
                }}
              >
                <div className="d-flex align-items-center justify-content-center">
                  <i className="bi bi-x-circle me-2 mb-1" />
                  <div>{intl.formatMessage({ id: "void" })}</div>
                </div>
              </button>
              <button
                type="button"
                className="btn btn-custom-light fw-bold p-2 py-1 text-font3"
                onClick={() => {
                  setZIndex(1);
                  setSwalProps({
                    show: true,
                    showCancelButton: true,
                    showConfirmButton: true,
                    confirmButtonText: intl.formatMessage({
                      id: "confirm",
                    }),
                    denyButtonText: intl.formatMessage({
                      id: "cancel",
                    }),
                    icon: "info",
                    title: intl.formatMessage({ id: "alert" }),
                    text: intl.formatMessage({
                      id: "confirmReorder",
                    }),
                    onConfirm: () => {
                      setSwalProps({});
                      reorderLottery();
                    },
                  });
                }}
              >
                <div className="d-flex align-items-center justify-content-center">
                  <i className="bi bi-pen me-2 mb-1" />
                  <div>{intl.formatMessage({ id: "reorder" })}</div>
                </div>
              </button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import {
  GameTypeEnumConst,
  GameTypeLandingConst,
} from "../../../constants/GameTypeConst";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/GamePlayAction";
import { CheckIsNull, toAbsoluteUrl } from "../../../utils/CommonHelper";
import { GameDepositDialog } from "./gameDepositDialog/GameDepositDialog";
import { GamePlayDialog } from "./gamePlayDialog/GamePlayDialog";

export const GamePlayListing = () => {
  const {
    gameType,
    companyOffset,
    companyCode,
    scrollPosition,
    gameCode,
    searchName,
  } = useParams();
  const intl = useIntl();
  const dispatch = useDispatch();

  const gamePlayState = useSelector(({ gamePlay }) => gamePlay, shallowEqual);
  const screenHeight = window.innerHeight;
  const screenWidth = window.innerWidth;
  const [visibleButton, setVisibleButton] = useState("topRight");
  const [dateNow, setDateNow] = useState(Date.now());
  // const isHorizontal = window.orientation === 90 || window.orientation === -90;
  useEffect(() => {
    const queryParams = {
      action: "companyplaygame",
      companycode: companyCode,
      gamecode: gameCode,
      platform: "mobile",
      gametype: GameTypeEnumConst[parseInt(gameType)],
    };
    dispatch(actions.playGame(queryParams));
  }, [companyCode, gameCode]);

  const toggleButton = () => {
    switch (visibleButton) {
      case "topRight":
        setVisibleButton("topLeft");
        break;
      case "topLeft":
        setVisibleButton("bottomLeft");
        break;
      case "bottomLeft":
        setVisibleButton("bottomRight");
        break;
      case "bottomRight":
        setVisibleButton("topRight");
        break;
      default:
        setVisibleButton("topRight");
    }
  };

  return (
    <div className="d-flex flex-column">
      <div
        id="gameDepositModalToggleBtn"
        style={{ display: "none" }}
        data-bs-toggle="modal"
        data-bs-target="#gameDepositModal"
      ></div>
      <GamePlayDialog
        toggleButton={toggleButton}
        companyCode={companyCode}
        gameType={GameTypeEnumConst[parseInt(gameType)]}
        companyOffset={companyOffset}
        searchName={searchName}
        scrollPosition={scrollPosition}
      />
      <div style={{ backgroundColor: "black" }}>
        {!gamePlayState.gamePlay || gamePlayState.actionsLoading ? (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ backgroundColor: "black", height: screenHeight }}
          >
            <div
              className="spinner-border"
              role="status"
              style={{ scale: "1.2" }}
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : gamePlayState.gamePlay.code === "1" ? (
          <div
            className="d-flex flex-column align-items-center justify-content-center text-muted"
            style={{ width: "100%", height: screenHeight }}
          >
            <i className="bi bi-search fs-1 mb-3" />
            <div style={{ color: "white" }}>
              {gamePlayState.gamePlay.message}
            </div>
            {visibleButton === "topRight" && (
              <div style={{ position: "absolute", top: 5, right: 5 }}>
                <div
                  style={{ background: "transparent" }}
                  data-bs-toggle="modal"
                  data-bs-target="#gamePlayModal"
                >
                  <img
                    src={toAbsoluteUrl(`/image/es51.gif`)}
                    style={{ width: "40px", height: "40px" }}
                  />
                </div>
              </div>
            )}

            {visibleButton === "topLeft" && (
              <div style={{ position: "absolute", top: 5, left: 5 }}>
                <div
                  style={{ background: "transparent" }}
                  data-bs-toggle="modal"
                  data-bs-target="#gamePlayModal"
                >
                  <img
                    src={toAbsoluteUrl(`/image/es51.gif`)}
                    style={{ width: "40px", height: "40px" }}
                  />
                </div>
              </div>
            )}

            {visibleButton === "bottomLeft" && (
              <div style={{ position: "absolute", bottom: 5, left: 5 }}>
                <div
                  style={{ background: "transparent" }}
                  data-bs-toggle="modal"
                  data-bs-target="#gamePlayModal"
                >
                  <img
                    src={toAbsoluteUrl(`/image/es51.gif`)}
                    style={{ width: "40px", height: "40px" }}
                  />
                </div>
              </div>
            )}

            {visibleButton === "bottomRight" && (
              <div style={{ position: "absolute", bottom: 5, right: 5 }}>
                <div
                  style={{ background: "transparent" }}
                  data-bs-toggle="modal"
                  data-bs-target="#gamePlayModal"
                >
                  <img
                    src={toAbsoluteUrl(`/image/es51.gif`)}
                    style={{ width: "40px", height: "40px" }}
                  />
                </div>
              </div>
            )}
          </div>
        ) : (
          <div>
            <iframe
              key={dateNow}
              title={gameCode}
              style={{
                height: screenHeight,
                width: screenWidth,
              }}
              src={gamePlayState.gamePlay.gameurl}
            />

            {visibleButton === "topRight" && (
              <div style={{ position: "absolute", top: 5, right: 5 }}>
                <div
                  style={{ background: "transparent" }}
                  data-bs-toggle="modal"
                  data-bs-target="#gamePlayModal"
                >
                  <img
                    src={toAbsoluteUrl(`/image/es51.gif`)}
                    style={{ width: "40px", height: "40px" }}
                  />
                </div>
              </div>
            )}

            {visibleButton === "topLeft" && (
              <div style={{ position: "absolute", top: 5, left: 5 }}>
                <div
                  style={{ background: "transparent" }}
                  data-bs-toggle="modal"
                  data-bs-target="#gamePlayModal"
                >
                  <img
                    src={toAbsoluteUrl(`/image/es51.gif`)}
                    style={{ width: "40px", height: "40px" }}
                  />
                </div>
              </div>
            )}

            {visibleButton === "bottomLeft" && (
              <div style={{ position: "absolute", bottom: 5, left: 5 }}>
                <div
                  style={{ background: "transparent" }}
                  data-bs-toggle="modal"
                  data-bs-target="#gamePlayModal"
                >
                  <img
                    src={toAbsoluteUrl(`/image/es51.gif`)}
                    style={{ width: "40px", height: "40px" }}
                  />
                </div>
              </div>
            )}

            {visibleButton === "bottomRight" && (
              <div style={{ position: "absolute", bottom: 5, right: 5 }}>
                <div
                  style={{ background: "transparent" }}
                  data-bs-toggle="modal"
                  data-bs-target="#gamePlayModal"
                >
                  <img
                    src={toAbsoluteUrl(`/image/es51.gif`)}
                    style={{ width: "40px", height: "40px" }}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

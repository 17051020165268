import { Field, Form, Formik } from "formik";
import { LoginTab } from "../../enum/LoginTab";
import { useRef, useState, useEffect } from "react";
import { FiRefreshCw } from "react-icons/fi";
import {
  forgotPasswordStep1Schema,
  forgotPasswordStep3Schema,
} from "../../yupSchema/forgotPasswordSchema";
import {
  fetchVerificationCode,
  forgotPasswordStep1,
  forgotPasswordStep2,
  forgotPasswordStep3,
  gmailForgotPasswordStep1,
  gmailForgotPasswordStep2,
  gmailForgotPasswordStep3,
} from "../../api/loginapi";
import OTPInput from "react-otp-input";
import { useIntl } from "react-intl";
import {
  ErrorContainer,
  Input,
  PhoneInputField,
  EmailInputField,
} from "../../../common/components/control/InputField";
import { CheckIsNull } from "../../../../utils/CommonHelper";
import Skeleton from "react-loading-skeleton";
import { ForgetPassNavTitle } from "../../../../constants/NavBarConst";

export const ForgetPasswordTab = ({ setLoginTab, setSwalProps, loginTab }) => {
  const dateNow = Date.now();
  const formikRef = useRef();
  const codeNum = useRef("");
  const intl = useIntl();
  const [forgotPasswordDetail, setForgotPasswordDetail] = useState();
  const [isShowPhoneNoError, setIsShowPhoneNoError] = useState(false);
  const [verifyImage, setVerifyImageUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [isShowOtpError, setIsShowOTPError] = useState(false);
  const [otpErrorDescription, setOtpErrorDescription] = useState("");
  const [phoneNoErrorDescription, setPhoneNoErrorDescription] = useState("");
  const [selectedNav, setSelectedNav] = useState(ForgetPassNavTitle.PHONE);
  const [forgotGmailPasswordDetail, setForgotGmailPasswordDetail] = useState();
  const [gmailNo, setGmailNo] = useState("");
  const [isShowGmailNoError, setIsShowGmailNoError] = useState(false);
  const [gmailNoErrorDescription, setGmailNoErrorDescription] = useState("");
  const getVerificationCode = () => {
    setVerifyImageUrl("");
    codeNum.current = "";
    fetchVerificationCode().then((resp) => {
      setVerifyImageUrl(resp.imageurl);
      codeNum.current = resp.codenum;
    });
  };

  useEffect(() => {
    getVerificationCode();
  }, []);

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <h3 style={{ color: "white" }}>
          {intl.formatMessage({ id: "forgetpassword" })}
        </h3>
      </div>
      <div style={{ margin: "10px", color: "white", textAlign: "center" }}>
        <span
          style={{
            color:
              loginTab === LoginTab.FORGOT_PASSWORD_PART_1
                ? "rgb(245, 190, 9)"
                : "white",
          }}
        >
          1
        </span>
        <div
          style={{
            display: "inline-block",
            width: "15%",
            verticalAlign: "middle",
            borderTop: "1px solid white",
            marginLeft: "5px",
            marginRight: "5px",
          }}
        ></div>
        <span
          style={{
            color:
              loginTab === LoginTab.FORGOT_PASSWORD_PART_2
                ? "rgb(245, 190, 9)"
                : "white",
          }}
        >
          2
        </span>
        <div
          style={{
            display: "inline-block",
            width: "15%",
            verticalAlign: "middle",
            borderTop: "1px solid white",
            marginLeft: "5px",
            marginRight: "5px",
          }}
        ></div>
        <span
          style={{
            color:
              loginTab === LoginTab.FORGOT_PASSWORD_PART_3
                ? "rgb(245, 190, 9)"
                : "white",
          }}
        >
          3
        </span>
      </div>
      {loginTab === LoginTab.FORGOT_PASSWORD_PART_1 ? (
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {Object.entries(ForgetPassNavTitle).map(([key, value]) => (
              <h6
                style={{
                  color: selectedNav === value ? "#f5be09" : "white",
                  borderBottom:
                    selectedNav === value ? "2px solid #f5be09" : "",
                  padding: "10px",
                }}
                className="text-font3"
                onClick={() => {
                  setSelectedNav(value);
                  getVerificationCode();
                }}
                key={key}
              >
                {intl.formatMessage({ id: value })}
              </h6>
            ))}
          </div>
          {selectedNav === ForgetPassNavTitle.PHONE ? (
            <Formik
              initialValues={{
                phoneNo: forgotPasswordDetail ? forgotPasswordDetail.telno : "",
                verifyCode: "",
              }}
              innerRef={formikRef}
              validationSchema={forgotPasswordStep1Schema(intl)}
              enableReinitialize={true}
              onSubmit={(values, formikHelpers) => {
                if (codeNum.current === values.verifyCode) {
                  setIsLoading(true);
                  const queryParams = {
                    telno: values.phoneNo,
                    uniqueid: dateNow,
                    language: intl.locale,
                  };

                  setForgotPasswordDetail(queryParams);

                  forgotPasswordStep1(queryParams).then((resp) => {
                    setIsLoading(false);
                    getVerificationCode();
                    formikHelpers.setFieldValue("verifyCode", "");
                    if (parseInt(resp.code) === 0) {
                      setLoginTab(LoginTab.FORGOT_PASSWORD_PART_2);
                    } else {
                      setIsShowPhoneNoError(true);
                      setPhoneNoErrorDescription(resp.message);
                    }
                  });
                } else {
                  formikHelpers.setFieldError(
                    "verifyCode",
                    intl.formatMessage({ id: "incorrectVerificationCode" })
                  );
                }
              }}
            >
              {({
                values,
                handleSubmit,
                setFieldValue,
                touched,
                errors,
                setFieldError,
                setFieldTouched,
              }) => (
                <Form>
                  <div className="mb-2">
                    <label
                      className="mb-2 text-input-label"
                      style={{ color: "white" }}
                    >
                      {intl.formatMessage({ id: "phoneNo" })}
                    </label>
                    <PhoneInputField
                      values={values}
                      setFieldValue={setFieldValue}
                      setIsShowPhoneNoError={setIsShowPhoneNoError}
                      isShowPhoneNoError={isShowPhoneNoError}
                      phoneNoErrorDescription={phoneNoErrorDescription}
                      setPhoneNoErrorDescription={setPhoneNoErrorDescription}
                      name="phoneNo"
                    />
                  </div>
                  <label
                    className="mb-2 text-input-label"
                    style={{ color: "white" }}
                  >
                    {intl.formatMessage({ id: "verificationCode" })}
                  </label>
                  <div className="mb-2 d-flex align-items-center">
                    {CheckIsNull(verifyImage) ? (
                      <Skeleton className="col-4" style={{ height: "60px" }} />
                    ) : (
                      <>
                        <img
                          src={verifyImage}
                          alt="verifyImage"
                          className="col-4 me-4"
                          style={{ height: "60px" }}
                        />
                        <FiRefreshCw
                          style={{ color: "white", marginLeft: "5px" }}
                          onClick={() => getVerificationCode()}
                        />
                      </>
                    )}
                  </div>
                  <div className="mb-2">
                    <Field
                      name="verifyCode"
                      component={Input}
                      placeholder={intl.formatMessage({
                        id: "verificationCode",
                      })}
                      autoComplete="off"
                      readOnly={isLoading}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      paddingTop: "2%",
                    }}
                  >
                    <button
                      type="submit"
                      disabled={isLoading}
                      style={{
                        width: "60%",
                        height: 35,
                        borderRadius: 15,
                        backgroundColor: "rgb(245, 190, 9)",
                        fontWeight: "bold",
                        color: "black",
                        fontSize: "12px",
                      }}
                    >
                      {isLoading && (
                        <div
                          className="spinner-border"
                          role="status"
                          style={{ scale: "0.75" }}
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      )}
                      {intl.formatMessage({ id: "requestOTP" })}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          ) : (
            <Formik
              initialValues={{
                email: forgotGmailPasswordDetail
                  ? forgotGmailPasswordDetail.gmailNo
                  : "",
                verifyCode: "",
              }}
              innerRef={formikRef}
              validationSchema={forgotPasswordStep1Schema(intl)}
              enableReinitialize={true}
              onSubmit={(values, formikHelpers) => {
                if (codeNum.current === values.verifyCode) {
                  setIsLoading(true);
                  // setGoogleGmail(values.gmailNo);
                  const queryParams = {
                    email: values.gmailNo,
                    uniqueid: dateNow,
                    language: intl.locale,
                  };

                  setForgotGmailPasswordDetail(queryParams);

                  gmailForgotPasswordStep1(queryParams).then((resp) => {
                    setIsLoading(false);
                    getVerificationCode();
                    formikHelpers.setFieldValue("verifyCode", "");
                    if (parseInt(resp.code) === 0) {
                      setLoginTab(LoginTab.FORGOT_PASSWORD_PART_2);
                    } else {
                      setIsShowGmailNoError(true);
                      setGmailNoErrorDescription(resp.message);
                    }
                  });
                } else {
                  formikHelpers.setFieldError(
                    "verifyCode",
                    intl.formatMessage({ id: "incorrectVerificationCode" })
                  );
                }
              }}
            >
              {({
                values,
                handleSubmit,
                setFieldValue,
                touched,
                errors,
                setFieldError,
                setFieldTouched,
              }) => (
                <Form>
                  <div className="mb-2">
                    <label
                      className="mb-2 text-input-label"
                      style={{ color: "white" }}
                    >
                      {intl.formatMessage({ id: "gmail" })}
                    </label>
                    <EmailInputField
                      values={values}
                      setFieldValue={setFieldValue}
                      setIsShowGmailNoError={setIsShowGmailNoError}
                      isShowGmailNoError={isShowGmailNoError}
                      gmailNoErrorDescription={gmailNoErrorDescription}
                      setGmailNoErrorDescription={setGmailNoErrorDescription}
                      name="gmailNo"
                    />
                  </div>
                  <label
                    className="mb-2 text-input-label"
                    style={{ color: "white" }}
                  >
                    {intl.formatMessage({ id: "verificationCode" })}
                  </label>
                  <div className="mb-2 d-flex align-items-center">
                    {CheckIsNull(verifyImage) ? (
                      <Skeleton className="col-4" style={{ height: "60px" }} />
                    ) : (
                      <>
                        <img
                          src={verifyImage}
                          alt="verifyImage"
                          className="col-4 me-4"
                          style={{ height: "60px" }}
                        />
                        <FiRefreshCw
                          style={{ color: "white", marginLeft: "5px" }}
                          onClick={() => getVerificationCode()}
                        />
                      </>
                    )}
                  </div>
                  <div className="mb-2">
                    <Field
                      name="verifyCode"
                      component={Input}
                      placeholder={intl.formatMessage({
                        id: "verificationCode",
                      })}
                      autoComplete="off"
                      readOnly={isLoading}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      paddingTop: "2%",
                    }}
                  >
                    <button
                      type="submit"
                      disabled={isLoading}
                      style={{
                        width: "60%",
                        height: 35,
                        borderRadius: 15,
                        backgroundColor: "rgb(245, 190, 9)",
                        fontWeight: "bold",
                        color: "black",
                        fontSize: "12px",
                      }}
                    >
                      {isLoading && (
                        <div
                          className="spinner-border"
                          role="status"
                          style={{ scale: "0.75" }}
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      )}
                      {intl.formatMessage({ id: "requestOTP" })}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </>
      ) : loginTab === LoginTab.FORGOT_PASSWORD_PART_2 ? (
        <>
          <div
            style={{
              color: "white",
              textAlign: "center",
              width: "70%",
              margin: "0 auto",
            }}
          >
            {intl.formatMessage({ id: "otpHasSended" })}
            {selectedNav === ForgetPassNavTitle.PHONE ? (
              <>
                {"(+"}
                {forgotPasswordDetail.telno}
                {")"}
              </>
            ) : (
              <>{forgotGmailPasswordDetail.gmailNo}</>
            )}
          </div>
          <OTPInput
            value={otp}
            onChange={setOtp}
            numInputs={5}
            containerStyle="justify-content-center"
            shouldAutoFocus={true}
            renderSeparator={<div className="mx-2 mb-3"></div>}
            renderInput={(props) => (
              <input {...props} className="form-control otp-field" />
            )}
          />
          <div className="d-flex justify-content-center mt-2">
            <ErrorContainer
              isShow={isShowOtpError}
              errorMsg={otpErrorDescription}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingTop: "2%",
            }}
          >
            <button
              type="submit"
              onClick={() => {
                if (!CheckIsNull(otp)) {
                  setIsShowOTPError(false);
                  setIsLoading(true);
                  if (selectedNav === ForgetPassNavTitle.PHONE) {
                    forgotPasswordStep2({
                      ...forgotPasswordDetail,
                      otpcode: otp,
                    }).then((resp) => {
                      setIsLoading(false);
                      setOtp("");
                      if (parseInt(resp.code) === 0) {
                        setLoginTab(LoginTab.FORGOT_PASSWORD_PART_3);
                      } else {
                        setIsShowOTPError(true);
                        setOtpErrorDescription(resp.message);
                      }
                    });
                  } else {
                    gmailForgotPasswordStep2({
                      ...forgotGmailPasswordDetail,
                      otpcode: otp,
                    }).then((resp) => {
                      setIsLoading(false);
                      setOtp("");
                      if (parseInt(resp.code) === 0) {
                        setLoginTab(LoginTab.FORGOT_PASSWORD_PART_3);
                      } else {
                        setIsShowOTPError(true);
                        setOtpErrorDescription(resp.message);
                      }
                    });
                  }
                } else {
                  setIsShowOTPError(true);
                  setOtpErrorDescription(
                    intl.formatMessage({ id: "otpRequired" })
                  );
                }
              }}
              disabled={isLoading}
              style={{
                width: "60%",
                height: 35,
                borderRadius: 15,
                backgroundColor: "rgb(245, 190, 9)",
                fontWeight: "bold",
                color: "black",
                fontSize: "12px",
              }}
            >
              <div className="d-flex align-items-center justify-content-center">
                {isLoading && (
                  <div
                    className="spinner-border text-dark"
                    role="status"
                    style={{ scale: "0.75" }}
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
                {intl.formatMessage({ id: "submit" })}
              </div>
            </button>
          </div>
        </>
      ) : (
        <Formik
          initialValues={{
            newPassword: "",
            confirmPassword: "",
          }}
          innerRef={formikRef}
          validationSchema={forgotPasswordStep3Schema(intl)}
          enableReinitialize={true}
          onSubmit={(values, formikHelpers) => {
            if (values.newPassword === values.confirmPassword) {
              if (selectedNav === ForgetPassNavTitle.PHONE) {
                let queryParams = {
                  ...forgotPasswordDetail,
                  password: values.newPassword,
                };
                setIsLoading(true);
                forgotPasswordStep3(queryParams).then((resp) => {
                  setIsLoading(false);
                  if (parseInt(resp.code) === 0) {
                    setSwalProps({
                      show: true,
                      icon: "success",
                      title: intl.formatMessage({ id: "success" }),
                      text: intl.formatMessage({ id: "resetSuccess" }),
                    });
                    setLoginTab(LoginTab.LOGIN);
                  } else {
                    formikHelpers.setFieldError("username", resp.message);
                  }
                });
              } else {
                let queryParams = {
                  ...forgotGmailPasswordDetail,
                  password: values.newPassword,
                };
                setIsLoading(true);
                gmailForgotPasswordStep3(queryParams).then((resp) => {
                  setIsLoading(false);
                  if (parseInt(resp.code) === 0) {
                    setSwalProps({
                      show: true,
                      icon: "success",
                      title: intl.formatMessage({ id: "success" }),
                      text: intl.formatMessage({ id: "resetSuccess" }),
                    });
                    setLoginTab(LoginTab.LOGIN);
                  } else {
                    formikHelpers.setFieldError("username", resp.message);
                  }
                });
              }
            } else {
              formikHelpers.setFieldError(
                "confirmPassword",
                intl.formatMessage({ id: "invalidConfirmPassword" })
              );
            }
          }}
        >
          {({
            values,
            handleSubmit,
            setFieldValue,
            touched,
            errors,
            setFieldError,
          }) => (
            <Form>
              <div className="mb-2">
                <label
                  className="mb-2 text-input-label"
                  style={{ color: "white" }}
                >
                  {intl.formatMessage({ id: "newPassword" })}
                </label>
                <Field
                  name="newPassword"
                  component={Input}
                  placeholder={intl.formatMessage({ id: "newPassword" })}
                  autoComplete="off"
                  type="password"
                />
              </div>
              <div className="mb-2">
                <label
                  className="mb-2 text-input-label"
                  style={{ color: "white" }}
                >
                  {intl.formatMessage({ id: "confirmPassword" })}
                </label>
                <Field
                  name="confirmPassword"
                  component={Input}
                  placeholder={intl.formatMessage({ id: "confirmPassword" })}
                  autoComplete="off"
                  type="password"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  paddingTop: "2%",
                }}
              >
                <button
                  type="submit"
                  disabled={isLoading}
                  style={{
                    width: "60%",
                    height: 35,
                    borderRadius: 15,
                    backgroundColor: "rgb(245, 190, 9)",
                    fontWeight: "bold",
                    color: "black",
                    fontSize: "12px",
                  }}
                >
                  <div className="d-flex align-items-center justify-content-center">
                    {isLoading && (
                      <div
                        className="spinner-border text-dark"
                        role="status"
                        style={{ scale: "0.75" }}
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                    {intl.formatMessage({ id: "submit" })}
                  </div>
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

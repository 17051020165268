import { useRef, useState, useEffect } from "react";
import { useIntl } from "react-intl";
import Skeleton from "react-loading-skeleton";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as homeActions from "../../../home/redux/HomeAction";
import SweetAlert2 from "react-sweetalert2";
import { MemberTransferForm } from "./MemberTransferForm";
import * as actions from "../../redux/SettingAction";
import { ApiStatusTypeEnum } from "../../../../enumeration/ApiStatusTypeEnum";
import { FiArrowLeft } from "react-icons/fi";
import { Link } from "react-router-dom";

export const MemberTransfer = () => {
  const [swalProps, setSwalProps] = useState({});
  const intl = useIntl();
  const formikRef = useRef();
  const dispatch = useDispatch();
  const settingState = useSelector(({ setting }) => setting, shallowEqual);
  const homeState = useSelector(({ home }) => home, shallowEqual);

  const saveValues = (queryParams) => {
    dispatch(
      actions.memberTransfer({ action: "transferfriend", ...queryParams })
    ).then((resp) => {
      resp = resp.data;

      if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
        setSwalProps({
          show: true,
          icon: "success",
          title: intl.formatMessage({ id: "success" }),
          text: intl.formatMessage({ id: "transferSuccessfully" }),
          onResolve: () => {
            setSwalProps({});
            formikRef.current.resetForm();
            dispatch(
              homeActions.getHomePageMemberData({
                action: "homepagebalance",
              })
            );
          },
        });
      } else {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };

  useEffect(() => {
    dispatch(
      homeActions.getHomePageMemberData({
        action: "homepagebalance",
      })
    );
  }, []);

  return (
    <div
      style={{
        backgroundColor: "black",
        backgroundImage: `url(${process.env.PUBLIC_URL}/image/homebackground.png)`,
        backgroundSize: "cover",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={styles.navBar}>
        <div style={styles.backButton}>
          <Link to="/setting/profile">
            <FiArrowLeft style={{ color: "white" }} />
          </Link>
        </div>
        <div style={styles.title} className="text-font4">
          {intl.formatMessage({ id: "memberTransfer" })}
        </div>
      </div>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      {homeState.homePageMemberData ? (
        <div style={{ width: "95%", margin: "0 auto" }}>
          <div className="mt-4">
            <MemberTransferForm
              formikRef={formikRef}
              saveValues={saveValues}
              mainWallet={homeState.homePageMemberData.es3balance}
            />
          </div>
          <button
            type="button"
            disabled={settingState.actionsLoading}
            style={{ color: "black", backgroundColor: "#f5be09" }}
            className="btn btn-prim fw-bold px-3 w-100 my-4 text-font3"
            onClick={() => formikRef.current.submitForm()}
          >
            {intl.formatMessage({ id: "submit" })}
          </button>
          <div className="custom-border-bottom mb-4" />
          <div className="text-muted text-font2">
            <div className="fw-bold mb-4">
              {intl.formatMessage({ id: "attention" })} :
            </div>
            <div className="mb-3">
              {intl.formatMessage({ id: "memberTransferDscription_1" })}
            </div>
            <div className="mb-1">
              {intl.formatMessage({ id: "memberTransferDscription_2" })}
            </div>
          </div>
        </div>
      ) : (
        <Skeleton className="w-100" height="600px" />
      )}
    </div>
  );
};
const styles = {
  navBar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "60px",
    backgroundColor: "black",
  },
  backButton: {
    position: "absolute",
    left: "10px",
    fontSize: "20px",
  },
  title: {
    fontWeight: "bold",
    color: "white",
  },
};

import * as Yup from "yup";

export const memberTransferSchema = (intl) => {
  return Yup.object().shape({
    amount: Yup.string()
      .nullable(false)
      .required(intl.formatMessage({ id: "amountRequired" })),
    receiverUsername: Yup.string()
      .nullable(false)
      .required(intl.formatMessage({ id: "receiverUsernameRequired" })),
  });
};
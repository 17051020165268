import { useEffect, useRef } from "react";
import * as actions from "../redux/FishingAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useState } from "react";
import { GameListing } from "../../common/components/GameListing";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CheckIsNull } from "../../../utils/CommonHelper";
import { GameTypeEnum } from "../../../enumeration/GameTypeEnum";

export const FishingListing = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [category, setCategory] = useState("all");
  const { companyCode } = useParams();
  const navigate = useNavigate();
  const [companyName, setCompanyName] = useState();
  const location = useLocation();
  const [companyOffset, setCompanyOffset] = useState(
    location.state ? parseInt(location.state.companyOffset) : 0
  );
  const isInitial = useRef(true);

  const fishingState = useSelector(({ fishing }) => fishing, shallowEqual);

  useEffect(() => {
    const params = {
      action: "mbfishinggamelist",
      companycode: companyCode,
      category,
    };

    dispatch(actions.getFishingGameList(params)).then(() => {
      if (location.state && isInitial.current) {
        window.scrollTo({
          top: parseInt(location.state.scrollPosition),
          behavior: "smooth",
        });
      }
      isInitial.current = false;
    });
  }, [companyCode, category]);

  const handlePlayGame = (gameCode, searchName) => {
    const currentScrollPosition =
      window.scrollY || document.documentElement.scrollTop;
    navigate(
      `/gamePlay/${companyCode}/${gameCode}/${companyOffset}/${currentScrollPosition}/${
        GameTypeEnum.FISHING
      }${CheckIsNull(searchName) ? "" : `/${searchName}`}`
    );
  };

  useEffect(() => {
    if (fishingState.fishingGameList) {
      const foundCompany = fishingState.fishingGameList.companyGameLists.find(
        (company) => company.companycode === companyCode
      );

      if (foundCompany) {
        setCompanyName(foundCompany.companyname);
      }
    }
  }, [fishingState.fishingGameList, companyCode]);

  return (
    <>
      <GameListing
        gameCompanyList={fishingState.fishingGameList?.companyGameLists}
        gameCategoryList={fishingState.fishingGameList?.fishingGameCategories}
        gameList={fishingState.fishingGameList?.fishingGameLists}
        setCategory={setCategory}
        category={category}
        companyCode={companyCode}
        handlePlayGame={handlePlayGame}
        setCompanyOffset={setCompanyOffset}
        companyOffset={companyOffset}
        initialSearchName={location.state ? location.state.searchName : ""}
        companyName={companyName}
        gameType={"fishing"}
      />
    </>
  );
};

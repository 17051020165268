import { ReduxCallTypesConst } from "../../../constants/ReduxCallTypesConst";
import { postMobile1 } from "../../common/redux/MasterLayoutCRUD";
import { liveCasinoSlice } from "./LiveCasinoSlice";

const { actions } = liveCasinoSlice;

export const getLiveCasinoGameList = (queryParams) => (dispatch) => {
  const controller = new AbortController();
  const signal = controller.signal;

  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return postMobile1(queryParams, signal)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.liveCasinoGameListFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: fetchLiveCasinoGameList");
      }
    })
    .catch((error) => {
      if (error.name !== "AbortError") {
        // Check if the error is due to aborting
        dispatch(
          actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
        );
        throw error;
      }
    });
};

export const clearLiveCasinoGameList = () => (dispatch) => {
  dispatch(actions.clearLiveCasinoGameList());
};

export const playGame = (queryParams) => (dispatch) => {
  const controller = new AbortController(); // Create an instance of AbortController
  const signal = controller.signal; // Get the signal from the controller

  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return postMobile1(queryParams, signal) // Pass signal to the API call
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.playCompanyGame({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: companyPlayGame");
      }
    })
    .catch((error) => {
      if (error.name !== "AbortError") {
        // Check if the error is due to aborting
        dispatch(
          actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
        );
        throw error;
      }
    });
};

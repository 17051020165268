import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { LoginTab } from "../../enum/LoginTab";
import { useAuth } from "../AuthInit";

export const RegisterMethodTab = ({ setLoginTab }) => {
  const intl = useIntl();
  const { promoShow, setPromoShow } = useAuth();

  useEffect(() => {
    if (!promoShow) {
      const modalTrigger = document.getElementById("modalTrigger");
      if (modalTrigger) {
        modalTrigger.click();
      }
      setPromoShow(true);
    }
  }, []);
  const renderRegister = () => {
    return (
      <div style={{ width: "100%", marginTop: 50 }}>
        <div style={{ textAlign: "center" }}>
          <h3 style={{ color: "white" }}>
            {intl.formatMessage({ id: "chooseRegisterMethod" })}
          </h3>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: "2%",
          }}
        >
          <button
            onClick={() => {
              setLoginTab(LoginTab.GOOGLE_REGISTER);
            }}
            style={{
              width: "60%",
              height: 35,
              borderRadius: 15,
              backgroundColor: "white",
              fontWeight: "bold",
              color: "black",
              fontSize: "12px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/image/google.png`}
              style={{ width: "20px", height: "20px", marginRight: "10px" }}
            />
            <span style={{ flex: 1, textAlign: "center" }}>
              {intl.formatMessage({ id: "registerWithGoogle" })}
            </span>
          </button>
        </div>
        <div style={{ margin: "10px", color: "white", textAlign: "center" }}>
          <div
            style={{
              display: "inline-block",
              width: "20%",
              verticalAlign: "middle",
              borderTop: "1px solid white",
            }}
          ></div>
          <span style={{ padding: "0 10px" }}>
            {intl.formatMessage({ id: "orRegisterWith" })}
          </span>
          <div
            style={{
              display: "inline-block",
              width: "20%",
              verticalAlign: "middle",
              borderTop: "1px solid white",
            }}
          ></div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: "2%",
          }}
        >
          <button
            onClick={() => {
              setLoginTab(LoginTab.REGISTER_PART_1);
            }}
            style={{
              width: "60%",
              height: 35,
              borderRadius: 15,
              backgroundColor: "white",
              fontWeight: "bold",
              color: "black",
              fontSize: "12px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/image/phone.png`}
              style={{ width: "20px", height: "20px", marginRight: "10px" }}
            />
            <span style={{ flex: 1, textAlign: "center" }}>
              {intl.formatMessage({ id: "registerWithPhone" })}
            </span>
          </button>
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        backgroundColor: "transparent",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <div style={{ width: "100%" }}>{renderRegister()}</div>
    </div>
  );
};

import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Skeleton from "react-loading-skeleton";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/SettingAction";
import { Link } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import { CopyToClipboard, toAbsoluteUrl } from "../../../../utils/CommonHelper";
import SweetAlert2 from "react-sweetalert2";

export const ContactUs = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const settingState = useSelector(({ setting }) => setting, shallowEqual);
  const [swalProps, setSwalProps] = useState({});

  useEffect(() => {
    dispatch(actions.getContactList({ action: "contactlist" }));
  }, []);

  useEffect(() => {
    document.body.classList.add(
      "pages-bg",
      "h-100",
      "scroll-y-default",
      "bg-dark"
    );
    document.querySelector("html").classList.add("h-100", "scroll-y-default");
    document
      .querySelector("#root")
      .classList.add("h-100", "w-100", "scroll-y-default", "position-absolute");

    document.body.style.backgroundImage = `url(${toAbsoluteUrl(
      "/image/homebackground.png"
    )})`;

    return () => {
      document.body.style.backgroundImage = ``;
      document.body.classList.remove(
        "pages-bg",
        "h-100",
        "scroll-y-default",
        "bg-dark"
      );
      document
        .querySelector("html")
        .classList.remove("h-100", "scroll-y-default");
      document
        .querySelector("#root")
        .classList.remove(
          "h-100",
          "w-100",
          "scroll-y-default",
          "position-absolute"
        );
    };
  }, []);

  return (
    <>
      <SweetAlert2
        onResolve={() => {
          setSwalProps({});
        }}
        backdrop={false}
        {...swalProps}
        customClass="custom-swal"
      />
      <div style={styles.navBar}>
        <div style={styles.backButton}>
          <Link to="/setting">
            <FiArrowLeft style={{ color: "white" }} />
          </Link>
        </div>
        <div style={styles.title} className="text-font4">
          {intl.formatMessage({ id: "contactUs" })}
        </div>
      </div>
      <div>
        {settingState.contactList ? (
          <>
            {settingState.contactList.contactLists.map((contactList, index) => (
              <div
                key={index}
                className={`custom-border rounded px-3 py-4 ${
                  contactList.isurl === "true"
                    ? "cursor-pointer"
                    : "d-flex align-items-center justify-content-between"
                }`}
                onClick={() => {
                  if (contactList.isurl === "true") {
                    window.open(contactList.contact);
                  }
                }}
                style={{
                  backgroundColor: "rgba(255,255,255,0.02)",
                  width: "94%",
                  margin: "10px auto",
                  padding: "5px",
                  color: "white",
                  fontSize: "12px",
                  borderRadius: "5px",
                }}
              >
                <div>
                  <div className="mb-3 fw-bold ">{contactList.channelname}</div>
                  <div>{contactList.contact}</div>
                </div>
                {contactList.isurl !== "true" && (
                  <i
                    className="ms-2 fs-3 fa fa-copy cursor-pointer"
                    style={{ color: "white" }}
                    onClick={() => {
                      if (sessionStorage.getItem("platform") === "mobile") {
                        CopyToClipboard(
                          contactList.contact,
                          setSwalProps,
                          intl
                        );
                      } else {
                        CopyToClipboard(
                          contactList.contact,
                          setSwalProps,
                          intl
                        );
                      }
                    }}
                  />
                )}
              </div>
            ))}
          </>
        ) : (
          <Skeleton className="w-100" height="600px" />
        )}
      </div>
    </>
  );
};

const styles = {
  navBar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "60px",
    backgroundColor: "black",
  },
  backButton: {
    position: "absolute",
    left: "10px",
    fontSize: "20px",
  },
  title: {
    fontWeight: "bold",
    color: "white",
  },
};

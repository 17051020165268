import { useEffect, useState } from "react";
import * as actions from "../../redux/SettingAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import Skeleton from "react-loading-skeleton";
import { CheckIsNull, toAbsoluteUrl } from "../../../../utils/CommonHelper";
import { TransferDialog } from "./transferDialog/TransferDialog";
import SweetAlert2 from "react-sweetalert2";
import { Overlay } from "../../../common/components/Overlay";
import { FiArrowLeft } from "react-icons/fi";
import { Link, useLocation } from "react-router-dom";
import { ApiStatusTypeEnum } from "../../../../enumeration/ApiStatusTypeEnum";

export const GameTransfer = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const settingState = useSelector(({ setting }) => setting, shallowEqual);
  const [swalProps, setSwalProps] = useState({});
  const [company, setCompany] = useState();
  const [isUpdateBalance, setIsUpdateBalance] = useState(0);
  const [isHovered, setHovered] = useState(false);
  const location = useLocation();

  useEffect(() => {
    dispatch(actions.getGameCompanyTransferUI({ action: "membergamelist" }));
  }, [isUpdateBalance]);

  const handleButtonClick = () => {
    dispatch(actions.transferBackWallet()).then((resp) => {
      resp = resp.data;

      if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
        setSwalProps({
          show: true,
          icon: "success",
          title: intl.formatMessage({ id: "success" }),
          text: intl.formatMessage({ id: "transferSuccessfully" }),
        });
        if (sessionStorage.getItem("platform") === "mobile") {
          console.log("update:transferall");
        } else {
          window.location.reload();
        }
      } else {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };

  useEffect(() => {
    document.body.classList.add(
      "pages-bg",
      "h-100",
      "scroll-y-default",
      "bg-dark"
    );
    document.querySelector("html").classList.add("h-100", "scroll-y-default");
    document
      .querySelector("#root")
      .classList.add("h-100", "w-100", "scroll-y-default", "position-absolute");

    document.body.style.backgroundImage = `url(${toAbsoluteUrl(
      "/image/homebackground.png"
    )})`;

    return () => {
      document.body.style.backgroundImage = ``;
      document.body.classList.remove(
        "pages-bg",
        "h-100",
        "scroll-y-default",
        "bg-dark"
      );
      document
        .querySelector("html")
        .classList.remove("h-100", "scroll-y-default");
      document
        .querySelector("#root")
        .classList.remove(
          "h-100",
          "w-100",
          "scroll-y-default",
          "position-absolute"
        );
    };
  }, []);

  const customCardClass =
    "rounded d-flex flex-column align-items-center justify-content-center py-3";

  return (
    <div>
      <div style={styles.navBar}>
        <div style={styles.backButton}>
          {location.state && location.state.backTo && (
            <Link to={location.state.backTo}>
              <FiArrowLeft style={{ color: "white" }} />
            </Link>
          )}
        </div>
        <div style={styles.title} className="text-font4">
          {intl.formatMessage({ id: "gameTransfer" })}
        </div>
      </div>
      <div>
        <SweetAlert2
          customClass="custom-swal"
          onResolve={() => {
            setSwalProps({});
          }}
          {...swalProps}
        />
        {settingState.actionsLoading && <Overlay />}
        {settingState.gameCompanyTransferUI ? (
          <>
            <TransferDialog
              setSwalProps={setSwalProps}
              setIsUpdateBalance={setIsUpdateBalance}
              company={company}
              mainWallet={settingState.gameCompanyTransferUI.es3balance}
            />
            <div
              style={{
                backgroundColor: "#1c1e23",
                width: "95%",
                margin: "5px auto",
                paddingTop: "10px",
                paddingBottom: "7vh",
                borderRadius: "5px",
              }}
            >
              <div
                className="d-flex justify-content-between align-items-center mb-4"
                style={{ width: "95%", margin: "0 auto", color: "white" }}
              >
                <div
                  className={`col-4 ${customCardClass}`}
                  style={{ background: "rgba(0,0,0,0.2)" }}
                >
                  <div className="mb-3 text-muted">
                    {intl.formatMessage({ id: "walletBalance" })}
                  </div>
                  <div className="fw-bold">
                    {settingState.gameCompanyTransferUI.es3balance}
                  </div>
                </div>
                <div>
                  {/* <div className="align-items-center mb-2">
                    <button
                      type="button"
                      className="fw-semibold ws-nowrap"
                      style={{
                        fontSize: "12px",
                        backgroundColor: isHovered
                          ? "#f5be09"
                          : "rgba(245, 190, 9, 0.05)",
                        color: isHovered ? "" : "#f5be09",
                        border: "1px solid #f5be09",
                        padding: "0.5rem",
                        borderRadius: "5px",
                        transition: "background-color 0.3s, color 0.3s",
                      }}
                      onTouchStart={() => setHovered(true)}
                      onTouchEnd={() => setHovered(false)}
                      onClick={handleButtonClick}
                    >
                      {intl
                        .formatMessage({ id: "transferAllToWallet" })
                        .toUpperCase()}
                    </button>
                  </div> */}
                </div>
              </div>
              <div
                className="row px-1 mb-4 justify-content-between align-items-center"
                style={{ width: "95%", margin: "0 0" }}
              >
                {settingState.gameCompanyTransferUI.memberGameLists.map(
                  (company, index) => (
                    <div
                      className="col-md-3 px-2 mb-3"
                      key={index}
                      style={{
                        width: "33.333%",
                        margin: "0 0",
                        color: "white",
                      }}
                    >
                      <div
                        className={`${customCardClass} cursor-pointer position-relative text-center`}
                        style={{ background: "rgba(0,0,0,0.2)" }}
                        title={intl.formatMessage({ id: "clickTransfer" })}
                        onClick={() => setCompany(company)}
                        data-bs-toggle="modal"
                        data-bs-target="#transferModal"
                      >
                        <img
                          className="position-absolute"
                          src={toAbsoluteUrl("image/transferIcon.png")}
                          style={{
                            width: "20px",
                            right: "0.1rem",
                            top: "0.1rem",
                          }}
                        />
                        <div className="mb-3 text-muted truncate text-font3">
                          {company.gamename}
                        </div>
                        <GamaBalance
                          gamebalance={company.gamebalance}
                          companyCode={company.gamecode}
                          isUpdateBalance={isUpdateBalance}
                        />
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </>
        ) : (
          <Skeleton className="w-100" height="600px" />
        )}
      </div>
    </div>
  );
};

const GamaBalance = ({ companyCode, isUpdateBalance, gamebalance }) => {
  const dispatch = useDispatch();
  const [balance, setBalance] = useState(gamebalance);

  useEffect(() => {
    if (gamebalance === "--") {
      dispatch(
        actions.getCompanyGameBalance({
          action: "membertargetbalance",
          companycode: companyCode,
        })
      ).then((resp) => {
        resp = resp.data;
        setBalance(resp.balance);
      });
    } else {
      setBalance(gamebalance);
    }
  }, [companyCode, gamebalance, isUpdateBalance]);

  return <div className="fw-bold text-prim text-font2">{balance}</div>;
};

const styles = {
  navBar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "60px",
    backgroundColor: "black",
  },
  backButton: {
    position: "absolute",
    left: "10px",
    fontSize: "20px",
  },
  title: {
    fontWeight: "bold",
    color: "white",
  },
};

/* eslint-disable jsx-a11y/anchor-is-valid */
import { Route, Routes } from "react-router-dom";
import { createContext } from "react";
import { AppGamesListing } from "../../modules/appGames/component/AppGamesListing";

const AppGamesContext = createContext();

const AppGamesWrapper = () => {
  const value = {};

  return (
    <AppGamesContext.Provider value={value}>
      <Routes>
        <Route path="/:companyCode" element={<AppGamesListing />} />
      </Routes>
    </AppGamesContext.Provider>
  );
};

export { AppGamesWrapper };

import * as Yup from "yup";

export const addBankSchema = (intl) => {
  return Yup.object().shape({
    accountNum: Yup.string()
      .nullable(false)
      .required(intl.formatMessage({ id: "accountNumRequired" })),
    fullName: Yup.string()
      .nullable(false)
      .required(intl.formatMessage({ id: "fullNameRequired" })),
  });
};
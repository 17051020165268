import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GameTypeEnum } from "../../../enumeration/GameTypeEnum";
import { CheckIsNull } from "../../../utils/CommonHelper";
import { GameListing } from "../../common/components/GameListing";
import * as actions from "../redux/LiveCasinoAction";

export const LiveCasinoListing = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [category, setCategory] = useState("all");
  const { companyCode } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [companyName, setCompanyName] = useState();
  const location = useLocation();
  const [companyOffset, setCompanyOffset] = useState(
    location.state ? parseInt(location.state.companyOffset) : 0
  );
  const isInitial = useRef(true);

  const liveCasinoState = useSelector(
    ({ liveCasino }) => liveCasino,
    shallowEqual
  );

  useEffect(() => {
    const params = {
      action: "mblivegamelistV2",
      companycode: companyCode,
      category,
    };

    dispatch(actions.getLiveCasinoGameList(params)).then(() => {
      if (location.state && isInitial.current) {
        window.scrollTo({
          top: parseInt(location.state.scrollPosition),
          behavior: "smooth",
        });
      }
      isInitial.current = false;
    });
  }, [companyCode, category]);

  const handlePlayGame = (gameCode, searchName) => {
    const currentScrollPosition =
      window.scrollY || document.documentElement.scrollTop;
    navigate(
      `/gamePlay/${companyCode}/${gameCode}/${companyOffset}/${currentScrollPosition}/${
        GameTypeEnum.LIVE
      }${CheckIsNull(searchName) ? "" : `/${searchName}`}`
    );
  };

  useEffect(() => {
    if (liveCasinoState.liveCasinoGameList) {
      const foundCompany =
        liveCasinoState.liveCasinoGameList.companyGameLists.find(
          (company) => company.companycode === companyCode
        );

      if (foundCompany) {
        setCompanyName(foundCompany.companyname);
      }
    }
  }, [liveCasinoState.liveCasinoGameList, companyCode]);

  return (
    <>
      <GameListing
        gameCompanyList={liveCasinoState.liveCasinoGameList?.companyGameLists}
        gameCategoryList={
          liveCasinoState.liveCasinoGameList?.liveGameCategories
        }
        gameLobby={liveCasinoState.liveCasinoGameList?.liveGameLobby}
        gameList={liveCasinoState.liveCasinoGameList?.liveGameLists}
        setCategory={setCategory}
        category={category}
        companyCode={companyCode}
        handlePlayGame={handlePlayGame}
        setCompanyOffset={setCompanyOffset}
        companyOffset={companyOffset}
        initialSearchName={location.state ? location.state.searchName : ""}
        companyName={companyName}
        gameType={"live"}
      />
    </>
  );
};

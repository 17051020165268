import { useState } from "react";
import { useIntl } from "react-intl";
import {
  HistoryTelcoStatusConst,
  HistoryStatusColorConst,
  HistoryStatusConst,
  historyDurationList,
} from "../../../../../constants/HistoryConst";
import * as actions from "../../../redux/SettingAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import SweetAlert2 from "react-sweetalert2";

export const HistoryTelco = () => {
  const intl = useIntl();
  const [dayType, setDayType] = useState(1);
  const [status, setStatus] = useState(HistoryTelcoStatusConst.PENDING);
  const [swalProps, setSwalProps] = useState({});
  const dispatch = useDispatch();

  const settingState = useSelector(({ setting }) => setting, shallowEqual);

  useEffect(() => {
    dispatch(actions.getTelcoDepositList({ days: dayType }));
    console.log(settingState);
  }, [dayType]);

  return (
    <>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      <div className="col-3">
        <select
          className="form-control text-font3"
          value={dayType}
          onChange={(e) => setDayType(e.target.value)}
        >
          {historyDurationList.map((day, index) => (
            <option key={index} value={day}>
              {day} {intl.formatMessage({ id: "days" })}
            </option>
          ))}
        </select>
      </div>
      <div
        className="d-flex mb-4"
        style={{
          margin: "10px auto",
        }}
      >
        {Object.entries(HistoryTelcoStatusConst).map(([key, value]) => (
          <div
            className={`me-3 cursor-pointer switch px-3 rounded fw-bold py-2 text-font2`}
            style={{
              color: status === value ? "#f5be09" : "white",
              border:
                status === value ? "1px solid #f5be09" : "1px solid white",
              borderRadius: "5px",
            }}
            onClick={() => setStatus(value)}
            key={key}
          >
            {intl.formatMessage({
              id:
                value === HistoryTelcoStatusConst.APPROVE
                  ? "success"
                  : value === HistoryTelcoStatusConst.REJECT
                  ? "fail"
                  : value,
            })}
          </div>
        ))}
      </div>
      {settingState.depositTelcoList ? (
        <>
          {settingState.listLoading ? (
            <div className="d-flex align-items-center justify-content-center py-5">
              <div
                className="spinner-border"
                role="status"
                style={{ scale: "1.2" }}
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <div style={{ minHeight: "600px" }}>
              {settingState.depositTelcoList.topupTelcoLists.filter(
                (deposit) =>
                  deposit.telcostatus.toLowerCase() === status.toLowerCase()
              ).length > 0 ? (
                settingState.depositTelcoList.topupTelcoLists
                  .filter(
                    (deposit) =>
                      deposit.telcostatus.toLowerCase() === status.toLowerCase()
                  )
                  .map((deposit, index) => (
                    <div
                      className="card mb-2"
                      key={index}
                      style={{ background: "#1c1e23", color: "white" }}
                    >
                      <div
                        className="card-body rounded"
                        style={{
                          background: "rgba(0,0,0,0.2)",
                        }}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="fw-bold text-font3">
                            {deposit.topupamount}
                          </div>
                          <div
                            className={`py-1 px-2 rounded small fw-bold text-dark`}
                            style={{
                              fontSize: "11px",
                              background:
                                HistoryStatusColorConst[deposit.telcostatus],
                            }}
                          >
                            {deposit.telcostatus}
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div>
                            <div className="small text-font2">
                              {intl.formatMessage({ id: "telconame" })} :{" "}
                              {deposit.telconame}
                            </div>
                            <div className="small text-font2">
                              {intl.formatMessage({ id: "mobilename" })} :{" "}
                              {deposit.mobilename}
                            </div>
                            <div className="text-muted fst-italic small text-font2">
                              {intl.formatMessage({ id: "requestedDate" })} :{" "}
                              {deposit.requestdate}
                            </div>
                            {(deposit.telcostatus.toLowerCase() ===
                              HistoryStatusConst.APPROVE.toLowerCase() ||
                              deposit.telcostatus.toLowerCase() ===
                                HistoryStatusConst.REJECT.toLowerCase()) && (
                              <div className="text-muted fst-italic small text-font2">
                                {intl.formatMessage({ id: "respondDate" })} :{" "}
                                {deposit.responsedate}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
              ) : (
                <div className="d-flex py-5 align-items-center justify-content-center">
                  <div className="text-muted d-flex align-items-center justify-content-center flex-column">
                    <i className="bi bi-search fs-1 mb-3" />
                    <div>{intl.formatMessage({ id: "noRecordFound" })}</div>
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      ) : (
        <Skeleton className="w-100" height="600px" />
      )}
    </>
  );
};

/* eslint-disable jsx-a11y/anchor-is-valid */
import { Navigate, Route, Routes } from "react-router-dom";
import { createContext } from "react";
import { Setting } from "../../modules/setting/component/Setting";
import { Deposit } from "../../modules/setting/component/deposit/deposit";
import { PromotionListing } from "../../modules/promotion/component/PromotionListing";
import { TransactionReport } from "../../modules/setting/component/transactionReport/TransactionReport";
import { Personal } from "../../modules/setting/component/personal/Personal";
import { PointShop } from "../../modules/setting/component/pointShop/PointShop";
import { Bonus } from "../../modules/setting/component/bonus/Bonus";
import { MemberTransfer } from "../../modules/setting/component/memberTransfer/MemberTransfer";
import { Withdraw } from "../../modules/setting/component/withdraw/Withdraw";
import { GameTransfer } from "../../modules/setting/component/gameTransfer/GameTransfer";
import { AddBank } from "../../modules/setting/component/bankMangement/AddBank";
import { LiveChat } from "../../modules/setting/component/liveChat/LiveChat";
import { MobileDeposit } from "../../modules/setting/component/deposit/depositDialog/gatewayDeposit/mobileDeposit/MobileDeposit";
import { DownloadListing } from "../../modules/download/component/Download";
import { Team } from "../../modules/setting/component/team/Team";
import { ContactUs } from "../../modules/setting/component/contactUs/contactUs";
const SettingContext = createContext();

const SettingWrapper = ({}) => {
  const value = {};

  return (
    <SettingContext.Provider value={value}>
      <Routes>
        <Route path="/profile" element={<Setting />} />
        <Route path="/deposit" element={<Deposit />} />
        <Route path="/deposit/payment" element={<MobileDeposit />} />
        <Route path="/personal" element={<Personal />} />
        <Route path="/promotion" element={<PromotionListing />} />
        <Route path="/report" element={<TransactionReport />} />
        <Route path="/pointShop" element={<PointShop />} />
        <Route path="/bonus" element={<Bonus />} />
        <Route path="/memberTransfer" element={<MemberTransfer />} />
        <Route path="/withdraw" element={<Withdraw />} />
        <Route path="/transfer" element={<GameTransfer />} />
        <Route path="/addBank" element={<AddBank />} />
        <Route path="/chat" element={<LiveChat />} />
        <Route path="/download" element={<DownloadListing />} />
        <Route path="/team" element={<Team />} />
        <Route path="/contactUs" element={<ContactUs />} />
        <Route path="/*" element={<Navigate to="/profile" />} />
      </Routes>
    </SettingContext.Provider>
  );
};

export { SettingWrapper };

import { useEffect, useState, useRef } from "react";
import * as actions from "../redux/SlotAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { CheckIsNull } from "../../../utils/CommonHelper";
import { GameListing } from "../../common/components/GameListing";
import { useNavigate, useLocation, Link, useParams } from "react-router-dom";
import { GameTypeEnum } from "../../../enumeration/GameTypeEnum";

export const SlotListing = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [category, setCategory] = useState("all");
  const { companyCode } = useParams();
  const [companyName, setCompanyName] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const [companyOffset, setCompanyOffset] = useState(
    location.state ? parseInt(location.state.companyOffset) : 0
  );
  const isInitial = useRef(true);
  const slotState = useSelector(({ slot }) => slot, shallowEqual);

  useEffect(() => {
    const params = {
      action: "mbslotgamelist",
      companycode: companyCode,
      category,
    };

    dispatch(actions.getSlotGameList(params)).then(() => {
      if (location.state && isInitial.current) {
        window.scrollTo({
          top: parseInt(location.state.scrollPosition),
          behavior: "smooth",
        });
      }
      isInitial.current = false;
    });
  }, [companyCode, category]);

  useEffect(() => {
    if (slotState.slotGameList) {
      const foundCompany = slotState.slotGameList.companyGameLists.find(
        (company) => company.companycode === companyCode
      );

      if (foundCompany) {
        setCompanyName(foundCompany.companyname);
      }
    }
  }, [slotState.slotGameList, companyCode]);

  const handlePlayGame = (gameCode, searchName) => {
    const currentScrollPosition =
      window.scrollY || document.documentElement.scrollTop;
    navigate(
      `/gamePlay/${companyCode}/${gameCode}/${companyOffset}/${currentScrollPosition}/${
        GameTypeEnum.SLOTS
      }${CheckIsNull(searchName) ? "" : `/${searchName}`}`
    );
  };

  return (
    <>
      <GameListing
        gameCompanyList={slotState.slotGameList?.companyGameLists}
        gameCategoryList={slotState.slotGameList?.slotGameCategory}
        gameList={slotState.slotGameList?.slotGameLists}
        setCategory={setCategory}
        category={category}
        companyCode={companyCode}
        handlePlayGame={handlePlayGame}
        setCompanyOffset={setCompanyOffset}
        companyOffset={companyOffset}
        initialSearchName={location.state ? location.state.searchName : ""}
        companyName={companyName}
        gameType={"slot"}
      />
    </>
  );
};

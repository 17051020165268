import { useIntl } from "react-intl";
import { Modal } from "../../../common/components/Modal";
import { useRef } from "react";
import { GameDepositDialog } from "../gameDepositDialog/GameDepositDialog";
import { useNavigate } from "react-router-dom";


export const GamePlayDialog = ({toggleButton, companyCode,gameType,companyOffset,scrollPosition,searchName}) => {
  const intl = useIntl();
  const dismissBtnRef = useRef();
  const Navigate = useNavigate();

  const handleDeposit = ( ) => {
    document.getElementById("gameDepositModalToggleBtn").click();
    dismissBtnRef.current.click();
  };
  const handleChangePosition = () => {
    dismissBtnRef.current.click();
    toggleButton();
  };

  const handleGoBack = () => {
    dismissBtnRef.current.click();
    Navigate(`/${gameType}/${companyCode}`, {
        state: { companyOffset, searchName, scrollPosition },
      });
  };

  return (
    <div>     
    <Modal
      modalId="gamePlayModal"
      title={intl.formatMessage({ id: "option" })}
      dismissBtnRef={dismissBtnRef}
      modalSize="modal-lg"
    >
      <div className="p-3">
        <div onClick={handleDeposit}
        style={{
                width: "100%",
                height: "50px",
                display: "flex",
                cursor: "pointer",
                alignItems: "center",
                borderBottom:'1px solid #262626',
              }}>{intl.formatMessage({ id: "deposit" })}

        </div>

        <div onClick={handleChangePosition}
          style={{
            width: "100%",
            height: "50px",
            display: "flex",
            cursor: "pointer",
            alignItems: "center",
            borderBottom:'1px solid #262626',
          }}>{intl.formatMessage({ id: "changePosition" })}
        </div>

        <div onClick={handleGoBack}
          style={{
            width: "100%",
            height: "50px",
            display: "flex",
            cursor: "pointer",
            alignItems: "center",
          }}>{intl.formatMessage({ id: "backPage" })}
        </div>
      </div>
    </Modal>
    <GameDepositDialog companyCode={companyCode} />
    </div>
    
  );
};

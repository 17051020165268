import { ReduxCallTypesConst } from "../../../constants/ReduxCallTypesConst";
import { postMobile1 } from "../../common/redux/MasterLayoutCRUD";
import { slotSlice } from "./SlotSlice";

const { actions } = slotSlice;

export const getSlotGameList = (queryParams) => (dispatch) => {
  const controller = new AbortController();
  const signal = controller.signal;

  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return postMobile1(queryParams, signal)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.slotGameListFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: fetchSlotGameList");
      }
    })
    .catch((error) => {
      if (error.name !== "AbortError") {
        dispatch(
          actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
        );
        throw error;
      }
    });
};

export const clearSlotGameList = () => (dispatch) => {
  dispatch(actions.clearSlotGameList());
};

export const playGame = (queryParams) => (dispatch) => {
  const controller = new AbortController();
  const signal = controller.signal;

  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return postMobile1(queryParams, signal)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.playCompanyGame({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: companyPlayGame");
      }
    })
    .catch((error) => {
      if (error.name !== "AbortError") {
        dispatch(
          actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
        );
        throw error;
      }
    });
};

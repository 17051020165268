import { Field, Form, Formik } from "formik";
import { LoginTab } from "../../enum/LoginTab";
import { useRef, useState, useEffect } from "react";
import { FiRefreshCw} from 'react-icons/fi';
import {
  fetchIPAddress,
  fetchVerificationCode,
  getCurrentCountryCode,
  phoneAuthStep1,
  phoneAuthStep2,
} from "../../api/loginapi";
import OTPInput from "react-otp-input";
import { useIntl } from "react-intl";
import {
  ErrorContainer,
  Input,
  PhoneInputField,
} from "../../../common/components/control/InputField";
import { CheckIsNull } from "../../../../utils/CommonHelper";
import Skeleton from "react-loading-skeleton";
import { useAuth } from "../AuthInit";
import { phoneAuthStep1Schema } from "../../yupSchema/phoneAuthSchema";

export const LoginPhoneAuthTab = ({
  setLoginTab,
  setSwalProps,
  loginTab,
  tempAuth,
}) => {
  const formikRef = useRef();
  const codeNum = useRef("");
  const intl = useIntl();
  const [phoneAuthDetail, setPhoneAuthDetail] = useState();
  const [isShowPhoneNoError, setIsShowPhoneNoError] = useState(false);
  const [verifyImage, setVerifyImageUrl] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isFetchingCurrency, setIsFetchingCurrency] = useState(true);
  const [otp, setOtp] = useState("");
  const [isShowOtpError, setIsShowOTPError] = useState(false);
  const [otpErrorDescription, setOtpErrorDescription] = useState("");
  const [phoneNoErrorDescription, setPhoneNoErrorDescription] = useState("");
  const [phoneCode, setPhoneCode] = useState("my");
  const { saveAuth, setCurrentUser } = useAuth();

  const getVerificationCode = () => {
    setVerifyImageUrl("");
    codeNum.current = "";
    fetchVerificationCode().then((resp) => {
      setVerifyImageUrl(resp.imageurl);
      codeNum.current = resp.codenum;
    });
  };

  useEffect(() => {
    getVerificationCode();
    fetchIPAddress().then((res) => {
      getCurrentCountryCode(res.ip).then((re) => {
        setPhoneCode(re.country.toLowerCase());
        setIsFetchingCurrency(false);
        setIsLoading(false);
      });
    });
  }, []);

  return (
    <>
      <div style={{ textAlign: 'center'}}>
          <h3 style={{color:'white'}}>{intl.formatMessage({ id: "phoneAuth"})}</h3>
        </div>
      <div style={{ margin: '10px', color: 'white', textAlign: 'center' }}>
           <span style={{ color: loginTab === LoginTab.LOGIN_PHONE_AUTH_STEP_1 ? 'rgb(245, 190, 9)' : 'white' }} >1</span>
              <div
                style={{
                display: 'inline-block',
                width: '15%',
                verticalAlign: 'middle',
                borderTop: '1px solid white',
                marginLeft:'5px',
                marginRight:'5px',
                }}
              ></div>
          <span style={{ color: loginTab === LoginTab.LOGIN_PHONE_AUTH_STEP_2? 'rgb(245, 190, 9)' : 'white' }}>2</span>
          </div> 
      {loginTab === LoginTab.LOGIN_PHONE_AUTH_STEP_1 ? (
        <Formik
          initialValues={{
            phoneNo: phoneAuthDetail ? phoneAuthDetail.telno : "",
            verifyCode: "",
          }}
          innerRef={formikRef}
          validationSchema={phoneAuthStep1Schema(intl)}
          enableReinitialize={true}
          onSubmit={(values, formikHelpers) => {
            if (codeNum.current === values.verifyCode) {
              setIsLoading(true);
              const queryParams = {
                telno: values.phoneNo,
                memberid: tempAuth.memberid,
                loginid: tempAuth.loginid,
                language: intl.locale,
              };

              setPhoneAuthDetail(queryParams);

              phoneAuthStep1(queryParams).then((resp) => {
                setIsLoading(false);
                getVerificationCode();
                formikHelpers.setFieldValue("verifyCode", "");
                if (parseInt(resp.code) === 0) {
                  setLoginTab(LoginTab.LOGIN_PHONE_AUTH_STEP_2);
                } else {
                  setIsShowPhoneNoError(true);
                  setPhoneNoErrorDescription(resp.message);
                }
              });
            } else {
              formikHelpers.setFieldError(
                "verifyCode",
                intl.formatMessage({ id: "incorrectVerificationCode" })
              );
            }
          }}
        >
          {({
            values,
            handleSubmit,
            setFieldValue,
            touched,
            errors,
            setFieldError,
            setFieldTouched,
          }) => (
            <Form>
              <div className="mb-2">
              <label className="mb-2 text-input-label" style={{ color: 'white' }}>
                {intl.formatMessage({ id: "phoneNo" })}
              </label>
                {!isFetchingCurrency ? (
                  <PhoneInputField
                    values={values}
                    setFieldValue={setFieldValue}
                    setIsShowPhoneNoError={setIsShowPhoneNoError}
                    isShowPhoneNoError={isShowPhoneNoError}
                    phoneNoErrorDescription={phoneNoErrorDescription}
                    setPhoneNoErrorDescription={setPhoneNoErrorDescription}
                    name="phoneNo"
                    country={phoneCode}
                  />
                ) : (
                  <Skeleton className="w-100" height="35px" />
                )}
              </div>
              <label className="mb-2 text-input-label" style={{ color: 'white' }}>
                {intl.formatMessage({ id: "verificationCode" })}
              </label>
              <div className="mb-2 d-flex align-items-center">
                {CheckIsNull(verifyImage) ? (
                  <Skeleton className="col-4" style={{ height: "60px" }} />
                ) : (
                  <>
                    <img
                      src={verifyImage}
                      alt="verifyImage"
                      className="col-4 me-4"
                      style={{ height: "60px" }}
                    />
                    <FiRefreshCw style={{color:'white',marginLeft:'5px'}} onClick={() => getVerificationCode()} />
                  </>
                )}
              </div>
              <div className="mb-2">
                <Field
                  name="verifyCode"
                  component={Input}
                  placeholder={intl.formatMessage({ id: "verifyCode" })}
                  autoComplete="off"
                  readOnly={isLoading}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '2%' }}>
             <button
               type="submit"
               disabled={isLoading}
               style={{
                 width: '60%',
                 height: 35,
                 borderRadius: 15,
                 backgroundColor: 'rgb(245, 190, 9)',
                 fontWeight: 'bold',
                 color: 'black',
                 fontSize:'12px',
               }}
             >
                {isLoading && (
                 <div
                   className="spinner-border"
                   role="status"
                   style={{ scale: "0.75" }}
                 >
                   <span className="visually-hidden">Loading...</span>
                 </div>
               )}
              {intl.formatMessage({ id: "requestOTP" })}
             </button>
           </div>
            </Form>
          )}
        </Formik>
      ) : (
        <>
        <div style={{ color: 'white', textAlign: 'center',width:'70%', margin:'0 auto'}}>  
        {intl.formatMessage(
            { id: "otpHasSended" }
          )}{'(+'}{phoneAuthDetail.telno}{')'}
           </div>
          <OTPInput
            value={otp}
            onChange={setOtp}
            numInputs={5}
            containerStyle="justify-content-center"
            shouldAutoFocus={true}
            renderSeparator={<div className="mx-2 mb-3"></div>}
            renderInput={(props) => (
              <input {...props} className="form-control otp-field" />
            )}
          />
          <div className="d-flex justify-content-center mt-2">
            <ErrorContainer
              isShow={isShowOtpError}
              errorMsg={otpErrorDescription}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '2%'}}>
          <button
            type="submit"
            onClick={() => {
              if (!CheckIsNull(otp)) {
                setIsShowOTPError(false);
                setIsLoading(true);
                phoneAuthStep2({
                  ...phoneAuthDetail,
                  otpcode: otp,
                }).then((resp) => {
                  setIsLoading(false);
                  setOtp("");
                  if (parseInt(resp.code) === 0) {
                    saveAuth({ ...tempAuth, telno: phoneAuthDetail.telno });
                    setCurrentUser({
                      ...tempAuth,
                      telno: phoneAuthDetail.telno,
                    });
                    setLoginTab(LoginTab.LOGIN);
                  } else {
                    setIsShowOTPError(true);
                    setOtpErrorDescription(resp.message);
                  }
                });
              } else {
                setIsShowOTPError(true);
                setOtpErrorDescription(
                  intl.formatMessage({ id: "otpRequired" })
                );
              }
            }}
            disabled={isLoading}
            style={{
              width: '60%',
              height: 35,
              borderRadius: 15,
              backgroundColor: 'rgb(245, 190, 9)',
              fontWeight: 'bold',
              color: 'black',
              fontSize:'12px',
            }}
          >
            <div className="d-flex align-items-center justify-content-center">
              {isLoading && (
                <div
                  className="spinner-border text-dark"
                  role="status"
                  style={{ scale: "0.75" }}
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
              {intl.formatMessage({ id: "submit" })}
            </div>
          </button>
          </div>
        </>
      )}
    </>
  );
};

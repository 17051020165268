import { useEffect, useRef, useState } from "react";
import * as actions from "../redux/SportAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { GameCategoryConst } from "../../../constants/GameCategory";
import {
  CheckIsNull,
  ResolveArrayNull,
  toAbsoluteUrl,
} from "../../../utils/CommonHelper";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { GameTypeEnum } from "../../../enumeration/GameTypeEnum";
import { Button, Drawer } from "@mui/material";
import { FiArrowLeft } from "react-icons/fi";
import { IoIosArrowDown } from "react-icons/io";
import Skeleton from "react-loading-skeleton";
import { Overlay } from "../../common/components/Overlay";

export const SportListing = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [category, setCategory] = useState(GameCategoryConst.ALL);
  const { companyCode } = useParams();
  const [companyName, setCompanyName] = useState();
  const location = useLocation();
  const [companyOffset, setCompanyOffset] = useState(
    location.state ? parseInt(location.state.companyOffset) : 0
  );
  const navigate = useNavigate();
  const [openCompanyList, setOpenCompanyList] = useState(false);
  const [game, setGame] = useState(null);
  const isInitial = useRef(true);

  const sportState = useSelector(({ sport }) => sport, shallowEqual);

  const _showCompanyList = () => {
    setOpenCompanyList(true);
  };

  const handleCloseCompanyList = () => {
    setOpenCompanyList(false);
  };
  const changeCompanyList = (companyGameType, companyListCode) => {
    navigate(`/${companyGameType}/${companyListCode}`);
    setOpenCompanyList(false);
  };

  useEffect(() => {
    const params = {
      action: "mbsportgamelist",
      companycode: companyCode,
      category,
    };

    dispatch(actions.getSportGameList(params)).then(() => {
      if (location.state && isInitial.current) {
        document.body.scrollTo({
          top: parseInt(location.state.scrollPosition),
          behavior: "smooth",
        });
      }
      isInitial.current = false;
    });
  }, [companyCode, category]);

  useEffect(() => {
    if (sportState.sportGameList) {
      setGame(sportState.sportGameList.sportGameLists[0]);
    }
  }, [sportState.sportGameList]);

  useEffect(() => {
    if (sportState.sportGameList) {
      const foundCompany = sportState.sportGameList.companyGameLists.find(
        (company) => company.companycode === companyCode
      );

      if (foundCompany) {
        setCompanyName(foundCompany.companyname);
      }
    }
  }, [sportState.sportGameList, companyCode]);

  const handlePlayGame = (gameCode, searchName) => {
    const currentScrollPosition =
      window.scrollY || document.documentElement.scrollTop;
    navigate(
      `/gamePlay/${companyCode}/${gameCode}/${companyOffset}/${currentScrollPosition}/${
        GameTypeEnum.SPORT
      }${CheckIsNull(searchName) ? "" : `/${searchName}`}`
    );
  };

  useEffect(() => {
    document.body.classList.add(
      "pages-bg",
      "h-100",
      "scroll-y-default",
      "bg-dark"
    );
    document.querySelector("html").classList.add("h-100", "scroll-y-default");
    document
      .querySelector("#root")
      .classList.add("h-100", "w-100", "scroll-y-default", "position-absolute");

    document.body.style.backgroundImage = `url(${toAbsoluteUrl(
      "/image/homebackground.png"
    )})`;

    return () => {
      document.body.style.backgroundImage = ``;
      document.body.classList.remove(
        "pages-bg",
        "h-100",
        "scroll-y-default",
        "bg-dark"
      );
      document
        .querySelector("html")
        .classList.remove("h-100", "scroll-y-default");
      document
        .querySelector("#root")
        .classList.remove(
          "h-100",
          "w-100",
          "scroll-y-default",
          "position-absolute"
        );
    };
  }, []);

  return (
    <>
      <div className="d-flex flex-column">
        <div style={styles.navBar}>
          <div
            style={styles.backButton}
            onClick={() =>
              navigate(`/home`, {
                state: { gameType: "sport" },
              })
            }
          >
            <FiArrowLeft style={{ color: "white" }} />
          </div>
          <div style={styles.title} onClick={_showCompanyList}>
            {companyName}
            <IoIosArrowDown style={{ paddingLeft: "5px" }} />
          </div>
        </div>
        {sportState.actionsLoading && <Overlay />}
        {game &&
        sportState.sportGameList &&
        sportState.sportGameList.sportGameLists ? (
          <div
            style={{
              backgroundColor: "#1c1e23",
              paddingBottom: "7vh",
            }}
            className="rounded mx-3 px-3 pt-2"
          >
            <div>
              <div className="text-center">
                <img
                  alt={companyCode}
                  src={sportState.sportGameList.sportGameLists[0].bigimageurl}
                  style={{ height: "200px" }}
                />
              </div>
            </div>
            <div className="my-3 fw-bold text-font4" style={{ color: "white" }}>
              {intl.formatMessage({
                id: "sportsbookTitle",
              })}
            </div>
            <div className="mb-5 text-font3" style={{ color: "white" }}>
              {intl.formatMessage({
                id: `${companyCode}_description`,
              })}
            </div>
            <button
              type="button"
              className="btn btn-prim-light px-4 fw-bold w-100 text-font4"
              onClick={() => handlePlayGame(game.gamecode)}
            >
              {intl.formatMessage({ id: "playNow" }).toUpperCase()}
            </button>
          </div>
        ) : (
          <Skeleton className="w-100" height="600px" />
        )}
        <Drawer
          anchor="bottom"
          open={openCompanyList}
          onClose={handleCloseCompanyList}
          style={{ minHeight: "40vh" }}
        >
          <div style={{ backgroundColor: "#262626", padding: "16px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Button onClick={handleCloseCompanyList}>
                <FiArrowLeft style={{ color: "white" }} />
              </Button>
              <div style={{ flex: 7, color: "white" }}>
                {intl.formatMessage({ id: "companyList" })}
              </div>
            </div>

            <div
              style={{
                color: "white",
                cursor: "pointer",
                width: "100%",
                paddingTop: "20px",
              }}
            >
              {sportState.sportGameList &&
                sportState.sportGameList.companyGameLists.map((company) => (
                  <div
                    onClick={() =>
                      changeCompanyList(company.gametype, company.companycode)
                    }
                    key={company.id}
                    style={{
                      width: "100%",
                      height: "50px",
                      borderTop: "1px solid #262626",
                    }}
                  >
                    {company.companyname}
                  </div>
                ))}
            </div>
          </div>
        </Drawer>
      </div>
    </>
  );
};
const styles = {
  navBar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "60px",
    backgroundColor: "black",
  },
  backButton: {
    position: "absolute",
    left: "10px",
    fontSize: "20px",
  },
  title: {
    fontWeight: "bold",
    color: "white",
    margin: "0 auto",
  },
};

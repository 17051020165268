/* eslint-disable jsx-a11y/anchor-is-valid */
import { Route, Routes } from "react-router-dom";
import { createContext } from "react";
import { SportListing } from "../../modules/sport/component/SportListing";

const SportContext = createContext();

const SportWrapper = () => {
  const value = {};

  return (
    <SportContext.Provider value={value}>
      <Routes>
        <Route path="/:companyCode" element={<SportListing />} />
      </Routes>
    </SportContext.Provider>
  );
};

export { SportWrapper };
import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import Skeleton from "react-loading-skeleton";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as masterActions from "../../../common/redux/MasterLayoutAction";
import SweetAlert2 from "react-sweetalert2";
import * as actions from "../../redux/SettingAction";
import { ApiStatusTypeEnum } from "../../../../enumeration/ApiStatusTypeEnum";
import { Overlay } from "../../../common/components/Overlay";
import { FiArrowLeft } from "react-icons/fi";
import { Link } from "react-router-dom";
import "bootstrap-icons/font/bootstrap-icons.css";

export const Bonus = () => {
  const [swalProps, setSwalProps] = useState({});
  const intl = useIntl();
  const dispatch = useDispatch();
  const settingState = useSelector(({ setting }) => setting, shallowEqual);

  const saveValues = (currencyCode) => {
    dispatch(
      actions.transferBonusToBalance({ currencycode: currencyCode })
    ).then((resp) => {
      resp = resp.data;

      if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
        dispatch(actions.getBonusList());
        dispatch(actions.getMultiWalletInfo());
        dispatch(masterActions.getWalletInfo());

        setSwalProps({
          show: true,
          icon: "success",
          title: intl.formatMessage({ id: "success" }),
          text: intl.formatMessage({ id: "purchaseSuccessfully" }),
          onResolve: () => {
            setSwalProps({});
          },
        });
      } else {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };

  useEffect(() => {
    dispatch(actions.getBonusList());
  }, []);

  return (
    <div
      style={{
        backgroundColor: "black",
        backgroundImage: `url(${process.env.PUBLIC_URL}/image/homebackground.png)`,
        backgroundSize: "cover",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={styles.navBar}>
        <div style={styles.backButton}>
          <Link to="/setting/profile">
            <FiArrowLeft style={{ color: "white" }} />
          </Link>
        </div>
        <div style={styles.title} className="text-font4">
          {intl.formatMessage({ id: "bonus" })}
        </div>
      </div>
      {settingState.actionsLoading && <Overlay />}
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      <div
        style={{
          backgroundColor: "#1c1e23",
          width: "95%",
          margin: "10px auto",
          padding: "5px",
          color: "white",
          fontSize: "12px",
          borderRadius: "5px",
        }}
      >
        {settingState.bonusList ? (
          <div>
            {settingState.bonusList.bonusLists.map((bonus, index) => (
              <div
                className="custom-border p-3 rounded d-flex mb-4"
                key={index}
                style={{
                  width: "95%",
                  margin: "10px auto",
                  backgroundColor: "#2e323a",
                }}
              >
                <div className="d-flex align-items-center col-5">
                  <div className="d-flex align-items-center me-4">
                    <img
                      src={bonus.imageurl}
                      alt={bonus.currencycode}
                      style={{ width: "40px", height: "40px" }}
                    />
                  </div>
                  <div className="flex-fill">
                    <div className="mb-2 text-muted">{bonus.currencycode}</div>
                    <div>{bonus.currencyname}</div>
                  </div>
                </div>
                <div className="flex-fill mx-3">
                  <div className="mb-2 text-muted">
                    {intl.formatMessage({ id: "bonus" })}
                  </div>
                  <div className="fw-semibold">{bonus.bonus}</div>
                </div>
                <div className="d-flex align-items-center pe-4">
                  <i
                    className="bi bi-arrow-left-right fs-3 cursor-pointer icon-exchange"
                    title={intl.formatMessage({ id: "claimBonus" })}
                    onClick={() => {
                      if (parseFloat(bonus.bonus) > 0) {
                        setSwalProps({
                          show: true,
                          icon: "info",
                          title: intl.formatMessage({ id: "alert" }),
                          text: intl.formatMessage({
                            id: "tranferBonusDescription",
                          }),
                          showCancelButton: true,
                          showConfirmButton: true,
                          confirmButtonText: intl.formatMessage({
                            id: "confirm",
                          }),
                          denyButtonText: intl.formatMessage({ id: "cancel" }),
                          onConfirm: () => {
                            saveValues(bonus.currencycode);
                          },
                        });
                      } else {
                        setSwalProps({
                          show: true,
                          icon: "error",
                          title: intl.formatMessage({ id: "error" }),
                          text: intl.formatMessage(
                            { id: "atLeast1toClaim" },
                            { n: bonus.currencycode }
                          ),
                        });
                      }
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <Skeleton className="w-100" height="600px" />
        )}
      </div>
    </div>
  );
};
const styles = {
  navBar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "60px",
    backgroundColor: "black",
  },
  backButton: {
    position: "absolute",
    left: "10px",
    fontSize: "20px",
  },
  title: {
    fontWeight: "bold",
    color: "white",
  },
};

import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import SweetAlert2 from "react-sweetalert2";
import * as actions from "../../redux/SettingAction";
import { ApiStatusTypeEnum } from "../../../../enumeration/ApiStatusTypeEnum";
import { Overlay } from "../../../common/components/Overlay";
import { FiArrowLeft } from "react-icons/fi";
import { Link } from "react-router-dom";
import { AddBankDialog } from "./addBank/AddBankDialog";

export const AddBank = () => {
  const [swalProps, setSwalProps] = useState({});
  const intl = useIntl();
  const dispatch = useDispatch();
  const settingState = useSelector(({ setting }) => setting, shallowEqual);

  useEffect(() => {
    dispatch(actions.getCountryBankList({ action: "memberbankcardui" }));
  }, []);

  const NewBankCard = () => (
    <div
      className="custom-border rounded px-3 py-4 cursor-pointer"
      style={{ background: "rgba(255,255,255,0.02)", margin: "10px" }}
      data-bs-toggle="modal"
      data-bs-target="#addBankModal"
    >
      <div className="d-flex justify-content-center align-items-center mb-3">
        <i className="bi bi-plus-circle f text-font4 me-2 mb-1 text-muted" />
        <div className="text-font4">
          {intl.formatMessage({ id: "addBank" })}
        </div>
      </div>
      <div className="text-center smaller text-muted text-font1">
        {intl.formatMessage({ id: "addBankDescription" })}
      </div>
    </div>
  );

  const handleRemoveBank = (itemid) => {
    const queryParams = {
      action: "removebankcard",
      itemid,
    };
    dispatch(actions.deleteBankAccount(queryParams)).then((resp) => {
      resp = resp.data;

      if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
        setSwalProps({
          show: true,
          icon: "success",
          title: intl.formatMessage({ id: "success" }),
          text: intl.formatMessage({ id: "deleteBankSuccessfully" }),
          onResolve: () => {
            dispatch(
              actions.getCountryBankList({ action: "memberbankcardui" })
            );
            setSwalProps({});
          },
        });
      } else {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };

  const BankCard = ({ bankAccount }) => (
    <div
      className="custom-border rounded px-3 py-4"
      style={{
        backgroundColor: "rgba(255,255,255,0.02)",
        width: "94%",
        margin: "10px auto",
        padding: "5px",
        color: "white",
        fontSize: "12px",
        borderRadius: "5px",
      }}
    >
      <div className="d-flex align-items-center justify-content-between mb-4">
        <div className="fw-bold text-truncate" title={bankAccount.bankname}>
          {bankAccount.bankname}
        </div>
        <div>
          <i
            className="bi bi-trash text-danger cursor-pointer"
            onClick={() => {
              setSwalProps({
                show: true,
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: intl.formatMessage({ id: "confirm" }),
                denyButtonText: intl.formatMessage({ id: "cancel" }),
                icon: "info",
                title: intl.formatMessage({ id: "alert" }),
                text: intl.formatMessage({ id: "confirmRemoveBank" }),
                onConfirm: () => {
                  setSwalProps({});
                  handleRemoveBank(bankAccount.itemid);
                },
              });
            }}
          />
        </div>
      </div>
      <div className="text-truncate">{bankAccount.accountnum}</div>
    </div>
  );

  const NewBankCardList = () => {
    if (settingState.countryBankList) {
      let indents = [];
      for (
        var i = 0;
        i < 3 - settingState.countryBankList.memberBankCardUIs.length;
        i++
      ) {
        indents.push(<NewBankCard key={i} />);
      }
      return <>{indents}</>;
    } else {
      return <></>;
    }
  };

  return (
    <div
      style={{
        backgroundColor: "black",
        backgroundImage: `url(${process.env.PUBLIC_URL}/image/homebackground.png)`,
        backgroundSize: "cover",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={styles.navBar}>
        <div style={styles.backButton}>
          <Link to="/setting/countryBankList">
            <FiArrowLeft style={{ color: "white" }} />
          </Link>
        </div>
        <div style={styles.title} className="text-font4">
          {intl.formatMessage({ id: "addBank" })}
        </div>
      </div>
      {settingState.actionsLoading && <Overlay />}
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      {settingState && settingState.countryBankList && (
        <AddBankDialog
          setSwalProps={setSwalProps}
          fullName={settingState.countryBankList.fullname}
        />
      )}

      <div
        style={{
          backgroundColor: "#1c1e23",
          width: "95%",
          margin: "10px auto",
          padding: "5px",
          color: "white",
          fontSize: "12px",
          borderRadius: "5px",
        }}
      >
        <div className="collapse show" id="collapseExample">
          <div>
            {settingState &&
              settingState.countryBankList &&
              settingState.countryBankList.memberBankCardUIs.map(
                (bankAccount, index) => (
                  <BankCard key={index} bankAccount={bankAccount} />
                )
              )}

            <NewBankCardList />
          </div>
        </div>
      </div>
    </div>
  );
};
const styles = {
  navBar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "60px",
    backgroundColor: "black",
  },
  backButton: {
    position: "absolute",
    left: "10px",
    fontSize: "20px",
  },
  title: {
    fontWeight: "bold",
    color: "white",
  },
};

import { ReduxCallTypesConst } from "../../../constants/ReduxCallTypesConst";
import * as requestToApi from "./MasterLayoutCRUD";
import { masterLayoutSlice } from "./MasterLayoutSlice";

const { actions } = masterLayoutSlice;

export const getWalletInfo = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));
  return requestToApi
    .fetchWalletInfo(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.walletInfoFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: fetchWalletInfo");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};

// export const getPromotionReviewInfo = (queryParams) => (dispatch) => {
//   dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));
//   return requestToApi
//     .fetchPromotionReviewInfo(queryParams)
//     .then((response) => {
//       if (response) {
//         if (!response.data.isAbort) {
//           dispatch(actions.promotionReviewInfoFetched({ data: response.data }));
//           return response;
//         }
//       } else {
//         throw new Error("Error api: fetchPromotionReviewInfo");
//       }
//     })
//     .catch((error) => {
//       dispatch(
//         actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
//       );
//       throw error;
//     });
// };

/* eslint-disable jsx-a11y/anchor-is-valid */
import { Route, Routes } from "react-router-dom";
import { createContext } from "react";
import { FishingListing } from "../../modules/fishing/component/FishingListing";

const FishingContext = createContext();

const FishingWrapper = () => {
  const value = {};

  return (
    <FishingContext.Provider value={value}>
      <Routes>
        <Route path="/:companyCode" element={<FishingListing />} />
      </Routes>
    </FishingContext.Provider>
  );
};

export { FishingWrapper };

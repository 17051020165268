import { useIntl } from "react-intl";
import { Modal } from "../../../../../../common/components/Modal";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";

export const MobileDepositDialog = ({ toggleButton }) => {
  const intl = useIntl();
  const dismissBtnRef = useRef();
  const navigate = useNavigate();

  const handleChangePosition = () => {
    dismissBtnRef.current.click();
    toggleButton();
  };

  const handleGoBack = () => {
    dismissBtnRef.current.click();
    navigate("/setting/deposit", {
      state: { backTo: "/setting/profile" },
    });
  };

  return (
    <div>
      <Modal
        modalId="mobileDepositModal"
        title={intl.formatMessage({ id: "option" })}
        dismissBtnRef={dismissBtnRef}
        modalSize="modal-lg"
      >
        <div className="p-3">
          <div
            onClick={handleChangePosition}
            style={{
              width: "100%",
              height: "50px",
              display: "flex",
              cursor: "pointer",
              alignItems: "center",
              borderBottom: "1px solid #262626",
            }}
          >
            {intl.formatMessage({ id: "changePosition" })}
          </div>

          <div
            onClick={handleGoBack}
            style={{
              width: "100%",
              height: "50px",
              display: "flex",
              cursor: "pointer",
              alignItems: "center",
            }}
          >
            {intl.formatMessage({ id: "backPage" })}
          </div>
        </div>
      </Modal>
    </div>
  );
};

import { useIntl } from "react-intl";
import { Modal } from "../../../../common/components/Modal";
import { ChangeRegionForm } from "./ChangeRegionForm";
import { useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../redux/SettingAction";
import { ApiStatusTypeEnum } from "../../../../../enumeration/ApiStatusTypeEnum";
import { useAuth } from "../../../../auth/components/AuthInit";

export const ChangeRegionDialog = ({ setSwalProps, region }) => {
  const intl = useIntl();
  const formikRef = useRef();
  const dispatch = useDispatch();
  const dismissBtnRef = useRef();
  const settingState = useSelector(({ setting }) => setting, shallowEqual);
  const { logout } = useAuth();

  const saveValues = (queryParams) => {
    console.log(123);
    dispatch(actions.updateCurrencyCode(queryParams)).then((resp) => {
      resp = resp.data;

      if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
        setSwalProps({
          show: true,
          icon: "success",
          title: intl.formatMessage({ id: "success" }),
          text: intl.formatMessage({ id: "switchSuccessfully" }),
          onConfirm: () => {
            formikRef.current.resetForm();
            dismissBtnRef.current.click();
            logout();
          },
        });
      } else {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };

  return (
    <Modal
      modalId="regionModal"
      title={intl.formatMessage({ id: "confirmation" })}
      dismissBtnRef={dismissBtnRef}
    >
      <div className="p-3 custom-border-bottom text-font2">
        <div className="mb-3">
          {intl.formatMessage({ id: "confirmationSwitchRegion_1" })}
        </div>
        <div className="mb-3">
          {intl.formatMessage(
            { id: "confirmationSwitchRegion_2" },
            { n: region }
          )}
        </div>
        <ChangeRegionForm
          formikRef={formikRef}
          saveValues={saveValues}
          region={region}
        />
      </div>
      <div className="p-3 d-flex justify-content-end align-items-center">
        {settingState.actionsLoading && (
          <div
            className="spinner-border me-2"
            role="status"
            style={{ scale: "0.55" }}
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        )}
        <button
          type="button"
          disabled={settingState.actionsLoading}
          className="btn btn-prim-light fw-bold px-3 me-2 text-font2"
          onClick={() => {
            formikRef.current.resetForm();
            dismissBtnRef.current.click();
          }}
        >
          {intl.formatMessage({ id: "cancel" })}
        </button>
        <button
          type="button"
          disabled={settingState.actionsLoading}
          className="btn btn-prim fw-bold px-3 text-font2"
          onClick={() => {
            formikRef.current.handleSubmit();
          }}
        >
          {intl.formatMessage({ id: "confirm" })}
        </button>
      </div>
    </Modal>
  );
};

export const Overlay = ({ zIndex }) => {
  return (
    <div
      className="modal fade show d-block ps-0 d-flex align-items-center justify-content-center"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-modal="true"
      role="dialog"
      style={{
        background: "rgba(0,0,0,0.8)",
        zIndex: zIndex ? zIndex : "9999",
      }}
    >
      <div className="spinner-border" role="status" style={{ scale: "1.2" }}>
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

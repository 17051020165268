import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/SettingAction";
import Skeleton from "react-loading-skeleton";
import { CheckIsNull } from "../../../../utils/CommonHelper";
import { FiArrowLeft } from "react-icons/fi";
import { Link, useLocation } from "react-router-dom";
import { Button, Drawer } from "@mui/material";

export const TransactionReport = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [dateType, setDateType] = useState("0");
  const [selectedButton, setSelectedButton] = useState("transaction");
  const location = useLocation();
  const [openDate, setOpenDate] = useState(false);
  const settingState = useSelector(({ setting }) => setting, shallowEqual);

  useEffect(() => {
    dispatch(actions.getGameReport());
  }, []);

  useEffect(() => {
    dispatch(actions.getTransactionReport({ datetype: dateType }));
  }, [dateType]);
  const _showDate = () => {
    setOpenDate(true);
  };

  const handleCloseDate = () => {
    setOpenDate(false);
  };

  const renderTransactionReport = () => {
    return (
      <div>
        {settingState.transactionReport ? (
          <>
            <div
              className="col-3 mb-3 text-font3"
              style={{
                border: "1px solid white",
                padding: "5px 0 5px 0",
                borderRadius: "5px",
                color: "white",
                marginLeft: "10px",
                textAlign: "center",
              }}
              onClick={_showDate}
            >
              {settingState.transactionReport.reportDateLists
                .filter((reportDate) => reportDate.datetype === dateType)
                .map((reportDate, index) => (
                  <div key={index} value={reportDate.datetype}>
                    {reportDate.description}
                  </div>
                ))}
            </div>
            {settingState.listLoading ? (
              <div className="d-flex align-items-center justify-content-center py-5">
                <div
                  className="spinner-border"
                  role="status"
                  style={{ scale: "1.2" }}
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <div style={{ minHeight: "600px" }}>
                {settingState.transactionReport.memberReports.length > 0 ? (
                  <div>
                    {settingState.transactionReport.memberReports.map(
                      (report, index) => (
                        <div
                          className="card mb-2"
                          key={index}
                          style={{ width: "95%", margin: "0 auto" }}
                        >
                          <div
                            className="card-body rounded"
                            style={{
                              background: "rgba(46, 50, 58, 1)",
                              color: "white",
                            }}
                          >
                            <div className="d-flex justify-content-between">
                              <div className="text-font2">{report.message}</div>
                              <div>
                                <div
                                  className={`mb-2 text-end fw-bold text-font2 ${
                                    CheckIsNull(report.balance)
                                      ? ""
                                      : parseFloat(report.balance) >= 0
                                      ? "text-success"
                                      : "text-danger"
                                  }`}
                                >
                                  {report.balance}
                                </div>
                                <div className="text-muted fst-italic text-font1">
                                  {report.date} {report.time}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                ) : (
                  <div className="d-flex py-5 align-items-center justify-content-center">
                    <div className="text-muted d-flex align-items-center justify-content-center flex-column">
                      <i
                        style={{ color: "white" }}
                        className="bi bi-search fs-1 mb-3"
                      />
                      <div className="text-font2" style={{ color: "white" }}>
                        {intl.formatMessage({ id: "noRecordFound" })}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            <Drawer
              anchor="bottom"
              open={openDate}
              onClose={handleCloseDate}
              style={{ minHeight: "40vh" }}
            >
              <div style={{ backgroundColor: "#1c1e23", padding: "16px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Button onClick={handleCloseDate}>
                    <FiArrowLeft style={{ color: "white" }} />
                  </Button>
                  <div
                    style={{ flex: 7, color: "white" }}
                    className="text-font4"
                  >
                    {intl.formatMessage({ id: "option" })}
                  </div>
                  <div style={{ flex: 2 }}></div>
                </div>
                <div
                  style={{
                    color: "white",
                    cursor: "pointer",
                    width: "100%",
                    paddingTop: "20px",
                  }}
                  className="text-font3"
                >
                  {settingState.transactionReport &&
                    settingState.transactionReport.reportDateLists.map(
                      (reportDate, index) => (
                        <div
                          key={index}
                          value={reportDate.datetype}
                          onClick={() => {
                            setDateType(reportDate.datetype);
                            handleCloseDate();
                          }}
                          style={{
                            width: "100%",
                            height: "40px",
                            display: "flex",
                            cursor: "pointer",
                          }}
                        >
                          {reportDate.description}
                        </div>
                      )
                    )}
                </div>
              </div>
            </Drawer>
          </>
        ) : (
          <>
            <Skeleton height="50px" className="col-3 mb-4" />
            <Skeleton height="500px" className="w-100" />
          </>
        )}
      </div>
    );
  };
  const renderGameReport = () => {
    return (
      <div>
        {settingState.gameReport ? (
          <>
            {settingState.listLoading ? (
              <div className="d-flex align-items-center justify-content-center py-5">
                <div
                  className="spinner-border"
                  role="status"
                  style={{ scale: "1.2" }}
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <div style={{ minHeight: "600px" }}>
                {settingState.gameReport.gameReports.length > 0 ? (
                  <div>
                    {settingState.gameReport.gameReports.map(
                      (report, index) => (
                        <div
                          className="card mb-3"
                          key={index}
                          style={{ width: "95%", margin: "0 auto" }}
                        >
                          <div
                            className="card-body rounded"
                            style={{
                              background: "rgba(46, 50, 58, 1)",
                              color: "white",
                            }}
                          >
                            <div className="d-flex justify-content-between text-font2">
                              <div>{report.companyname}</div>
                              <div className="p-1 px-2 fw-bold border border-warning rounded text-warning text-font2">
                                {report.gametype}
                              </div>
                            </div>
                            <div className="fw-bold test-font3">
                              {report.gamename}
                            </div>
                            <div className="d-flex">
                              <div className="me-1 text-muted text-font2">
                                {intl.formatMessage({ id: "bet" })} :
                              </div>
                              <div className="text-font2">{report.bet}</div>
                              <div className="ms-3 me-1 text-muted text-font2">
                                {intl.formatMessage({ id: "win" })} :
                              </div>
                              <div className="text-font2">{report.win}</div>
                            </div>
                            <div
                              className={`mb-2 text-end fw-bold ${
                                CheckIsNull(report.balance)
                                  ? ""
                                  : parseFloat(report.balance) >= 0
                                  ? "text-success"
                                  : "text-danger"
                              }`}
                            >
                              {report.balance}
                            </div>
                            <div className="text-muted fst-italic text-font1">
                              {report.date} {report.time}
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                ) : (
                  <div className="d-flex py-5 align-items-center justify-content-center">
                    <div className="text-muted d-flex align-items-center justify-content-center flex-column">
                      <i
                        style={{ color: "white" }}
                        className="bi bi-search fs-1 mb-3"
                      />
                      <div className="text-font2" style={{ color: "white" }}>
                        {intl.formatMessage({ id: "noRecordFound" })}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        ) : (
          <>
            <Skeleton height="600px" className="w-100" />
          </>
        )}
      </div>
    );
  };
  return (
    <>
      <div
        style={{
          backgroundColor: "black",
          backgroundImage: `url(${process.env.PUBLIC_URL}/image/homebackground.png)`,
          backgroundSize: "cover",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={styles.navBar}>
          <div style={styles.backButton}>
            {location.state && location.state.backTo && (
              <Link to={location.state.backTo}>
                <FiArrowLeft style={{ color: "white" }} />
              </Link>
            )}
          </div>
          <div style={styles.title} className="text-font4">
            {intl.formatMessage({ id: "report" })}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            flexDirection: "row",
          }}
        >
          <div
            onClick={() => setSelectedButton("transaction")}
            className="text-font3"
            style={{
              padding: "10px",
              color: `${selectedButton === "transaction" ? "black" : "white"}`,
              border: `2px solid ${
                selectedButton === "transaction" ? "#f5be09" : "transparent"
              }`,
              background: `${
                selectedButton === "transaction" ? "#f5be09" : "transparent"
              }`,
              borderRadius: "5px",
              width: "30%",
              textAlign: "center",
            }}
          >
            {intl.formatMessage({ id: "transaction" })}
          </div>
          <div style={{ width: "10px" }}></div>
          <div
            onClick={() => setSelectedButton("game")}
            className="text-font3"
            style={{
              padding: "10px",
              color: `${selectedButton === "game" ? "black" : "white"}`,
              border: `2px solid ${
                selectedButton === "game" ? "#f5be09" : "transparent"
              }`,
              background: `${
                selectedButton === "game" ? "#f5be09" : "transparent"
              }`,
              borderRadius: "5px",
              width: "30%",
              textAlign: "center",
            }}
          >
            {intl.formatMessage({ id: "game" })}
          </div>
        </div>
        <div style={{ paddingBottom: "7vh" }}>
          <div
            style={{
              width: "95%",
              background: "#1c1e23",
              paddingTop: "10px",
              margin: "10px auto",
              borderRadius: "5px",
            }}
          >
            {selectedButton === "transaction" && renderTransactionReport()}
            {selectedButton === "game" && renderGameReport()}
          </div>
        </div>
      </div>
    </>
  );
};
const styles = {
  navBar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "60px",
    backgroundColor: "black",
  },
  backButton: {
    position: "absolute",
    left: "10px",
    fontSize: "20px",
  },
  title: {
    fontWeight: "bold",
    color: "white",
  },
};

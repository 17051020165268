import { Field, Form, Formik } from "formik";
import { useIntl } from "react-intl";
import { Input } from "../../../../common/components/control/InputField";
import { transferSchema } from "../../yupSchema/transferSchema";

export const TransferForm = ({
  formikRef,
  saveValues,
  gameBalance,
  mainWallet,
  isDeposit,
  rate,
}) => {
  const intl = useIntl();

  return (
    <Formik
      initialValues={{
        amount: "",
      }}
      innerRef={formikRef}
      validationSchema={transferSchema(intl)}
      enableReinitialize={true}
      onSubmit={(values, formikHelpers) => {
        const queryParams = {
          amount: values.amount,
        };

        saveValues(queryParams);
      }}
    >
      {({
        values,
        handleSubmit,
        setFieldValue,
        touched,
        errors,
        setFieldError,
      }) => (
        <Form>
          <label className="mb-2 text-input-label" style={{ color: "white" }}>
            {intl.formatMessage({ id: "amount" })}
          </label>
          <div className="mb-2 position-relative">
            <Field
              name="amount"
              component={Input}
              placeholder={intl.formatMessage({ id: "amount" })}
              autoComplete="off"
              type="number"
            />
            <div
              className="position-absolute text-prim cursor-pointer"
              style={{ right: "35px", top: "5px", color: "#f5be09" }}
              onClick={() => {
                console.log(mainWallet, gameBalance);
                const [gameBal] = gameBalance.split(".");
                const [mainBal] = mainWallet.split(".");

                setFieldValue(
                  "amount",
                  isDeposit
                    ? mainBal.replaceAll(",", "")
                    : gameBal.replaceAll(",", "")
                );
              }}
            >
              {intl.formatMessage({ id: "all" })}
            </div>
          </div>
          <div className="small d-flex justify-content-between align-items-center">
            <div className="small d-flex justify-content-start align-items-center">
              <div className="text-muted me-2">
                {intl.formatMessage({ id: "rate" })} :
              </div>
              <div className="fw-semibold">{rate}</div>
            </div>
            <div className="small d-flex justify-content-end align-items-center">
              <div className="text-muted me-2">
                {intl.formatMessage({ id: "available" })} :
              </div>
              <div className="fw-semibold">
                {isDeposit ? mainWallet : gameBalance}
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
